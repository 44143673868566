import React from 'react';
import seat from '../images/seat.png'
import luggage from '../images/luggage.png'
import engine from '../images/engine.png'
import second from '../images/second.png'
import speed from '../images/speed.png'
import transmission from '../images/transmission.png'
import fuel from '../images/fuel.png'
import platenumber from '../images/platenumber.png'
import options from '../images/options.png'
import { useSelector } from 'react-redux';
import { Skeleton } from 'antd';
function Specifications({singleCar}) {

    // const {singleCar}=useSelector(state=>state.Data)
  return (
      
    <div className='specifications'>
        <div className="container">
            <h2>Specification</h2>
            <div className="row specRow">
            {
          !singleCar.seats ? <Skeleton avatar loading/> :
          <>
          <div className="col-lg-3 colSpec">
                    <div className="specItem">
                        <div className="sitemHd">
                            <span>Seats</span>
                        </div>
                        <div className="sitemCon">
                            <div className="sitemThumb">
                                <img src={seat} alt="" />
                            </div>
                            <div className="sitemRight">
                                <span>{singleCar && singleCar.seats}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 colSpec">
                    <div className="specItem">
                        <div className="sitemHd">
                            <span>Luggage Space</span>
                        </div>
                        <div className="sitemCon">
                            <div className="sitemThumb">
                                <img src={luggage} alt="" />
                            </div>
                            <div className="sitemRight">
                                <span>{singleCar && singleCar.luggage_space}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 colSpec">
                    <div className="specItem">
                        <div className="sitemHd">
                            <span>Engine</span>
                        </div>
                        <div className="sitemCon">
                            <div className="sitemThumb">
                                <img src={engine} alt="" />
                            </div>
                            <div className="sitemRight">
                                <span>{singleCar && singleCar.engine}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 colSpec">
                    <div className="specItem">
                        <div className="sitemHd">
                            <span>0 - 100 km/h</span>
                        </div>
                        <div className="sitemCon">
                            <div className="sitemThumb">
                                <img src={second} alt="" />
                            </div>
                            <div className="sitemRight">
                                <span>{singleCar && singleCar.acceleration}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 colSpec">
                    <div className="specItem">
                        <div className="sitemHd">
                            <span>Maximum Speed</span>
                        </div>
                        <div className="sitemCon">
                            <div className="sitemThumb">
                                <img src={speed} alt="" />
                            </div>
                            <div className="sitemRight">
                                <span>{singleCar && singleCar.max_speed}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 colSpec">
                    <div className="specItem">
                        <div className="sitemHd">
                            <span>Transmission</span>
                        </div>
                        <div className="sitemCon">
                            <div className="sitemThumb">
                                <img src={transmission} alt="" />
                            </div>
                            <div className="sitemRight">
                                <span>{singleCar && singleCar.transmission}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 colSpec">
                    <div className="specItem">
                        <div className="sitemHd">
                            <span>Fuel</span>
                        </div>
                        <div className="sitemCon">
                            <div className="sitemThumb">
                                <img src={fuel} alt="" />
                            </div>
                            <div className="sitemRight">
                                <span>{singleCar && singleCar.fuel} </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 colSpec">
                    <div className="specItem">
                        <div className="sitemHd">
                            <span>Options</span>
                        </div>
                        <div className="sitemCon">
                            <div className="sitemThumb">
                                <img src={options} alt="" />
                            </div>
                            <div className="sitemRight">
                                <span>{singleCar && singleCar.options}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 colSpec">
                    <div className="specItem">
                        <div className="sitemHd">
                            <span>Vehicle Plate Number</span>
                        </div>
                        <div className="sitemCon">
                            <div className="sitemThumb">
                                <img src={platenumber} alt="" />
                            </div>
                            <div className="sitemRight">
                                <span>{singleCar && singleCar.plate_number}</span>
                            </div>
                        </div>
                    </div>
                </div>
          
          </>
                                    
      }
                
            </div>
        </div>
    </div>
  );
}

export default Specifications;
