import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Footer from '../Componants/Footer';
import GadgetCta from '../Componants/GadgetCta';
import NavBar from '../Componants/NavBar';
import SpBanner from '../images/lamborgini.png';
function ThankYouproduct() {
    const { state } = useLocation()
    return (
        <div>
            <NavBar />
            <div className="bannerSec spBanner" style={{ backgroundImage: `url('${SpBanner}')` }}>
                <div className="WrapperBnr">
                    <div className="container bnrCon h100">
                        <div className="spRow h100">
                            <h1>Payment</h1>
                            <p>Donec felis neque, pellentesque vel enim at, hendrerit vulputate augue. Donec eget odio in lorem congue</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="confirmBooking">
                <div className="container">
                    <div className="row cbRow thankRow">
                        <div className="col-lg-5">
                            <div className="cbLeft h100">
                                <div className="cbLcar h100">
                                    <img src={state.image} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="cbRight">
                                <div className="thankYouDec order">
                                    <h2>Thank You!</h2>
                                    <h3>You're all set your purchase was Successful </h3>
                                    {/* <h3>Reach by:</h3> */}
                                    <div className="bookDetail">
                                        <Link to='/orders'>
                                            <button className='bookDtlbtn'>
                                                BOOKING DETAILS
                                            </button>
                                        </Link>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <GadgetCta />
                </div>
            </div>
            <Footer noTopSpace />
        </div>
    );
}

export default ThankYouproduct;
