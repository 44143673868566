import './App.scss';
import './css/animate.css';
import Home from './Pages/Home';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import CarDetails from './Pages/CarDetails';
import Login from './Pages/Login';
import CreateAccount from './Pages/CreateAccount';
import VerifyAccount from './Pages/VerifyAccount';
import UserInfo from './Pages/UserInfo';
import ConfirmBooking from './Pages/ConfirmBooking';
import AddPayment from './Pages/AddPayment';
import AddPayment2 from './Pages/AddPayment2';
import ThankYou from './Pages/ThankYou';
import Shop from './Pages/Shop';
import ShopDetail from './Pages/ShopDetail';
import ConfirmBookingProduct from './Pages/ConfirmBookingProduct';
import Orders from './Pages/Orders';
import OrderDetail from './Pages/OrderDetail';
import OrderOnWay from './Pages/OrderOnWay';
import OrderCancel from './Pages/OrderCancel';
import OrderDelivered from './Pages/OrderDelivered';
import RideDetail from './Pages/RideDetail';
import ContactUs from './Pages/ContactUs';
import Cart from './Pages/Cart';
import MyRides from './Pages/MyRides';
import RideDetail2 from './Pages/RideDetail2';
import RideDetail3 from './Pages/RideDetail3';
import RideDetail4 from './Pages/RideDetail4';
import RideDetail5 from './Pages/RideDetail5';
import Notifications from './Pages/Notifications';
import ProfileView from './Pages/ProfileView';
import Terms from './Pages/Terms';
import PrivateRoute from './PrivateRoute/PrivateRoute';

import EditProfile from './Componants/EditProfile';
import FaqPage from './Pages/FaqPage';
import ChangeEmail from './Componants/ChangeEmail';
import ChangePhone from './Componants/ChangePhone';
import VerifyPhone from './Componants/VerifyPhone';
import VerifyEmail from './Componants/VerifyEmail';
import Tab3 from './Componants/Tab3';
import SuccessPage from './Pages/SuccessPage';
import AddNewAddress from './Componants/AddNewAddress';
import ForgotPasswordPage from './Pages/ForgotPasswordPage';
import EmailVerification from './Pages/EmailVerification';
import NewPassword from './Pages/NewPassword';
import Success from './Pages/Success';
import ThankYouproduct from './Pages/ProductThankyou';
import AddPayment3 from './Pages/AddPayment3';
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}>
          
          </Route>
          <Route path="/login" element={<Login />}>
          </Route>  
          <Route path="/create-account" element={<CreateAccount />}>
          </Route>  
          <Route path="/user-info" element={<UserInfo />}>
          </Route>
          <Route path="/verify-account" element={<VerifyAccount />}>
          </Route>
          <Route path="/forgot-password" element={<ForgotPasswordPage />}>
          </Route>
          <Route path="/email-verification" element={<EmailVerification />}>            
          </Route>
          <Route path="/new-password" element={<NewPassword />}>
          </Route>
          <Route path="/success-login" element={<Success />}>
          </Route>
          <Route path="/terms" element={<Terms />}>
          </Route>
          <Route path="/add-payment2" element={<AddPayment2 />}>
          </Route>
          <Route path="/add-payment3" element={<AddPayment3 />}>
          </Route>
          <Route path="/faqs" element={<FaqPage/>}>
          </Route>
          
          <Route element={<PrivateRoute/>}>
          <Route path="/car-detail/:id" element={<CarDetails />}>
          </Route>  
             
         
          
          <Route path="/contact-us" element={<ContactUs />}>
          </Route>
          <Route path="/confirm-booking" element={<ConfirmBooking />}>
          </Route>
          <Route path="/add-payment" element={<AddPayment />}>
          </Route>
          <Route path="/add-payment2" element={<AddPayment2 />}>
          </Route>
          <Route path="/thank-you" element={<ThankYou />}>
          </Route>
          <Route path="/thank-you-product" element={<ThankYouproduct />}>
          </Route>
          <Route path="/ride-detail" element={<RideDetail2 />}>
          </Route>

          {/* Shop products */}

          <Route path="/shop/:id" element={<Shop />}>
          </Route>
          <Route path="/shop-detail/:id" element={<ShopDetail />}>
          </Route>
          <Route path="/product-confirm-booking/:id" element={<ConfirmBookingProduct/>}>
          </Route>
          <Route path="/tab3" element={<Tab3/>}>
          </Route>
          <Route path="/orders" element={<Orders />}>
          </Route>
          <Route path="/order-detail/:id" element={<OrderDetail />}>
          </Route>
          <Route path="/order-onway/:id" element={<OrderOnWay />}>
          </Route>
          <Route path="/order-cancel/:id" element={<OrderCancel />}>
          </Route>
          <Route path="/order-delivered/:id" element={<OrderDelivered />}>
          </Route>
          <Route path="/ride-detail" element={<RideDetail />}>
          </Route>
          
          <Route path="/cart" element={<Cart />}>
          </Route>
          <Route path="/my-rides" element={<MyRides />}>
          </Route>
          <Route path="/ride-detail2" element={<RideDetail2 />}>
          </Route>
          <Route path="/ride-detail3" element={<RideDetail3 />}>
          </Route>
          <Route path="/ride-detail4" element={<RideDetail4 />}>
          </Route>
          <Route path="/ride-detail5" element={<RideDetail5 />}>
          </Route>
          <Route path="/notifications" element={<Notifications />}>
          </Route>
          <Route path="/profile-view" element={<ProfileView />}>
          </Route>
          <Route path="/edit-profile" element={<EditProfile />}>
          </Route>
          
          <Route path="/email-change" element={<ChangeEmail/>}>
          </Route>
          <Route path="/phone-change" element={<ChangePhone/>}>
          </Route>
          <Route path="/verify-phone" element={<VerifyPhone/>}>
          </Route>
          <Route path="/verify-email" element={<VerifyEmail/>}>
          </Route>
          <Route path="/success" element={<SuccessPage />}>
          </Route>
          <Route path="/add-new-address" element={<AddNewAddress />}>
          </Route>
          <Route path="/email-verification" element={<EmailVerification />}>
          </Route>
          
          
          </Route>
          {/* end */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
