import React from 'react';
import { Link, useNavigate} from 'react-router-dom';
import SpBanner from '../images/lamborgini.png';
import regBg from '../images/regImg.png';
import Footer from '../Componants/Footer'
import SuccessIcon from '../images/success.png'
function Success() {
    const navigate=useNavigate()
    return (
        <div>
            <div className="bannerSec spBanner" style={{ backgroundImage: `url('${SpBanner}')` }}>
                <div className="WrapperBnr">

                </div>
            </div>
            <div className="accRegistration pb60">
                <div className="container">
                    <div className="row accRow">
                        <div className="col-lg-6 colRgLeft p-0">
                            <div className="rgLeftWrap">
                                <div className="rgLcon h100">
                                    <div className="rgLHd">
                                        <h2><span>Enjoy the </span> <span>Experience with us!</span></h2>
                                    </div>
                                    <div className="rgLimg">
                                        <img src={regBg} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div  className="col-lg-6 colRgRight p-0">
                            <div className="rgRtWrap successWrp">
                                <div className="formBody">
                                    <div className="product--name epDetail successView">
                                        <div className="success_hd">
                                            <img src={SuccessIcon} alt="" />
                                        </div>
                                        <div className="success-dec sdLogin">
                                            <h2>Success</h2>
                                            <p>You have successfully reset your password.</p>
                                        </div>
                                        <div className="bkBtn">
                                            <button onClick={()=>navigate('/login',{state:{invalid:false}})} type="submit" class="btn backBtn">BACK TO LOGIN</button>
                                        </div>
                                    </div>   
                                </div>                          
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer noTopSpace/>
        </div>
        
    );

}

export default Success;
