import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SpBanner from '../images/lamborgini.png'
import delIcon from '../images/delIcon.png'
import { GetOrdersById, GetShippingById, RateProduct, UpdateProductReviewById } from '../Redux/Features/Orderslice';
import { ImLocation } from "react-icons/im";
import moment from 'moment';
import Rating from 'react-rating';
import { useForm } from 'react-hook-form';
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import { RiStarFill, RiStarLine } from "react-icons/ri";
import { message } from 'antd';
import Skeleton from 'react-loading-skeleton';
import ReportIssue from './ReportIssue';
function OrderDeliveredView() {

    const { id } = useParams()
    const { SingleOrder,loading } = useSelector(state => state.Orders)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [rating, setRating] = useState()
    const [Rate, setRate] = useState()
    const [edit, setEdit] = useState(false)
    const [review, setreview] = useState(SingleOrder?.order_item && SingleOrder?.order_item[0]?.review?.review)
    const [Review, setReview] = useState()
    const [defaultValue, setdefaultValue] = useState()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { register, handleSubmit, reset, control, formState: { errors } } = useForm(
        {
            defaultValues: {
                name: null
            }
        }
    )
    console.log(SingleOrder, 'SingleOrder in order delivered');

    const onSubmit = (data) => {
        console.log(data, 'data in onsubmit');
        SingleOrder?.order_item && SingleOrder?.order_item[0]?.review === null ?
            rating ? dispatch(RateProduct({ orderItemId: SingleOrder?.order_item[0]?.id, proId: SingleOrder?.order_item[0]?.product?.id, review: data.rev, rating: rating })).then((respponse) => {
                //    console.log(respponse,'respponse in rateproduct');
                if (respponse.payload.message === "Created") {
                    message.success('Review added successfully')
                    navigate('/orders')
                }
            }) : message.info('Rating is required')
            :
            dispatch(UpdateProductReviewById({ active: SingleOrder?.active, id: SingleOrder?.order_item && SingleOrder?.order_item[0]?.review?.id, orderItemId: SingleOrder?.order_item[0]?.id, proId: SingleOrder?.order_item[0]?.product?.id, review: data.review, rating: Rate })).then((response) => {
                console.log(response, 'review updated');
                if (response?.payload?.message === "Updated") {
                    navigate('/orders')
                    message.success('Review Updated Successfully')
                }
            })






    }
    useEffect(() => {
        dispatch(GetOrdersById(id)).then((res) => {
            //   console.log(res?.payload?.data?.order?.id,'orders here');
            dispatch(GetShippingById(res?.payload?.data?.order?.shipping_address_id)).then((shipping) => {
                //   console.log(shipping,'by id');
            })
        })

    }, [dispatch])
    // useEffect(() => {


    // }, [dispatch])

    return (
        <div>
            {/* {loading && <h1>Loading .............</h1>} */}
            {
                show &&
                <ReportIssue setdefaultValue={setdefaultValue} defaultValue={defaultValue} category='shop' orderId={SingleOrder?.id}  handleClose={handleClose} handleShow={handleShow} show={show} />
            }
            <div className="bannerSec spBanner" style={{ backgroundImage: `url('${SpBanner}')` }}>
                <div className="WrapperBnr">
                    <div className="container bnrCon h100 p-0">
                        <div className="orderSRow">
                            <div className="odHd">
                                <h1>Order Details</h1>
                                <div onClick={handleShow} className="report">
                                    <span><i class="fas fa-flag"></i></span>
                                    <span>Report</span>
                                </div>
                            </div>
                            {SingleOrder?.order_item?.length === 1 ?
                                <div class="row cbRow orderSingleRow">
                                    <div class="col-lg-5">
                                        <div class="cbLeft h100">
                                            <div class="cbLpro h100">{SingleOrder?.order_item && <img src={SingleOrder?.order_item[0]?.product?.product_image[0].image} alt="" />}</div>
                                        </div>
                                    </div>
                                    <div class="col-lg-7">
                                        <div class="cbRight">
                                            <div class="orderDetailRt">
                                                <div className="orderDate">
                                                    <div style={{ display: 'flex' }}>
                                                        <span className='ordId'>PRODUCT CODE </span>&nbsp;<span style={{ fontSize: 'larger', fontWeight: '450', }}>({SingleOrder?.order_item && SingleOrder?.order_item[0]?.product?.code})</span>
                                                    </div>

                                                    <span className='ordId'>ORDER # {SingleOrder?.code? SingleOrder?.code:<Skeleton width={100} height={20} /> }</span>
                                                    <span className='ordDt'> {moment.utc(SingleOrder?.transaction?.created_at).local().format('MMMM DD, h:mm a ')}</span>
                                                </div>
                                                <div className="orderedProduct ">
                                                    {SingleOrder?.order_item ?
                                                        <h2>
                                                            <span>{SingleOrder?.order_item[0]?.product?.brand}</span>
                                                            {SingleOrder?.order_item[0]?.product?.brand} {SingleOrder?.order_item[0]?.product?.title}
                                                        </h2>
                                                        :
                                                        <h2>
                                                            <span><Skeleton width={100} height={20} /></span>
                                                            <Skeleton width={100} height={20} />
                                                        </h2>
                                                        
                                                    }
                                                    <h5>Quantity: {SingleOrder?.total_qty ? SingleOrder?.total_qty :<Skeleton width={100} height={20} /> }</h5>
                                                </div>
                                                <div className="orderInfo">
                                                    <div className="oiLeft orderDelivered">
                                                        <span className='alertOrder'>
                                                            {/* <i className="fas fa-shipping-fast"></i> */}
                                                            <img src={delIcon} />
                                                            <span>
                                                                Your order has been delivered
                                                                {SingleOrder?.order_item && <span className='cancelDate'>on {moment.utc(SingleOrder?.order_item[0]?.updated_at).local().format('MMMM DD YYYY')} </span>}
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <div className="oiRt"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                SingleOrder?.order_item?.map((items) => (
                                    <>
                                        <div class="row cbRow orderSingleRow">
                                            <div class="col-lg-5">
                                                <div class="cbLeft h100">
                                                    <div class="cbLpro h100"><img width='100px' src={items.product?.product_image[0].image} alt="" /></div>
                                                </div>
                                            </div>
                                            <div class="col-lg-7">
                                                <div class="cbRight">
                                                    <div class="orderDetailRt">
                                                        <div className="orderDate">
                                                            <span className='ordId'>ORDER # {SingleOrder?.code ? SingleOrder?.code  :<Skeleton width={100} height={20}/>}</span>
                                                            <span className='ordDt'>{moment.utc(SingleOrder?.transaction?.created_at).local().format('MMMM DD, h:mm a ')}</span>
                                                        </div>
                                                        <div className="orderedProduct lowSpace">
                                                            {SingleOrder?.order_item && <h2>
                                                                <span>{items?.product?.brand}</span>
                                                                {items?.product?.brand} {items?.product?.title}
                                                            </h2>}
                                                            <h5>Quantity: {items?.qty}</h5>
                                                        </div>
                                                        <div className="orderInfo">
                                                            <div className="oiLeft multipleSec">
                                                                <span><img style={{ marginRight: '8px' }} src={delIcon} alt="" />Your order is on the way</span>
                                                                <a target='blank' href={SingleOrder?.order_item && SingleOrder?.order_item[0]?.public_url} >    <button className='oiBtn'>TRACK -KH0213525</button></a>
                                                            </div>
                                                            <div className="oiRt"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="orderPayment">
                <div className="container p-0">
                    <div className="row opRow">
                        <div className="col-lg-6 colOpLt">
                            <div className="item-deliver">
                                <div className="rideInfo">
                                    <span>Delivered On {moment.utc(SingleOrder?.transaction?.updated_at).local().format('MMMM DD ')}  </span>
                                </div>
                                <div className="item-deliver--edit">
                                    <span>Deliver To</span>
                                </div>
                                <div className="item-deliver--location">
                                    <div className="item-deliver--location--icon">
                                        <ImLocation color='#F87756' size={20} />
                                    </div>
                                    <div className="item-deliver--location--locname">
                                        <h2>
                                            <span>{SingleOrder?.shipping_address?.city ?SingleOrder?.shipping_address?.city:<Skeleton width={100} height={20}/>}</span>
                                            {SingleOrder?.shipping_address?.address?SingleOrder?.shipping_address?.address :<Skeleton width={100} height={20}/>}{SingleOrder?.shipping_address?.zip ? SingleOrder?.shipping_address?.zip  :<Skeleton width={100} height={20}/>}
                                        </h2>
                                    </div>
                                </div>
                                {SingleOrder?.order_item && SingleOrder?.order_item[0]?.review === null ?
                                    <div className="ratingReview">
                                        <form onSubmit={handleSubmit(onSubmit)} >
                                            <div className="rating">
                                                <h3>Rate this Product</h3>
                                                <div className="ratingAlter">
                                                    {/* <div class="ratingStars">
                                        <span class="fa fa-star"></span>
                                        <span class="fa fa-star"></span>
                                        <span class="fa fa-star"></span>
                                        <span class="fa fa-star"></span>
                                        <span class="fa fa-star"></span>
                                    </div> */}
                                                    <Rating
                                                        onChange={(rate) => setRating(rate)}

                                                        emptySymbol={<AiOutlineStar size={50} color='#DEDEDE' />}
                                                        fullSymbol={<AiFillStar size={50} color='gold' />}
                                                        initialRating={rating}
                                                    />

                                                </div>
                                                <div className="addReview">
                                                    <label for="">Write a Review</label>
                                                    <textarea {...register("rev", { required: 'Review is required', minLength: { value: 6, message: ' Review must be atleast 6 characters' }, maxLength: { value: 100, message: 'Review must be less than 100 charecters' } })} onChange={(e) => setReview(e.target.value)} value={Review} maxlength="100" name="rev" id="" cols="30" >
                                                    </textarea>
                                                    {errors.rev && <span style={{ color: 'red' }} >{errors.rev.message}</span>}
                                                </div>



                                                <div className="col-lg-12">

                                                    <div className="customAmt rateView">
                                                        {/* <label for="">Enter custom amount</label> */}
                                                        <div className="cusInpBtn">
                                                            {/* <input type="text" name="ln" ></input> */}
                                                            <button
                                                            // onClick={()=>handleReviewAndTip(RideDetailsUser?.driver?.user_stripe)}
                                                            >DONE</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div> :
                                    <div className="ratingReview">
                                        <div className="rating">
                                            {!edit ? <h3>Rating & Review</h3> : <h3>Edit Rate</h3>}
                                            <div className="ratingAlter">
                                                <div class="ratingStars">
                                                    <Rating
                                                        onChange={(rate) => setRate(rate)}
                                                        initialRating={Rate ? Rate : SingleOrder?.order_item && SingleOrder?.order_item[0]?.review?.rating}
                                                        emptySymbol={<RiStarLine size={34} color='#DEDEDE' />}
                                                        fullSymbol={<RiStarFill size={34} color='gold' />}
                                                        readonly={!edit}

                                                    />
                                                </div>
                                                <div className="reviewEdit">
                                                    {!edit && <button onClick={() => setEdit(!edit)} >Edit Review</button>}
                                                </div>
                                            </div>

                                            <div className="reviewDec">
                                                {edit ?
                                                    <>
                                                        <form onSubmit={handleSubmit(onSubmit)} >
                                                            <h3>Edit Review</h3>
                                                            <textarea {...register('review', { required: ' Review is required', pattern: [/[a-z]/, /[A-Z]/, /[0-9]/], minLength: { value: 5, message: 'Review must be atleast 5 characters' }, maxLength: { value: 100, message: ' Review should be less than 100 characters' } })} className='reviewEdi' onChange={(e) => setreview(e.target.value)} maxlength="100" defaultValue={SingleOrder?.order_item && SingleOrder?.order_item[0]?.review?.review} name='review' />
                                                            {errors?.review && <p style={{ color: 'red' }}>{errors?.review.message}</p>}
                                                            <div className="customAmt">
                                                                <div className="cusInpBtn">

                                                                    <button   >DONE</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </>
                                                    : <p>{SingleOrder?.order_item && SingleOrder?.order_item[0]?.review?.review}</p>}

                                            </div>
                                        </div>
                                    </div>

                                }

                            </div>
                        </div>
                        <div className="col-lg-6 colOpRt">
                            <div className="calcRow">
                                <div className="calcPart">
                                    <div class="orderSum">
                                        <h2>Order Summary</h2>
                                        <div class="orders">
                                            <div class="orderin">
                                                <div class="ordItemName"><span>Total Item Price</span></div>
                                                <div class="itemPrice actualPrice"><span>{SingleOrder?.transaction?.item_amount ?`$ ${SingleOrder?.transaction?.item_amount}` :<Skeleton height={20} width={60}/>}</span></div>
                                            </div>
                                            <div class="orderin">
                                                <div class="ordItemName"><span>Shipping Price</span></div>
                                                <div class="itemPrice"><span>{SingleOrder?.transaction?.delivery_fee?`$ ${SingleOrder?.transaction?.delivery_fee}` :<Skeleton height={20}  width={60}/>} </span></div>
                                            </div>
                                            <div class="orderin">
                                                <div class="ordItemName"><span>Service Charges</span></div>
                                                <div class="itemPrice"><span>{SingleOrder?.transaction?.service_charge ?`$ ${SingleOrder?.transaction?.service_charge}`:<Skeleton height={20}  width={60}/>}</span></div>
                                            </div>
                                            {SingleOrder?.transaction?.discount &&
                                                <div class="orderin">
                                                    <div class="ordItemName"><span>Promocode Discount</span></div>
                                                    <div class="itemPrice"><span>{ SingleOrder?.transaction?.discount ?`- $ ${SingleOrder?.transaction?.discount}` :<Skeleton height={20}  width={60}/>}</span></div>
                                                </div>
                                            }
                                            <div class="orderin totalPrice">
                                                <div class="ordItemName"><span>TOTAL</span></div>
                                                <div class="itemPrice"><span>{SingleOrder?.transaction?.total_amount? `$ ${SingleOrder?.transaction?.total_amount}`:<Skeleton height={20}  width={60}/>}</span></div>
                                            </div>
                                        </div>
                                        <div className="paidCred">
                                            <div class="paidviaCred">
                                                <div className="pcredwrap">
                                                    <div className="refundMsg">
                                                        <div className='refifpaid'>
                                                            <span><i class="fa fa-check"></i></span> Paid via credit card ending {SingleOrder?.transaction?.card_last4}
                                                        </div>
                                                        {/* <p>Refund Initiated</p> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OrderDeliveredView;
