import { message } from 'antd';
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AddNewShippingAddres, EditShippingAddres, GetAllShippingAddress, GetAllState, GetStateById } from '../Redux/Features/Userslice';
function EditAddress({ UserAddress, setEditAdress, setRefetch }) {
    console.log(UserAddress, 'edit page');
    const { register, handleSubmit, control, formState: { errors } } = useForm(
        {
            defaultValues: {
                name: UserAddress?.name,
                address: UserAddress?.address,
                unit: UserAddress?.unit,
                city: UserAddress?.city,
                landmark: UserAddress?.landmark,
                state_id: UserAddress?.state_id,
                zip_code: UserAddress?.zip,
            }
        }
    )
    const dispatch = useDispatch()
    const { states, SingleState } = useSelector(state => state.User)
    const onSubmit = (data) => {

        dispatch(EditShippingAddres({ name: data.name, address: data.address, unit: data.unit, city: data.city, landmark: data?.landmark, state: data.state_id, zip: data.zip_code, active: UserAddress?.active, default: UserAddress?.default, id: UserAddress?.id })).then((res) => {
            console.log(res, 'res after editship');
            if (res?.payload?.message === "Updated") {


                setRefetch(true)
                setRefetch(false)
                setEditAdress(false)
                message.success('Shipping Address Updated Successfully')
            } else {
                message.info('Shipping address verification failed')
            }
        })

    }

    useEffect(() => {
        dispatch(GetAllState()).then((res) => {

        })
        dispatch(GetStateById(UserAddress?.state_id)).then((res) => {

        })
    }, [dispatch, UserAddress?.state_id])

    return (
        <div>
            <div class="rgRtWrap">
                <div class="rgRtHd addNwAdrsHd">
                    <h2>Edit Shipping Address</h2>
                </div>
                <div action="#" class="register-form addNewForm">
                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div class="formWrap">

                            <div class="form-controller">
                                <label for="">Name</label>

                                <input {...register('name', { required: ' Name is required', minLength: { value: 3, message: 'Name must be atleast 3 characters' }, pattern: { value: /^[a-zA-Z ]*$/, message: 'Name Must be Alphabets' }, maxLength: { value: 20, message: 'Name must be less than 20 alphabets' } })} type="text" autocomplete="off" name="name" placeholder=" Name" />
                                {errors?.name && <p style={{ color: 'red' }}>{errors?.name.message}</p>}
                            </div>
                            <div class="form-controller">
                                <label for="">Address</label>
                                <input {...register('address', { required: ' Address is required', pattern: [/[a-z]/, /[A-Z]/, /[0-9]/], minLength: { value: 3, message: 'address must be atleast 3 characters' }, maxLength: { value: 70, message: 'Address must be less than 70 characters' } })} type="text" autocomplete="off" name="address" placeholder=" Address    " />
                                {errors?.address && <p style={{ color: 'red' }}>{errors?.address.message}</p>}
                            </div>
                            <div class="form-controller">
                                <label for="">Unit/Floor</label>
                                <input {...register('unit', { required: ' Unit/floor is required', pattern: [/[a-z]/, /[A-Z]/, /[0-9]/], minLength: { value: 3, message: 'Unit/floor must be atleast 3 characters' }, maxLength: { value: 30, message: 'Unit/floor must be less than 30 characters' } })} type="text" autocomplete="off" name="unit" placeholder=" Unit/Floor" />
                                {errors?.unit && <p style={{ color: 'red' }}>{errors?.unit.message}</p>}
                            </div>
                            <div class="form-controller">
                                <label for="">Landmark</label>
                                <input {...register('landmark', { minLength: { value: 3, message: 'Landmark must be atleast 3 characters' }, pattern: { value: /^[a-zA-Z ]*$/, message: 'Landmark Must be Alphabets' }, maxLength: { value: 30, message: 'Landmark must be less than 30 characters' } })} type="text" autocomplete="off" name="landmark" placeholder=" landmark" />
                                {errors?.landmark && <p style={{ color: 'red' }}>{errors?.landmark.message}</p>}
                            </div>
                            <div class="form-controller">
                                <label for="">City</label>
                                <input {...register('city', { required: ' City is required', minLength: { value: 3, message: 'City must be atleast 3 characters' }, pattern: { value: /^[a-zA-Z ]*$/, message: 'City Must be Alphabets' }, maxLength: { value: 30, message: 'City must be less than 30 characters' } })} type="text" autocomplete="off" name="city" placeholder=" City" />
                                {errors?.city && <p style={{ color: 'red' }}>{errors?.city.message}</p>}
                            </div>

                            <div className="form-row row">
                                <div class="form-controller col-lg-6">
                                    <label for="">State</label>
                                    <select className='select-state' {...register("state_id", { required: 'State is required' })} name='state_id'>
                                        {states?.states?.map((item) => (
                                            <>
                                                <option selected value={SingleState?.id} hidden>{SingleState?.name}</option>
                                                <option value={item.id}>{item.name}</option>
                                            </>
                                        ))}
                                    </select>
                                    {errors?.state_id && <p style={{ color: 'red' }}>{errors?.state_id.message}</p>}
                                </div>

                                <div class="form-controller col-lg-6">
                                    <label for="">Zip Code</label>
                                    <input {...register('zip_code', { required: 'Zip Code is required', pattern: { value: /\d+/, message: 'Zip Code must be numbers' }, minLength: { value: 5, message: ' Zip Code  must be atleast 5 digits ' }, maxLength: { value: 7, message: 'Zip code should be less than 7 digits' } })} className='phnin' min='0' type="number" autocomplete="off" name="zip_code" placeholder="Zip Code" control={control} />
                                    {errors?.zip_code && <p style={{ color: 'red' }}>{errors?.zip_code.message}</p>}
                                </div>
                            </div>
                        </div>
                        <div class="formFooter addFoo">

                        </div>
                        <button type="submit" class="btn">Save</button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default EditAddress;