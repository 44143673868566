import React from 'react'
import { useSelector } from 'react-redux';

function CartIcon() {
  const {CartCount}=useSelector(state=>state.Shop)
  console.log(CartCount,'CartCount');
  return (
    <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 27.96 30">
            <g id="Group_791" data-name="Group 791" transform="translate(-1482.04 -64)">
                <g id="noun_Shopping_Bag_860153" data-name="noun_Shopping Bag_860153" transform="translate(1466.634 54.2)">
                <path id="Path_152" data-name="Path 152" d="M36.027,30.662,34.3,16.714a.788.788,0,0,0-.745-.656h-3.4V14.926c0-3.07-1.729-5.126-4.262-5.126-2.563,0-4.262,2.056-4.262,5.126v1.162H17.878a.766.766,0,0,0-.745.685l-1.7,13.918a2.7,2.7,0,0,0,.656,2.146,2.65,2.65,0,0,0,2.056.954H33.285a2.614,2.614,0,0,0,2.027-.924A2.7,2.7,0,0,0,36.027,30.662ZM23.093,16.118V14.956c0-1.669.715-3.636,2.772-3.636s2.772,1.937,2.772,3.636v1.162Z" fill="#fff"/>
                </g>
                <g id="Rectangle_43" data-name="Rectangle 43" transform="translate(1491 75)" fill="#f6491e" stroke="#fff" stroke-width="1">
                { CartCount.count >0 &&
                <>
                  <rect width="19" height="19" rx="8" stroke="none"/>
                <rect x="0.5" y="0.5" width="18" height="18" rx="7.5" fill="none"/>
                </>
                }
                </g>
                <text id="_1" data-name="1" transform="translate(1498 90)" fill="#fff" font-size="14" font-family="SegoeUI-Semibold, Segoe UI" font-weight="600"><tspan x="0" y="0">{CartCount.count>0? CartCount?.count:''}</tspan></text>
            </g>
        </svg>
    </div>
  );
}

export default CartIcon;