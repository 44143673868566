import { Skeleton } from 'antd';
import React, { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { GetPickupLocations } from '../Redux/Features/Dataslice';

function LocationPopUp({handleLocationClose,location,place,setplace}) {

const dispatch =useDispatch()
const {pickuplocations,loading}=useSelector(state=>state.Data)
const handleLocChange=(id,title,location)=>{
  setplace({
    id:id,
    title:title,
    location:location
  });
  console.log(id,title);
}
console.log(place);

useEffect(() => {
  dispatch(GetPickupLocations())
}, [dispatch])

  return (
    <div>
        <Modal show={location} onHide={handleLocationClose} className='modalDuration'>
        <Modal.Header closeButton>
          <Modal.Title>Pickup Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='chooseLocation'>
          
          <form>
          {
                loading && [...Array(4)].map((_, i) => (
                    <div key={i} style={{ width: '250px', margin: '40px' ,height:'60px' }}>
                        <Skeleton title  loading active avatar />
                    </div>
                ))
            }
            {
             !loading &&pickuplocations.length>0  ?  pickuplocations.map((i,index)=>(
              <label key={index} className="locationItem">
              <input type='radio' name='loc1' value={i.title} onChange={()=>handleLocChange(i.id,i.title,i.location)}/>
              <div className="liwrap">
                  <div className="liwLeft">
                      <span class="checkmark"></span>
                  </div>
                  <div className="liwRt">
                      <span>{i.title}</span>
                      <h3>{i.location}</h3>
                  </div>
                  </div>
                  </label>

              )):''
            }
            
            {/* <div className="locationItem">
                <input type='radio' name='loc1' value='loc2' onChange={handleLocChange}/>
                <div className="liwrap">
                    <div className="liwLeft">
                        <i className='fad fad fa-map-marker-alt'></i>
                    </div>
                    <div className="liwRt">
                        <span>Worls Trade Center</span>
                        <h3>285 Fulton St, New York,NY 10007</h3>
                    </div>
                </div>
            </div>
            <div className="locationItem">
                <input type='radio' name='loc1' value='loc3' onChange={handleLocChange}/>
                <div className="liwrap">
                    <div className="liwLeft">
                        <i className='fad fad fa-map-marker-alt'></i>
                    </div>
                    <div className="liwRt">
                        <span>Worls Trade Center</span>
                        <h3>285 Fulton St, New York,NY 10007</h3>
                    </div>
                </div>
            </div> */}
          </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleLocationClose} className='closeBtn'>
            Cancel 
          </Button>
          <Button variant="primary" onClick={handleLocationClose} className='saveBtn'>
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default LocationPopUp;
