import { message } from 'antd';
import React, { useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { EditShippingAddres, GetAllState, GetStateById } from '../Redux/Features/Userslice';
function EditAddressPopup({ userAddress, editpopup, handleEditClose,setrefetch }) {
    console.log(userAddress, 'userAddress edit popup');
    const dispatch = useDispatch()
    const { states, SingleState } = useSelector(state => state.User)
    const notify = (message) => toast(message);
    const { register, handleSubmit, control, formState: { errors } } = useForm(
        {
            defaultValues: {
                name: userAddress?.name,
                address: userAddress?.address,
                unit: userAddress?.unit,
                city: userAddress?.city,
                state_id: userAddress?.state_id,
                zip_code: userAddress?.zip,
                Landmark: userAddress?.landmark,
            }
        }
    )
    const onSubmit = (data) => {
        dispatch(EditShippingAddres({name:data.name,address:data.address,unit:data.unit,city:data.city,state:data.state_id,zip:data.zip_code,active:userAddress?.active,default:userAddress?.default,id:userAddress?.id,landmark:data?.Landmark})).then((res)=>{
            console.log(res,'res after editship');
            if (res?.payload?.message=== "Updated") {
                
                
                setrefetch(true)
                setrefetch(false)
                handleEditClose()
                message.success('Shipping Address Updated Successfully')
            }else{
                message.info(res?.payload?.message)
               notify(res?.payload?.message)
            }
        })





    }
    useEffect(() => {
        dispatch(GetAllState()).then((res) => {

        })
        dispatch(GetStateById(userAddress?.state_id)).then((res) => {

        })
    }, [dispatch, userAddress?.state_id])
    console.log(userAddress,'userAddress');
    return (
        <div>
            <Modal show={editpopup} onHide={handleEditClose} className='modalEditLocation'>
                <Modal.Header closeButton className='modalEditHdr'>
                    <Modal.Title>Edit address</Modal.Title>
                </Modal.Header>
                <Modal.Body className='modalBodyPopup'>
                    <div className="editPopView">
                        <div action="#" class="register-form-edit">
                            <div class="row modalEditHdr">
                                <div class="col-lg-12 colRgRight p-0 align-self-center">
                                    <div class="rgRtWrap">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div action="#" class="register-form addCardFrm">

                                                <div className="form-row row">

                                                    <div class="form-controller col-lg-12">
                                                        <label for="">Name</label>
                                                        <input {...register('name', { required: ' Name is required', minLength: { value: 3, message: 'Name on card must be atleast 3 characters' }, pattern: { value: /^[a-zA-Z ]*$/, message: 'Name Must be Alphabets' }, maxLength: { value: 20, message: 'Name must be less than 20 alphabets' } })} name="name" type="text" autocomplete="off" placeholder="Name" />
                                                        {errors?.name && <p style={{ color: 'red' }}>{errors?.name.message}</p>}
                                                    </div>
                                                </div>
                                                <div className="form-row row">

                                                    <div class="form-controller col-lg-12">
                                                        <label for="">Address</label>
                                                        <input {...register('address', { required: ' Address is required', pattern: [/[a-z]/, /[A-Z]/, /[0-9]/], minLength: { value: 3, message: 'Address must be atleast 3 characters' }, maxLength: { value: 70, message: 'Address must be less than 70 characters' } })} name="address" type="text" autocomplete="off" placeholder="Address" />
                                                        {errors?.address && <p style={{ color: 'red' }}>{errors?.address.message}</p>}
                                                    </div>

                                                </div>
                                                <div className="form-row row">
                                                    <div class="form-controller col-lg-12">
                                                        <label for="">Unit/floor</label>
                                                        <input {...register('unit', { required: ' Unit/floor is required', pattern: [/[a-z]/, /[A-Z]/, /[0-9]/], minLength: { value: 3, message: 'Unit/floor must be atleast 3 characters' }, maxLength: { value: 30, message: 'Unit/floor must be less than 30 characters' } })} name="unit" type="text" autocomplete="off" placeholder="Unit/Floor" />
                                                        {errors?.unit && <p style={{ color: 'red' }}>{errors?.unit.message}</p>}
                                                    </div>
                                                </div>
                                                <div className="form-row row">
                                                    <div class="form-controller col-lg-12">
                                                        <label for="">Landmark</label>
                                                        <input {...register('Landmark', { pattern: [/[a-z]/, /[A-Z]/, /[0-9]/], minLength: { value: 3, message: 'Landmark must be atleast 3 characters' }, maxLength: { value: 70, message: 'Landmark must be less than 70 characters' } })} type="text" autocomplete="off" name="Landmark" placeholder=" Landmark    " />
                                                        {errors?.Landmark && <p style={{ color: 'red' }}>{errors?.Landmark.message}</p>}
                                                    </div>
                                                </div>
                                                <div className="form-row row">
                                                    <div class="form-controller col-lg-12">
                                                        <label for="">City</label>
                                                        <input {...register('city', { required: ' City is required', minLength: { value: 3, message: 'City must be atleast 3 characters' }, pattern: { value: /^[a-zA-Z ]*$/, message: 'City Must be Alphabets' }, maxLength: { value: 30, message: 'City must be less than 30 characters' } })} name="city" type="text" autocomplete="off" placeholder="City" />
                                                        {errors?.city && <p style={{ color: 'red' }}>{errors?.city.message}</p>}
                                                    </div>
                                                </div>

                                                <div className="form-row row">

                                                    <div class="form-controller col-lg-6">
                                                        <label for="">State</label>
                                                        <select className='select-state' {...register("state_id", { required: 'State is required' })} name='state_id'>
                                                        {/* <option selected  hidden>{SingleState?.name}</option> */}
                                                            {states?.states?.map((item) => (
                                                                <>
                                                                    
                                                                    <option value={item.id}>{item.name}</option>
                                                                </>
                                                            ))}
                                                        </select>
                                                        {errors?.state_id && <p style={{ color: 'red' }}>{errors?.state_id.message}</p>}
                                                    </div>
                                                    <div class="form-controller col-lg-6">
                                                        <label for="">Zip Code</label>
                                                        <input {...register('zip_code', { required: 'Zip Code is required', pattern: { value: /\d+/, message: 'Zip Code must be numbers' }, minLength: { value: 5, message: ' Zip Code  must be atleast 5 digits ' }, maxLength: { value: 7, message: 'Zip code should be less than 7 digits' } })} name="zip_code" type="number" min='0' autocomplete="off" placeholder="Zip Code" control={control} />
                                                        {errors?.zip_code && <p style={{ color: 'red' }}>{errors?.zip_code.message}</p>}
                                                    </div>
                                                </div>
                                                <div className="formFooter">
                                                    <div className="ff-left">
                                                        
                                                    </div>
                                                    <div className="ff-right">
                                                        <button class="payBtn">Update</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <ToastContainer
             position="top-center"
             autoClose={3300}
             hideProgressBar
             newestOnTop
             closeOnClick
             rtl={false}
             pauseOnFocusLoss
             draggable
             pauseOnHover
            />
        </div>
    );
}

export default EditAddressPopup;