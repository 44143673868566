import { Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetMyRides } from '../Redux/Features/Dataslice';
import moment from 'moment';
import Profile from '../images/prof.jpeg'
import Car3 from '../images/car3.png'
import { useNavigate } from 'react-router-dom';
import Rating from 'react-rating';
import InfiniteScroll from 'react-infinite-scroll-component';

import { AiOutlineStar, AiFillStar } from "react-icons/ai";

function MyRidesView() {
    const [myRides, setmyRides] = useState([])
    const [count, setcount] = useState(1)
  const [hasMore, sethasMore] = useState(true)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {  loading } = useSelector(State => State.Data)
    const date = '2022-03-15T19:07:33.000Z'
    console.log(date);
    
    const converted = moment.utc(date).local().format('MMMM Do , h:mm:ss a')
    // console.log('converted', converted);

    const fetchData =()=>{
        dispatch(GetMyRides({data:count})).then((res)=>{
          setcount(count+1)
          console.log(res,'res after first iteration');
          setmyRides([...myRides,...res?.payload])
        if (res?.payload?.length=== 0 || res?.payload<11) {
          sethasMore(false)
        }  
          
    
        })
      }
    useEffect(() => {
        dispatch(GetMyRides({data:0})).then((res)=>{
            console.log(res.payload,'my rides list in my f rides');
            setmyRides([...myRides,...res?.payload])
        })
    }, [dispatch])

    return (
        <div className='myRidesView'>
            <div className="container">
                <div className="ridesWrapper">
                    <div className="ridesHead">
                        <h1>My Rides</h1>

                    </div>
                    <div className="ridesList table-responsive">
                        {/*  */}
                        <table class="table table-image">
                            <tbody>
                                {/* list 1 */}
                                
                                {
                                    loading && [...Array(4)].map((_, i) => (
                                        <div key={i}>
                                            <Skeleton avatar active loading  />
                                        </div>
                                    ))
                                }
                           {!loading &&     <InfiniteScroll
  dataLength={myRides ?myRides.length:0} //This is important field to render the next data
  next={fetchData}
  hasMore={hasMore}
  loader={loading &&<h4>loading</h4>}
  endMessage={
    <p style={{ textAlign: 'center' }}>
     {myRides?.length<11 ?<></> :<b>Yay! You have seen it all</b>}
    </p>
  }
 
>
                                 {myRides.length > 0 ? myRides.map((i) => (
                                    <tr className={i.ride_status_id === 3 ? 'cancelledRow' : ''} style={{ cursor: 'pointer' }} onClick={() => {
                                        if (i.ride_status_id === 3) {
                                            navigate('')
                                        } else {
                                            if (i.driver_assigned_on === null) {
                                                navigate('/ride-detail', { state: { item: i } })
                                            }
                                            if (i.driver.message === "Your ride completed & not rated yet") {
                                                navigate('/ride-detail5', { state: { item: i } })
                                            }
                                            if (i.driver.message === "Your ride completed") {
                                                navigate('/ride-detail4', { state: { item: i } })
                                            }
                                            if (i.driver.message === "Driver Info") {
                                                navigate('/ride-detail2', { state: { item: i } })
                                            }
                                            if (!i.driver.message) {
                                                navigate('/ride-detail', { state: { item: i } })
                                            }
                                            if (i.ride_status_id === 5) {
                                                navigate('/ride-detail3', { state: { item: i } })
                                            }
                                            if (i.ride_status_id === 4) {
                                                navigate('/ride-detail3', { state: { item: i } })
                                            }
                                            if (i.ride_status_id === 6) {
                                                navigate('/ride-detail3', { state: { item: i } })
                                            }
                                            if (i.ride_status_id === 8) {
                                                navigate('/ride-detail2', { state: { item: i } })
                                            }
                                        }







                                    }} >
                                        <td  class="w-20">
                                            <div className="ride-Item">
                                                <div className="product--img">
                                                    <img src={i.vehicle_image} alt="" />
                                                </div>
                                            </div>
                                        </td>
                                        <td >
                                            <div style={{marginLeft:'5%'}} className="product--name">
                                                <div className="ride--car">
                                                    <span className='orderId'>ORDER # {i.ride_code}</span>
                                                    <span className='orderName'>{i.vehicle_name}</span>
                                                </div>
                                                <div className="ride--date">
                                                    <span className='ride-date-span' >{moment.utc(i.created_at).local().format('MMMM DD , h:mm a')}</span>
                                                    {i.ride_status_id === 3 && <span style={{ color: '#E86666', fontSize: '17px', fontWeight: 'normal', display: 'block', marginTop: '20px' }}>Your ride has cancelled on {moment.utc(i.updated_at).local().format('MMMM DD , h:mm a')}</span>}
                                                </div>
                                                {i.driver &&

                                                    <div className="ride--info">
                                                        <div className="ride--info--thumb">
                                                            {i.ride_status_id === 3 ? <></> :


                                                                i.ride_status_id !== 7 &&
                                                                <div className="ride--info--thumb__img">

                                                                    <img src={i.driver.image ? i.driver.image : `https://joeschmoe.io/api/v1/${i.driver.full_name}`} alt="Profile" />
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="ride--info--detail">

                                                            {i.ride_status_id === 3 ? <></> :

                                                                <>
                                                                    {i.ride_status_id !== 7 && <h3>{i.driver.full_name}</h3>}
                                                                    <span style={{
                                                                        color: i.ride_status_id === 7 ? "green" : "",

                                                                    }}   >{i.driver.message.charAt(0).toUpperCase() + i.driver.message.slice(1)}</span>
                                                                </>}


                                                            {i.driver.message === "Your ride completed & not rated yet" &&
                                                                <span>   <Rating
                                                                    onChange={(rate) => console.log(rate)}
                                                                    emptySymbol={<AiOutlineStar size={24} color='#DEDEDE' />}
                                                                    fullSymbol={<AiOutlineStar size={24} color='gold' />}
                                                                /></span>}

                                                            {i.driver.message === "Your ride completed" && <span>
                                                                <Rating
                                                                    initialRating={i.review.rating}
                                                                    emptySymbol={<AiOutlineStar size={24} color='#DEDEDE' />}
                                                                    fullSymbol={<AiFillStar size={24} color='#FEBA1B' />}
                                                                    readonly
                                                                /> </span>}

                                                        </div>
                                                    </div>

                                                }



                                            </div>
                                        </td>
                                        <td>
                                            <div className="Trip">
                                                <div className="bdRight">
                                                    <div class="bdRicon">
                                                        <span class={i.ride_status_id === 3 ? 'dot gdot' : "dot"}></span>
                                                    </div>
                                                    <div className="optedItem">
                                                        <label>Start Trip:</label>
                                                        <h3>{i.ride_start.slice(0, 6)}</h3>
                                                        <span>{i.ride_start.slice(7)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="Trip">
                                                <div className="bdRight">
                                                    <div class="bdRicon">
                                                        <span class={i.ride_status_id === 3 ? 'dot withWhite grey' : "dot withWhite"}></span>
                                                    </div>
                                                    <div className="optedItem">
                                                        <label>End Trip:</label>
                                                        <h3>{i.ride_end.slice(0, 6)}</h3>
                                                        <span>{i.ride_end.slice(7)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className={i.ride_status_id === 3 ? 'span-grey' : "usedHrs"} >
                                                <span >
                                                    <i className='iconTime'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" id="hr" width="17.293" height="19.953" viewBox="0 0 17.293 19.953">
                                                            <path id="Path_126" data-name="Path 126" d="M27.025,33.467A8.645,8.645,0,0,0,27.69,16.2V14.844h2v-1.33H24.364v1.33h2V16.2a8.645,8.645,0,0,0,.665,17.265Zm.665-15.931a7.257,7.257,0,0,1,2.4.644l-2.4,4.158Zm-1.33,0V24.82a.665.665,0,0,0,1.241.333l3.641-6.306a7.312,7.312,0,1,1-4.882-1.311Z" transform="translate(-18.378 -13.513)" fill="#f6491e" />
                                                        </svg>
                                                    </i>
                                                    {i.duration == '1' ? `${i.duration} Hour` : `${i.duration} Hours`}
                                                </span>
                                            </div>

                                        </td>
                                    </tr>
                                )) : !loading && <h1>No Rides Booked</h1>


                                            }

                                
</InfiniteScroll>
}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MyRidesView;



