import { message } from 'antd';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { UpdatePassword } from '../Redux/Features/Authslice';
import PasswordToggle from './PasswordToggle';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/fontawesome-free-solid'
import { library } from '@fortawesome/fontawesome-svg-core'
library.add(faEye, faEyeSlash);

function PasswordView() {
    const dispatch = useDispatch()
    const [InputType, Icon] = PasswordToggle();
    const { register, handleSubmit, formState: { errors } } = useForm()
    const navigate = useNavigate()
    const [visible, setVisible] = useState(false);
    const [visible2, setVisible2] = useState(false);
    const [visible3, setVisible3] = useState(false);
    const [details, setdetails] = useState({
        currentpwd: '',
        newpwd: '',
        confirmpwd: ''
    });

    const handlechange = (e) => {
        setdetails({ ...details, [e.target.name]: e.target.value })
    }

    const onSubmit = (detail) => {
        // e.preventDefault()
        // console.log('e is', e)
        if (detail.newpwd === detail.confirmpwd) {
            dispatch(UpdatePassword(detail)).then((res) => {

                if (res.type === 'auth/Updatepassword/rejected') {

                    message.info(res?.payload?.response?.data?.message)
                } else {
                    navigate('/')
                    message.success('Password changed successfully')
                     
                }




                // res?.payload?.message ? message.success(res?.payload?.message) : message.info(res?.payload?.response?.data?.message)
            })

        } else {
            message.info('Password Mismatch')
        }

    }

    return (
        <div>
            <form className='center-pass' onSubmit={handleSubmit(onSubmit)} action="#">
                <div class="form-controller col-lg-12 formIpt">
                    <div style={{ dispaly: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                        <label for="">Current Password</label>

                    </div>

                    <input  onChange={handlechange} name='currentpwd' type={visible ? "text" : "password"} placeholder="Current Password" {...register('currentpwd', { required: 'Current Password is required', minLength: { value: 6, message: 'Current Password must be atleast 6 characters' },maxLength: { value: 15, message: 'Current Password should be less than 15 characters' } })} />
                    {errors?.currentpwd && <p style={{ color: 'red' }}>{errors?.currentpwd.message}</p>}
                    <span className="passwordToggleIcon">
                        <FontAwesomeIcon icon={visible ? "eye" : "eye-slash"}
                            onClick={() => setVisible(!visible)} />
                    </span>
                </div>
                <div class="form-controller col-lg-12 formIpt">
                    <label for="">New Password</label>
                    <input  onChange={handlechange} name='newpwd' type={visible2 ? "text" : "password"} placeholder="New Password" {...register('newpwd', { required: 'New Password is required', minLength: { value: 6, message: 'New Password must be atleast 6 characters' },maxLength: { value: 15, message: 'New Password should be less than 15 characters' } })} />
                    {errors?.newpwd && <p style={{ color: 'red' }}>{errors?.newpwd.message}</p>}
                    <span className="passwordToggleIcon">
                        <FontAwesomeIcon icon={visible2 ? "eye" : "eye-slash"}
                            onClick={() => setVisible2(!visible2)} />
                    </span>
                </div>
                <div class="form-controller col-lg-12 formIpt">
                    <label for="">Confirm New Password</label>
                    <input  onChange={handlechange} name='confirmpwd' type={visible3 ? "text" : "password"} placeholder="Confirm New Password" {...register('confirmpwd', { required: 'Confirm new Password is required', minLength: { value: 6, message: 'Confirm new Password must be atleast 6 characters' },maxLength: { value: 15, message: 'Confirm New password should be less than 15 characters' } })} />
                    {errors?.confirmpwd && <p style={{ color: 'red' }}>{errors?.confirmpwd.message}</p>}
                    <span className="passwordToggleIcon">
                        <FontAwesomeIcon icon={visible3 ? "eye" : "eye-slash"}
                            onClick={() => setVisible3(!visible3)} />
                    </span>
                </div>
                <div className="addPayment">
                    <div class="addSaveBtn">
                        <button class="svBtn"> SAVE </button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default PasswordView;