
import { Skeleton } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Profile from '../images/prof.jpeg';
function ProfileDetail({user}) {

    // const dispatch =useDispatch()
    // const {user}=useSelector(State=>State.Auth)
    const navigate =useNavigate()
    // useeffect
    // useEffect(() => {
    //   dispatch(GetLoggedinUser())
    // }, [dispatch])
    // console.log(user);
  return (
    <div>
        <div className="profileEdit">
            <div onClick={()=>navigate('/edit-profile',{state:{user:user?.user}})} className="editProfileBtn">
                <span>Edit Profile</span>
            </div>
        </div>
        {!user?.user?.full_name ? <Skeleton avatar/>:
        <div className="profileHd">
        <div className="profImg">
            <img src={user?.user?.image ? user?.user?.image : `https://joeschmoe.io/api/v1/${user.user?.full_name}`} alt="" />
            
        </div>
        
        <div className="profRt">
            <div className="profNm">
                <h2>{user?.user?.full_name}</h2>
                <span onClick={()=>navigate('/edit-profile',{state:{user:user?.user}})} >change Photo</span>
            </div>
        </div>
    </div>
        }
        
        {
            !user?.user?.email? <Skeleton rows='1' size='small' active/> :

            <div className="contactDetail">
            <div className="cdItem">
                <span>First Name</span>
                <h4>{user?.user?.first_name} </h4>
            </div>
            <div className="cdItem">
                <span>Last Name</span>
                <h4>{user?.user?.last_name}</h4>
            </div>
        </div>
        }
        
    </div>
  );
}

export default ProfileDetail;
