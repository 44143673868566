import React, { useEffect, useState } from 'react'
import PasswordView from './PasswordView';
import Payments from './Payments';
import ProfileDetail from './ProfileDetail';
import Shipping from './Shipping';
import { Link, Navigate, useNavigate } from 'react-router-dom'
import EmailEdit from './EmailEdit';
import PhoneEdit from './PhoneEdit';
import { useDispatch, useSelector } from 'react-redux';
import { GetLoggedinUser } from '../Redux/Features/Authslice';
import Cookies from 'js-cookie';
import { message } from 'antd';

function ViewProfile() {
    const [toggle, setToggle] = useState(1);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { user } = useSelector(State => State.Auth)
    const handleToggle = (e) => {
        // console.log("test", e);
        setToggle(e);
    }
    useEffect(() => {
        dispatch(GetLoggedinUser()).then((res) => {
            if (res?.payload?.response?.data?.statusCode === 401) {
                // message.error('Account is blocked by admin, contact revup support team')
                Cookies.remove('token')
                // navigate('/')
            } else {

            }
            
        })
    }, [dispatch])
    console.log('set', user);
    return (
        <div>
            <div className="profileView">
                <div className="container">
                    <div className="profileMainWrapper">
                        <div className="row proRow">
                            <div className="col-lg-4 proLeft">
                                <div className="menuList">
                                    <div
                                        className={"menuList_single " + (toggle === 1 ? "active" : "")}
                                        onClick={() => handleToggle(1)}
                                    >
                                        <span>Profile</span>
                                    </div>
                                    <div
                                        className={"menuList_single " + (toggle === 2 ? "active" : "")}
                                        onClick={() => handleToggle(2)}
                                    >
                                        <span>Email</span>
                                    </div>
                                    <div
                                        className={"menuList_single " + (toggle === 3 ? "active" : "")}
                                        onClick={() => handleToggle(3)}
                                    >
                                        <span>Phone</span>
                                    </div>
                                    <div
                                        className={"menuList_single " + (toggle === 4 ? "active" : "")}
                                        onClick={() => handleToggle(4)}
                                    >
                                        <span>Payments</span>
                                    </div>
                                    <div
                                        className={"menuList_single " + (toggle === 5 ? "active" : "")}
                                        onClick={() => handleToggle(5)}
                                    >
                                        <span>Shipping</span>
                                    </div>
                                    <div
                                        className={"menuList_single " + (toggle === 6 ? "active" : "")}
                                        onClick={() => handleToggle(6)}
                                    >
                                        <span>Password</span>
                                    </div>
                                    <div className='menuList_single'>
                                        <Link to="/faqs" > <span>FAQ</span> </Link>
                                    </div>
                                    <div className='menuList_single'>
                                        <Link to="/terms" > <span>Terms</span> </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 proRight">
                                <div className="loginDetail">
                                    <div className={"loginPane " + (toggle === 1 ? "active" : "")}>
                                        <ProfileDetail user={user} />
                                    </div>
                                    <div className={"loginPane " + (toggle === 2 ? "active" : "")}>
                                        <EmailEdit user={user} />
                                    </div>
                                    <div className={"loginPane " + (toggle === 3 ? "active" : "")}>
                                        <PhoneEdit user={user} />
                                    </div>
                                    <div className={"loginPane " + (toggle === 4 ? "active" : "")}>
                                        <Payments />
                                    </div>
                                    <div className={"loginPane " + (toggle === 5 ? "active" : "")}>
                                        <Shipping />
                                    </div>
                                    <div className={"loginPane " + (toggle === 6 ? "active" : "")}>
                                        <PasswordView />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ViewProfile;


