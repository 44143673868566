import React from 'react';
import Footer from '../Componants/Footer';
import NavBar from '../Componants/NavBar';
import RideDetail4View from '../Componants/RideDetail4View';

function RideDetail4() {
  return (
    <div>
        <NavBar />
        <RideDetail4View />
        <Footer noTopSpace />
    </div>
  );
}

export default RideDetail4;
