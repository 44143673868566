import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cards from '../images/cards.png'
import BlueCar from '../images/car-blue.png';
import MasterCard from '../images/master.png';
import AddCard from './AddCard';
import EditAddressPopup from './EditAddressPopup';
import AddnewAddressPop from './AddnewAddressPop';
import VisaCard from '../images/visa.png';
import { useLocation, useNavigate } from 'react-router-dom'
import { DeleteShippingAddres, EditShippingAddres, GetAllShippingAddress } from '../Redux/Features/Userslice';
import { DeleteUserCard, GetAllCards, MakeCardDefault } from '../Redux/Features/Cardslice';
import { ApplyProductCoupencode, ConfirmOrder, MakeProductPayment, SingleCartById } from '../Redux/Features/Shopslice';
import { message, Spin } from 'antd';
import { ApplyCoupencode } from '../Redux/Features/Dataslice';
import { ImLocation } from "react-icons/im";
import Skeleton from 'react-loading-skeleton';
import tshirt from '../images/tshirt.png'
import watch from '../images/watch.png'
function Tabs2() {
    const [toggle, setToggle] = useState(1);
    const { state } = useLocation()
    const [addCard, setAddCard] = useState(false);
    const [increment, setIncrement] = useState(state?.qnty);
    const [editpopup, setEditpopup] = useState(false);
    const [addNewAddress, setAddNewAddress] = useState(false);
    const [refetchcard, setrefetchcard] = useState(false);
    const [refetch, setrefetch] = useState(false);
    const [userAddress, setuserAddress] = useState({});
    const navigate=useNavigate()
    
    const dispatch = useDispatch()
    const { shippingAddress,ShippingLoading } = useSelector(state => state.User)
    const { cards } = useSelector(state => state.Card)
    const { ProductpaymentLoading } = useSelector(state => state.Shop)
    const [extraClass, setExtraClass] = useState(cards[0]?.id);
    const { bookingDetails, coupenerror, coupenOffer, loading, CoupenRemove, error } = useSelector(state => state.Data)
    // console.log(state, 'tab2');
    const defaultShipiing =shippingAddress.length>0&& shippingAddress?.shipping_addresses.find((item)=>{return(item)})
    const { CartItems,orderPriceloading, orderPrice, ammountError, ProductCoupenError, CoponOffer, ShipmentDetails } = useSelector(state => state.Shop)
    // console.log(defaultShipiing,'defaultShipiing');
    const handleEditOpen = (address) => {
        setEditpopup(true)
        // console.log(address,'address in modal');
        setuserAddress(address)
    }
    const handleAddAdressOpen = () => {
        setAddNewAddress(true)
    }
    const handleAddAdressClose = () => {
        setAddNewAddress(!addNewAddress)
    }
    const handleIncrement = (action) => {
        if (action=== 'increment') {
            ammountError?message.info('Requested Quantity not availabe')  :setIncrement(increment + 1);
            
        }else{
           increment!==1&& setIncrement(increment - 1);
        }
        action=== 'increment' && !ammountError&& dispatch(ConfirmOrder([{ product_id: parseInt(state?.product?.id), quantity: increment }])).then((res) => {
            // console.log(res?.payload?.data?.rate?.total, 'res in cbview');
            if (res.payload.message=== "Ok") {
                coupenOffer.offer && sessionStorage.getItem('reFetch') &&dispatch(ApplyCoupencode({ code:state?.code, price: res?.payload?.data?.rate?.total.toString(),type:'shop' }))


                .then((res) => {

// console.log(res);
                    if (res.type !== 'data/Applycoupen/rejected') {
                        sessionStorage.setItem('reFetch', true)
                        // message.info('Promo code applied successfully')
                        // console.log(sessionStorage.getItem('reFetch'), 'under log');
                        // setitm(true)

                    }
                }

                )
                
            }
        })
    }
    // const handleDecrement = () => {
    //     if (increment != 0) {
    //         setIncrement(increment - 1);
    //     }
    // }
    const invertClass = (e) => {
        setExtraClass(e);
    };
    const handleToggle = (e) => {
        // console.log("test", e);
        setToggle(e);
    }
    const handleClick = (e) => {
        setExtraClass(e.id)
        dispatch(MakeCardDefault(e.id)).then(() => {
            setrefetchcard(true)
            setrefetchcard(false)
        })
        // console.log(e, 'e');
        
    }
    const handleAddCardClose = () => setAddCard(false);
    const handleEditClose = () => setEditpopup(false);
    const handleAddCardShow = () => setAddCard(true);
    const handlePayment=(item)=>{
        // console.log(item,'item');
        dispatch(MakeProductPayment(item)).then((response)=>{
            // console.log(response,'response after paying shop item');
            
            if (response.type === "data/MakeProductPayment/fulfilled") {
                navigate('/thank-you-product',{state:{image:state?.product?.product_image[0]?.image}})
            }
        })
    }
    const handleDefault=(data)=>{
        // console.log(data,'data');
        dispatch(EditShippingAddres({name:data.name,address:data.address,unit:data.unit,city:data.city,state:data.state_id,zip:data.zip,active:userAddress?.active,default:true,id:data?.id})).then((res)=>{
            // console.log(res,'res after editship');
            if (res?.payload?.message=== "Updated") {
                
                
                setrefetch(true)
                setrefetch(false)
                // handleEditClose()
                // message.success('Shipping Address Updated Successfully')
            }
        })
    }

    useEffect(() => {
        dispatch(GetAllShippingAddress())
        
    }, [dispatch,refetch])
    useEffect(() => {
        
        dispatch(GetAllCards()).then((cards) => {
            setExtraClass(cards?.payload[0]?.id)
        })
    }, [dispatch,refetchcard])
    useEffect(() => {

        dispatch(ConfirmOrder([{product_id:parseInt(state?.product?.id) ,quantity:increment}])).then((res)=>{
            console.log(state,'state doubt');
        })
        // dispatch(ConfirmOrder()).then((res)=>{
        //     console.log(res.payload.message, 'res after confirming order');


        // })
    }, [dispatch,increment])
    const DefaultShippingAddress =shippingAddress?.shipping_addresses&&shippingAddress?.shipping_addresses.filter(item=>item.default===true)
    console.log(DefaultShippingAddress,'DefaultShippingAddress');
    // const [extraClass, setExtraClass] = useState(cards[0]?.id);
    console.log(ammountError,'ammountError');
    return (
        <div>
            {
                addCard === true && <AddCard handleAddCardClose={handleAddCardClose} addCard={addCard} setrefetchcard={setrefetchcard} />
            }
            {
                editpopup && <EditAddressPopup userAddress={userAddress} handleEditClose={handleEditClose} editpopup={editpopup} setrefetch={setrefetch} />
            }
            {
                addNewAddress && <AddnewAddressPop handleAddAdressOpen={handleAddAdressOpen} addNewAddress={addNewAddress} handleAddAdressClose={handleAddAdressClose} />
            }
            <div className="payMethods">
                <div className="container">
                    <div className="pmRow tabtwoRow">
                        <ul className="nav nav-tabs tabtwoul" role="tablist">
                            {/* Select a delivery address */}
                            <li className="nav-item">
                                <span
                                    className={"nav-link " + (toggle === 1 ? "active" : "")}
                                    data-toggle="tab" role="tab"
                                    onClick={() => handleToggle(1)}
                                >Select a delivery address</span>
                            </li>
                            <li className="nav-item">
                                <span
                                    className={"nav-link " + (toggle === 2 ? "active" : "")}
                                    data-toggle="tab" role="tab"
                                    onClick={() =>shippingAddress?.length === 0? handleAddAdressOpen(): handleToggle(2)}
                                >Add Payment method</span>
                            </li>
                            <li className="nav-item">
                                <span
                                    className={"nav-link " + (toggle === 3 ? "active" : "")}
                                    data-toggle="tab" role="tab"
                                    onClick={() =>cards?.length === 0 ? handleAddCardShow(): handleToggle(3)}
                                >Confirm Booking</span>
                            </li>
                        </ul>
                        <div className="tab-content tab-content2">
                            <div class={"tab-pane " + (toggle === 1 ? "active" : "")} id="tabs-1" role="tabpanel">
                                <div className="paneWrap">
                                    <div className="row pwRow">
                                        <div className="col-lg-6">
                                            <h2>Your Address</h2>
                                            <div className="pmLeft dotAct">
                                                {ShippingLoading ? <h3>Fetching Cards...</h3>:
                                                shippingAddress?.count > 0 ?
                                                    shippingAddress?.shipping_addresses.map((address) => (
                                                        <>
                                                            <label onClick={()=>handleDefault(address)} className={ address.default ? "active" : ""}>
                                                                <input type="radio" name="q3" id="q1-n" value="1" onChange={() => invertClass(address?.id)} defaultChecked={ address.default} />
                                                                <div className="ptBank" for="q1-n">
                                                                    <div className="bankNm bankNmLoc">
                                                                        <span><b>{address.name}</b> {address.landmark}, {address.city}. {address.address} {address.zip} <span className='locEdit'
                                                                            onClick={() => handleEditOpen(address)}>Edit</span></span>
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        </>
                                                    )) : <h2>No shipping address Found</h2>
                                                }



                                            </div>
                                            <div className="addCardBtn">
                                                <button className='acBtn' onClick={() => handleAddAdressOpen()}>
                                                    <span>+</span> ADD A NEW ADDRESS
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="orderSy">
                                                <div className="calcRow">
                                                    <div className="calcPart">
                                                        <div className="orderSum">
                                                            <h2>Order Summary</h2>
                                                            <div className="orders">
                                                                <div className="orderin">
                                                                    <div className="ordItemName">
                                                                        <span>Total Item Price</span>
                                                                    </div>
                                                                    <div className="itemPrice actualPrice">
                                                                        <span>${state?.orderPrice?.product_total}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="orderin">
                                                                    <div className="ordItemName">
                                                                        <span>Shipping price</span>
                                                                    </div>
                                                                    <div className="itemPrice">
                                                                        <span>${state?.orderPrice?.shipment_rate}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="orderin">
                                                                    <div className="ordItemName">
                                                                        <span>Service Charges</span>
                                                                    </div>
                                                                    <div className="itemPrice">
                                                                        <span>${state?.orderPrice?.service_charge}</span>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    state?.couponOffer?.offer&&<div className="orderin">
                                                                    <div className="ordItemName">
                                                                        <span>Coupon Applied</span>
                                                                    </div>
                                                                    <div className="itemPrice">
                                                                        <span>-${state?.couponOffer?.offer}</span>
                                                                    </div>
                                                                </div>
                                                                }
                                                                
                                                                <div className="orderin totalPrice clrOrange">
                                                                    <div className="ordItemName">
                                                                        <span>TOTAL</span>
                                                                    </div>
                                                                    <div className="itemPrice">
                                                                        <span>${state?.couponOffer?.offer?state?.couponOffer?.total_cost :state?.orderPrice?.total}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="cardAccept">
                                                                <p>We accepts all major credit and debit cards:</p>
                                                                <div className="cards">
                                                                    <img src={Cards} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="paymentRow">
                                        <div className="procPaybtn btnPayItem">
                                            <button onClick={() => handleToggle(2)} className='payBtn'>
                                                Proceed
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"tab-pane " + (toggle === 2 ? "active" : "")} id="tabs-2" role="tabpanel">
                                <div className="paneWrap">
                                    <div className="row pwRow">
                                        <div className="col-lg-6">
                                            <h2>Your Payment Method</h2>
                                            <div className="pmLeft dotAct">
                                                <label className='bkCredHd'>
                                                    <div className="ptBank">
                                                        <div className="bankNm nmbk">
                                                            <span></span>
                                                        </div>
                                                        <div className="nameOnCard">
                                                            <span>Name on Card </span>
                                                        </div>
                                                        <div className="expires">
                                                            <span>Expires on</span>
                                                        </div>
                                                    </div>
                                                </label>
                                                {
                                                    cards?.length === 0 ? <h2>No cards Added</h2> :
                                                        cards.map((i) => (
                                                            <label onClick={() => handleClick(i)}  className="active" >
                                                                <input checked={extraClass === i.id} type="radio" name="q1" id={i.id} value="1" onChange={() => invertClass(i.id)} defaultChecked={extraClass === i.id} />




                                                                <div className="ptBank" for="q1-n">
                                                                    <div className="bankNm">
                                                                        <h3>{i.brand} Card</h3>
                                                                        <span>ending in {i.last4}</span>
                                                                    </div>
                                                                    <div className="nameOnCard">
                                                                        <span>{i.name}</span>
                                                                    </div>
                                                                    <div className="expires">
                                                                        <span>{i.exp_month}/{i.exp_year} </span>
                                                                    </div>
                                                                </div>



                                                            </label>
                                                        ))

                                                }

                                                {/* <label className= {extraClass === 2 ? "active" : ""}>
                                                <input type="radio" name="q1" id="q2-n" value="2"  onChange={() => invertClass(2)}/>
                                                <div className="ptBank" for="q2-n">
                                                    <div className="bankNm">
                                                        <h3>Bank of America Corp.</h3>
                                                        <span>ending in 5854</span>
                                                    </div>
                                                    <div className="nameOnCard">
                                                        <span>Sam Jacob</span>
                                                    </div>
                                                    <div className="expires">
                                                        <span>08/2028 </span>
                                                    </div>
                                                </div>
                                            </label> */}
                                                {/* <label className= {extraClass === 3 ? "active" : ""}>
                                                <input type="radio" name="q1" id="q3-n" value="3"  onChange={() => invertClass(3)}/>
                                                <div className="ptBank" for="q3-n">
                                                    <div className="bankNm">
                                                        <h3>Bank of America Corp.</h3>
                                                        <span>ending in 5854</span>
                                                    </div>
                                                    <div className="nameOnCard">
                                                        <span>Sam Jacob</span>
                                                    </div>
                                                    <div className="expires">
                                                        <span>08/2028 </span>
                                                    </div>
                                                </div>
                                            </label> */}
                                            </div>
                                            <div className="addCardBtn">
                                                <button className='acBtn' onClick={handleAddCardShow}>
                                                    <span>+</span> ADD YOUR CARD
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="orderSy">
                                                <div className="calcRow">
                                                    <div className="calcPart">
                                                        <div className="deliveryAddress">
                                                            <h2>Delivery Address</h2>
                                                            <div className="delArea">
                                                                {DefaultShippingAddress&&
                                                                <p>
                                                                <span><b>{DefaultShippingAddress[0]?.name}</b></span>
                                                                <span>{DefaultShippingAddress[0]?.address} </span>
                                                                <span>{DefaultShippingAddress[0]?.city} {DefaultShippingAddress[0]?.zip} </span>
                                                                {/* <span>(257) 563-7401</span> */}
                                                            </p>
                                                                }
                                                                
                                                            </div>
                                                        </div>
                                                        <div className="orderSum">
                                                            <h2>Order Summary</h2>
                                                            <div className="orders">
                                                                <div className="orderin">
                                                                    <div className="ordItemName">
                                                                        <span>Total Item Price</span>
                                                                    </div>
                                                                    <div className="itemPrice actualPrice">
                                                                        <span>${state?.orderPrice?.product_total}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="orderin">
                                                                    <div className="ordItemName">
                                                                        <span>Shipping Price</span>
                                                                    </div>
                                                                    <div className="itemPrice">
                                                                        <span>${state?.orderPrice?.shipment_rate}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="orderin">
                                                                    <div className="ordItemName">
                                                                        <span>Service Charges</span>
                                                                    </div>
                                                                    <div className="itemPrice">
                                                                        <span>${state?.orderPrice?.service_charge}</span>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    state?.couponOffer?.offer&&<div className="orderin">
                                                                    <div className="ordItemName">
                                                                        <span>Coupon Applied</span>
                                                                    </div>
                                                                    <div className="itemPrice">
                                                                        <span>-${state?.couponOffer?.offer}</span>
                                                                    </div>
                                                                </div>
                                                                }

                                                                <div className="orderin totalPrice clrOrange">
                                                                    <div className="ordItemName">
                                                                        <span>TOTAL</span>
                                                                    </div>
                                                                    <div className="itemPrice">
                                                                        <span>${state?.couponOffer?.offer?state?.couponOffer?.total_cost :state?.orderPrice?.total }</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="cardAccept">
                                                                <p>We accepts all major credit and debit cards:</p>
                                                                <div className="cards">
                                                                    <img src={Cards} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="paymentRow">
                                        <div className="procPaybtn btnPayItem">
                                            <button onClick={() =>cards?.length === 0 ? handleAddCardShow(): handleToggle(3)} className='payBtn'>
                                                Proceed to payment
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class={"tab-pane " + (toggle === 3 ? "active" : "")} id="tabs-3" role="tabpanel">
                                <div className="confirmBooking confirmTab tabConfirm tabProdList">
                                    {/* //single product show */}
                                    {
                                     
                                    <div className="row cbRow rowCb">
                                        <div className="col-lg-6 colLcb">
                                            <div className="cbLeft h100">
                                                <div className="cbLcar h100 cblFlex">
                                                    <img src={state?.product?.product_image[0]?.image} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 colRcbd">
                                            <div className="prodDetailDec prodDec">
                                                <div className="pdHd">
                                                    <span>{state?.product?.brand}</span>
                                                    <h1>{state?.product?.title}</h1>
                                                </div>
                                                <div className="countAlter">
                                                    <span className='minus otrCircle' onClick={handleIncrement}>-</span>
                                                    <span className="countNo">{increment}</span>
                                                    <span className='plus otrCircle' onClick={()=>handleIncrement('increment')}>+</span>
                                                </div>
                                            </div>
                                            {/* <div className="item-deliver delLocation">
                                                <div className="item-deliver--edit editdlHd">
                                                    <span>Delivery Location</span>
                                                </div>
                                                <div className="item-deliver--location location--deliver">
                                                    <div className="item-deliver--location--icon">
                                                    <ImLocation color='#F87756' size={20}/>
                                                    </div>
                                                    {DefaultShippingAddress &&
                                                    <div className="item-deliver--location--locname delivLocNAme">
                                                    <span>{DefaultShippingAddress[0]?.name}</span>
                                                    <span>{DefaultShippingAddress[0]?.address} {DefaultShippingAddress[0]?.city} {DefaultShippingAddress[0]?.zip}</span>
                                                </div>
                                                    }
                                                    
                                                </div>
                                            </div> */}
                                            <div className="item-deliver-date">

                                            </div>
                                        </div>
                                    </div> 
                                    
                                    }
                                    {/* //singleproductshow end */}
                                {/* <div className="table-col-3">
                                    <div className="table-responsive table-sticky">
                                        <table className="table table-bordered no-top cartTable ">
                                            <tbody className="text-center align-middle cartTableBody cbTableBody">
                                                <tr style={{ borderBlock: 'none' }} >
                                                    <th className="text-start p-3 itemImg" scope="row">Item</th>
                                                    <th className='itemName'></th>
                                                    <th className='tbHd itemPrice'>Price </th>
                                                    <th className='tbHd itemQuantity'>Quantity</th>
                                                    <th className='tbHd itemTotal'>Sub Total</th>
                                                </tr>
                                                <tr className='cartItem'>
                                                    <td className='td-itemImg'>
                                                        <div className="product">
                                                            <div className="product--img img--product">
                                                                <img src={watch} alt="" />
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                    <div className="product">
                                                            <div className="product--name">
                                                                <h2>Tonino Lamborghini Spyder Chrono Watch</h2>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="priceForProduct">
                                                            <span className='cartPrice'>
                                                                $1399.00
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="quantityAlter">
                                                        <div className="countAlter">
                                                            <span className='minus otrCircle'>-</span>
                                                            <span className="countNo">1</span>
                                                            <span className='plus otrCircle'>+</span>
                                                        </div>
                                                        </div>
                                                    </td>
                                                    <td className='subTotal'>
                                                        <span>$1501.00</span>
                                                    </td>
                                                </tr>
                                                <tr className='cartItem'>
                                                    <td className='td-itemImg'>
                                                        <div className="product">
                                                            <div className="product--img img--product">
                                                                <img src={watch} alt="" />
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                    <div className="product">
                                                            <div className="product--name">
                                                                <h2>Men's Blue T-Shirt Lamborghini</h2>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="priceForProduct">
                                                            <span className='cartPrice'>
                                                                $1399.00
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="quantityAlter">
                                                        <div className="countAlter">
                                                            <span className='minus otrCircle'>-</span>
                                                            <span className="countNo">1</span>
                                                            <span className='plus otrCircle'>+</span>
                                                        </div>
                                                        </div>
                                                    </td>
                                                    <td className='subTotal'>
                                                        <span>$1501.00</span>
                                                    </td>
                                                </tr>
                                                <tr className='cartItem'>
                                                    <td className='td-itemImg'>
                                                        <div className="product">
                                                            <div className="product--img img--product">
                                                                <img src={watch} alt="" />
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                    <div className="product">
                                                            <div className="product--name">
                                                                <h2>LAMBORGHINI Logo Style High Quality Metal Hand Spinner Fidget</h2>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="priceForProduct">
                                                            <span className='cartPrice'>
                                                                $1399.00
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="quantityAlter">
                                                        <div className="countAlter">
                                                            <span className='minus otrCircle'>-</span>
                                                            <span className="countNo">1</span>
                                                            <span className='plus otrCircle'>+</span>
                                                        </div>
                                                        </div>
                                                    </td>
                                                    <td className='subTotal'>
                                                        <span>$1501.00</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div> */}
                            
                                </div>
                                <div className='sepe'>
                                {!ammountError?
                                    <div className="row payCalcRow">
                                        <div className="col-lg-6 colPayLeft leftcolpay">
                                            <h2>Payment Method</h2>
                                            <div className="payLin">
                                                <div className="pllleft">
                                                   {cards[0]?.name &&<span>{cards[0]?.brand} </span> } 
                                                </div>
                                                <div className="pllrt">
                                                    <div className="cardIcon">
                                                    {
                                                                    cards[0]?.brand === 'Visa' ? <img src={VisaCard} alt="visa" /> : <img src={MasterCard} alt="" />
                                                                }
                                                    </div>
                                                    <div className="accNo">
                                                        
                                                        {cards[0]?.last4 &&<h2>XXXX XXXX X{cards[0]?.last4}</h2> } 
                                                    </div>
                                                </div>
                                            </div>
                                            
                                                <div className="deliveryAddress" style={{display:'flex',marginTop:'5%'}}>
                                                            <div style={{marginTop:'5%'}} ><ImLocation color='#F87756' size={20}/></div>
                                                            <div className="delArea noUnderline" style={{marginLeft:'5%'}}>
                                                                {DefaultShippingAddress &&
                                                                    <p>
                                                                        <span className='delLocation' >Delivery Location</span><br/>
                                                                        <span>{DefaultShippingAddress[0]?.name},</span><br/>
                                                                        <span>{DefaultShippingAddress[0]?.address} </span>
                                                                        <span>{DefaultShippingAddress[0]?.city} {DefaultShippingAddress[0]?.zip} </span>
                                                                        {/* <span>(257) 563-7401</span> */}
                                                                    </p>
                                                                }

                                                            </div>
                                                        </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="calcRow calcPayment">
                                                <div className="calcPart">
                                                
                                                    <div className="orderSum">
                                                       
                                                        <h2>Order Summary</h2>
                                                        <div className="orders">
                                                            <div className="orderin">
                                                                <div className="ordItemName">
                                                                    <span>Total Item Price</span>
                                                                </div>
                                                                <div className="itemPrice actualPrice">
                                                                {/* {sessionStorage.getItem('reFetch') && coupenOffer.total_cost ? <span>{coupenOffer.total_cost}</span>: orderPrice?.total? <span>{orderPrice?.total}</span>:<span><Skeleton height={15} width={100} /></span>  } */}
                                                                
                                                                {orderPriceloading?<span><Skeleton height={15} width={100} /></span>:<span>${orderPrice ?orderPrice?.product_total :state?.orderPrice?.product_total}</span>}
                                                                
                                                                    {/* <span>${orderPrice ?orderPrice?.product_total :state?.orderPrice?.product_total?state?.orderPrice?.product_total:<Skeleton width={100} height={100}/>}</span> */}
                                                                </div>
                                                            </div>
                                                            <div className="orderin">
                                                                <div className="ordItemName">
                                                                    <span>Shipping Price</span>
                                                                </div>
                                                                <div className="itemPrice">
                                                                    
                                                                    {orderPriceloading?<span><Skeleton height={15} width={100} /></span>:<span>${orderPrice ?orderPrice?.shipment_rate :state?.orderPrice?.shipment_rate}</span>}
                                                                </div>
                                                            </div>
                                                            <div className="orderin">
                                                                <div className="ordItemName">
                                                                    <span>Service Charges</span>
                                                                </div>
                                                                <div className="itemPrice">
                                                                {/* {orderPrice?.service_charge ? <span>${orderPrice?.service_charge }</span> : state?.orderPrice?<span>${state?.orderPrice?.service_charge}</span>   } */}
                                                                {orderPriceloading?<span><Skeleton height={15} width={100} /></span>:<span>${orderPrice ?orderPrice?.service_charge :state?.orderPrice?.service_charge}</span>}
                                                                    
                                                                </div>
                                                            </div>

                                                            {
                                                                    coupenOffer?.offer&&<div className="orderin">
                                                                    <div className="ordItemName">
                                                                        <span>Coupon Applied</span>
                                                                    </div>
                                                                    <div className="itemPrice">
                                                                    {orderPriceloading?<span><Skeleton height={15} width={100} /></span>:<span>-${coupenOffer ?coupenOffer?.offer :state?.couponOffer?.offer}</span>}
                                                                        
                                                                    </div>
                                                                </div>
                                                                }


                                                            <div className="orderin totalPrice">
                                                                <div className="ordItemName">
                                                                    <span style={{color:"#F87352"}}>TOTAL</span>
                                                                </div>
                                                                <div className="itemPrice">
                                                                {orderPriceloading?<span><Skeleton height={15} width={100} /></span>:coupenOffer?.total_cost ? <span style={{color:"#F87352"}} >${coupenOffer?.total_cost}</span>:state?.couponOffer?.offer? <span style={{color:"#F87352"}}>${state?.couponOffer?.total_cost}</span>:<span style={{color:"#F87352"}}>${orderPrice?.total}</span>}
                                                                    {}
                                                                    {/* <span>${coupenOffer?coupenOffer?.total_cost: state?.couponOffer?.offer?state?.couponOffer?.total_cost }</span> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> :
                                    <div className="calcRow calcPayment">
                                    <div className="calcPart">
                                    <h5>Requested stock not available</h5>
                                        </div>
                                        </div>
                                    
                                    }
                                  {!ammountError&&<div class="paymentRow">
                                        <div class="procPaybtn btnPayItem">
                                            {ProductpaymentLoading ? <button disabled class="payBtn" >Loading <Spin /> </button>:
                                            <button class="payBtn" onClick={()=>handlePayment({price:state?.orderPrice,product:state?.product,shipping:state?.shipping_details,card_id:cards[0]?.id,qnty:state?.qnty,coupon:state?.couponOffer,shipping_address_id:DefaultShippingAddress[0]?.id})} >Make payment</button>

                                            }
                                        </div>
                                    </div>}  
                                    
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Tabs2;
