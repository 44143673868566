import Cookies from "js-cookie";
import React from 'react';
// import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = () => {
    // const {isauth} =useSelector(state =>state.Login)
   
  return   Cookies.get('token') ? <Outlet/> : <Navigate  to='/login'/>
  
  }
export default PrivateRoute;
