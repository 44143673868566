import { Alert, message } from 'antd';
import Cookies from 'js-cookie';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SpBanner from '../images/lamborgini.png';
import regBg from '../images/regImg.png';
import { Login } from '../Redux/Features/Authslice';
import PasswordToggle from './PasswordToggle';
function LoginView() {
    const { register, handleSubmit, formState: { errors } } = useForm()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { LoginError,loading } = useSelector(state => state.Auth)
    const [InputType,Icon] = PasswordToggle();
    const {state}=useLocation()
    console.log(state,'state');
    console.log(state?.path);

    const onSubmit = (data) => {
        dispatch(Login(data)).then((re) => {
            console.log(re?.payload?.data?.user?.id,'userId');
            re.type=== "auth/login/fulfilled" &&
            message.success('Login Success')
            Cookies.set('token',re.payload.data.token)
            localStorage.setItem('revup userId',re?.payload?.data?.user?.id)
            localStorage.setItem('revup user',re.payload.data.user.full_name)
            localStorage.setItem('phone', re.payload.data.user.phone)
            localStorage.setItem('email', re.payload.data.user.email)
            
            state?.path !== undefined ? navigate(`/car-detail/${state.path}`): navigate('/')
        })
    }


    return (
        <div>
            <div className="bannerSec spBanner" style={{ backgroundImage: `url('${SpBanner}')` }}>
                <div className="WrapperBnr">

                </div>
            </div>
            <div className="accRegistration">
                <div className="container">
                    <div className="row accRow">
                        <div className="col-lg-6 colRgLeft p-0">
                            <div className="rgLeftWrap">
                                <div className="rgLcon h100">
                                    <div className="rgLHd">
                                        <h2><span>Enjoy the </span> <span>Experience with us!</span></h2>
                                    </div>
                                    <div className="rgLimg">
                                        <img src={regBg} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 colRgRight p-0">
                            <div className="rgRtWrap">
                                <div className="rgRtHd">
                                    <h2>Welcome Back!</h2>
                                    <span>Sign in to your account</span>
                                </div>
                                <div action="#" class="register-form">
                                    { LoginError&& LoginError === "Invalid login credentials"?<h2 style={{color:'#F6491E'}} >{LoginError}</h2>:<h6 style={{color:'#F6491E'}} >{LoginError}</h6>}
                                    <form onSubmit={handleSubmit(onSubmit)} >
                                        <div className="formWrap">
                                            <div className="form-controller">

                                                <label htmlFor="">Email</label>
                                                <input type="email" name="email" placeholder="Email"  {...register('email', {
                                                    required: 'E-mail is required',
                                                    pattern: /^\S+@\S+$/i,
                                                })} />
                                                {errors?.email && <p style={{ color: 'red' }}>{errors?.email.message}</p>}
                                            </div>
                                            <div className="form-controller">
                                                <label htmlFor="">Password</label>
                                                <input type={InputType} name="password" placeholder="Password" {...register('password', { required: 'Password is required', minLength: { value: 6, message: 'Password must be minimum 6 characters' } })} />
                                                <span className="passwordToggleIcon">
                                                    {Icon}
                                                </span>
                                                {errors?.password && <p style={{ color: 'red' }}>{errors?.password.message}</p>}
                                            </div>
                                        </div>
                                        <div className="forgetPass">
                                            <p  class="fp"><span style={{cursor:'pointer'}} onClick={()=>navigate('/forgot-password')} >Forgot Password</span> </p>
                                        </div>

                                        <button disabled={loading} type="submit" class="btn">Login</button>
                                    </form>                                   
                                    <p class="message">Don't have an account? <Link to='/user-info'><a href="#">Register</a></Link> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    );

}

export default LoginView;
