import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tshirt from '../images/tshirt.png'
import watch from '../images/watch.png'
import moment from 'moment';
import Rating from 'react-rating';
import InfiniteScroll from 'react-infinite-scroll-component';
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import { GetAllOrders } from '../Redux/Features/Orderslice';
import { Skeleton } from 'antd';
function OrdersView() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [orders, setorders] = useState()
  const [count, setcount] = useState(1)
  const [hasMore, sethasMore] = useState(true)
  const { orderCount, loading } = useSelector(state => state.Orders)
  const fetchData =()=>{
    dispatch(GetAllOrders({data:count})).then((res)=>{
      setcount(count+1)
      // console.log(res,'res after first iteration');
      setorders([...orders,...res?.payload?.data.orders])
    if (res?.payload?.data.orders.length=== 0 || res?.payload?.data.orders<11) {
      sethasMore(false)
    }  
      

    })
  }
  useEffect(() => {
    dispatch(GetAllOrders({data:0})).then((res)=>{
      console.log(res?.payload?.data,'the data recived after fetching in page');
      setorders(res?.payload?.data.orders)
    })

  }, [dispatch])
console.log(orders,'orders');
  return (
    <div>
      <div className="ordersView">
        <div className="container">
          <div className="orderWrapper">
            <h1>Orders</h1>
           {/* <h1>{orderCount }fff</h1>  */}
            {loading && loading && [...Array(4)].map((_, i) => (
              <div key={i}>
                <Skeleton avatar active loading />
              </div>
            ))}
            {!loading &&
<InfiniteScroll
  dataLength={orders ?orders.length:10} //This is important field to render the next data
  next={fetchData}
  hasMore={hasMore}
  loader={loading &&<h4>loading</h4>}
  endMessage={
    <p style={{ textAlign: 'center' }}>
     {orders?.length<11 ?<></> :<b>Yay! You have seen it all</b>} 
    </p>
  }
 
>
 {orders?.length > 0 ? orders?.map((order) => (
              <>
              
                <div className="orderItem" onClick={() => {
                  if (order?.order_item[0]?.status === 'Delivered') {
                    navigate(`/order-delivered/${order.id}`, { state: { order } })
                  }
                  else if  (order?.order_item[0]?.status === 'Shipped') {
                    navigate(`/order-onway/${order.id}`, { state: { order } })
                  }
                  else if (order?.order_item[0]?.status === 'Ordered') {
                    navigate(`/order-detail/${order.id}`, { state: { order } })
                  }
                  else if (order?.order_item[0]?.status === 'Cancelled') {
                    navigate(`/order-cancel/${order.id}`, { state: { order } })
                  }
                  else{
                    navigate(`/order-detail/${order.id}`, { state: { order } })
                  }
                }}>
                  <article>
                    <div id="product--image" >
                      <div className="proImg">
                        <img src={order?.order_item[0]?.product?.product_image[0]?.image} alt="" />
                      </div>
                    </div>
                    <div id="product--name" >
                      <div className="proName">
                        <span className='orderno'>ORDER # {order?.code}</span>
                        <h2>{order?.order_item[0]?.product?.brand.substr(0,10)}   {order?.order_item[0]?.product?.title.substr(0,15)}</h2>
                        {/* <span style={{
                          color: order?.order_item[0]?.status === 'Cancelled' ? "red" : "",

                        }} className='orderDate'>
                          
                          {order?.order_item[0]?.status==='Delivered'}Delivered On {moment.utc(order.created_at).local().format('MMMM DD,YYYY ')} 
                          
                           </span> */}
                           {order?.order_item[0]?.status==='Delivered'&&<span  className='orderDate'>Delivered On {moment.utc(order?.created_at).local().format('MMMM DD,YYYY ')} </span>}
                           {order?.order_item[0]?.status==='Ordered'&&<span  className='orderDate'>Order Placed On {moment.utc(order?.created_at).local().format('MMMM DD ')} </span>}
                           {order?.order_item[0]?.status==='Shipped'&&<span  className='orderDate'>Order Shipped On {moment.utc(order?.created_at).local().format('MMMM DD ')} </span>}
                           {order?.order_item[0]?.status==='Cancelled'&&<span style={{color:'red'}}  className='orderDate'>Cancelled On {moment.utc(order?.created_at).local().format('MMMM DD,YYYY ')} </span>}
                      </div>
                    </div>
                    {/* {
                      order?.order_item[0]?.status === 'Delivered' &&
                       order?.order_item[0]?.review === null ?
                       
                      <div id="product--rating" >
                        <>
                        <p>Write Review</p>
                        </>
                        <div className="proRating">
                          <p>Rate this product now</p>

                          <Rating
                            onChange={(rate) => console.log(rate)}
                            emptySymbol={<AiOutlineStar size={40} color='#DEDEDE' />}
                            fullSymbol={<AiOutlineStar size={40} color='gold' />}
                          />
                        </div>
                      </div>

                      : <div id="product--rating" >
                        <Rating
                          initialRating={order?.order_item[0]?.review?.rating}
                          emptySymbol={<AiOutlineStar size={40} color='#DEDEDE' />}
                          fullSymbol={<AiFillStar size={40} color='#FEBA1B' />}
                          readonly
                        />
                        
                      </div>

                    
                    } */}
                    
                    {
                      order?.order_item[0]?.status === 'Ordered' && <div id="product--rating" >
                        <div style={{width:'300px'}} className="orderTrack">
                        
                      </div>
                      </div>
                    }
                    {
                      order?.order_item[0]?.status === 'Cancelled' && <div id="product--rating" >
                        <div style={{width:'300px'}} className="orderTrack">
                        
                      </div>
                      </div>
                    }
                    {
                      order?.order_item[0]?.status === 'Shipped' && 
                      <div style={{display:'flex',justifyContent:'flex-end'}} id="product--rating" >
                      <div className="orderTrack">
                        <button className='trackBtn'>
                          TRACK -KH0213525
                        </button>
                      </div>
                    </div>
                    }
                    {
                      order?.order_item[0]?.status === 'Delivered' &&order?.order_item[0]?.review?.rating&& <div style={{display:'flex',justifyContent:'flex-end'}} id="product--rating" >
                      <Rating
                        initialRating={order?.order_item[0]?.review?.rating}
                        emptySymbol={<AiOutlineStar size={40} color='#DEDEDE' />}
                        fullSymbol={<AiFillStar size={40} color='#FEBA1B' />}
                        readonly
                      />
                      
                    </div>
                    }
                    {
                      order?.order_item[0]?.status === 'Delivered' &&order?.order_item[0]?.review==null&& 
                       <div style={{display:'flex',justifyContent:'flex-end'}} className='rowDouble' id="product--rating" >
                      <>
                      <p className='reviewWrite'>Write Review</p>
                      </>
                      <div className="proRating">
                        <p>Rate this product now</p>

                        <Rating
                          onChange={(rate) => console.log(rate)}
                          emptySymbol={<AiOutlineStar size={40} color='#DEDEDE' />}
                          fullSymbol={<AiOutlineStar size={40} color='gold' />}
                        />
                      </div>
                    </div>
                    }
                    {/* {
                      order?.order_item[0]?.status === 'Delivered' && order?.order_item[0]?.review === null ?
                      <>no review</>:
                    } */}
                    

                  </article>
                </div>
              </>
            )) : !loading && <>
              <h2>No orders found</h2>
            </>
}
            
</InfiniteScroll>
       }     
            

            {/* <div className="orderItem">
            <article>
                <div id="product--image" >
                  <div className="proImg">
                    <img src={watch} alt="" />
                  </div>
                </div>
                <div id="product--name" >
                  <div className="proName">
                    <span className='orderno'>ORDER #21252265</span>
                    <h2>Men'S T-Shirt Lamborghini</h2>
                    <span className='orderDate'>Order Placed On Nov 22</span>
                  </div>
                </div>
                <div id="product--rating" >
                  <div className="orderTrack">
                    <button className='trackBtn'>
                      TRACK -KH0213525
                    </button>
                  </div>
                </div>
            </article>
          </div> */}
            {/* <div className="orderItem">
            <article>
                <div id="product--image" >
                  <div className="proImg">
                    <img src={tshirt} alt="" />
                  </div>
                </div>
                <div id="product--name" >
                  <div className="proName">
                    <span className='orderno'>ORDER #21252265</span>
                    <h2>Men'S T-Shirt Lamborghini</h2>
                    <span className='orderDate cancel'>Cancelled On Nov 09,2021</span>
                  </div>
                </div>
                <div id="product--rating" >
                  <div className="proRating">
                    <p>Rate this product now</p>
                    <div class="stars">
                      <span class="fa fa-star"></span>
                      <span class="fa fa-star"></span>
                      <span class="fa fa-star"></span>
                      <span class="fa fa-star"></span>
                      <span class="fa fa-star"></span>
                    </div>  
                  </div>
                </div>
            </article>
          </div> */}
            {/* <div className="orderItem">
            <article>
                <div id="product--image" >
                  <div className="proImg">
                    <img src={watch} alt="" />
                  </div>
                </div>
                <div id="product--name" >
                  <div className="proName">
                    <span className='orderno'>ORDER #21252265</span>
                    <h2>Men'S T-Shirt Lamborghini</h2>
                    <span className='orderDate'>Order Placed On Nov 22</span>
                  </div>
                </div>
                <div id="product--rating" >
                  <div className="proRating">
                    <p>Rate this product now</p>
                    <div class="stars">
                      <span class="fa fa-star"></span>
                      <span class="fa fa-star"></span>
                      <span class="fa fa-star"></span>
                      <span class="fa fa-star"></span>
                      <span class="fa fa-star"></span>
                    </div>  
                  </div>
                </div>
            </article>
          </div> */
          }
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrdersView;