

// import React from 'react'
// import { Button, Modal,Form  } from 'react-bootstrap';
// import { loadStripe } from "@stripe/stripe-js";

// import { useEffect, useState } from "react";

// import { AddNewCard } from '../Redux/Features/Cardslice';
// import { useDispatch, useSelector } from 'react-redux';
// import { toast, ToastContainer } from 'react-toastify';
// import { message } from 'antd';


// function AddCard({handleAddCardClose,addCard,setrefetchcard}) {
    
   
//         const [stripe, setstripe] = useState()
//         const [load, setload] = useState(false)
//         // const [loading, setloading] = useState()
//         const [nameOnCard, setnameOnCard] = useState()
//          const dispatch=useDispatch()
//          const {loading}=useSelector(state=>state.Card)
    
// const handleRefetch =()=>{
//   setload(true)
//   handleAddCardClose()
//   setrefetchcard(true)
//   setrefetchcard(false)
//   message.success('Card added successfully')
//   setload(false)
  
// }

//     let stripePromise;

//     const getStripe = async () => {
//         if (!stripePromise) {
//             stripePromise = await loadStripe('pk_test_ZhcC8ZgxbuaVnZ5T9wrISWUx00Xl80CLQR')

//         }

//         setstripe(stripePromise)
//     };

//     useEffect(() => {
//         getStripe()
//     }, [])
 
//     var style = {
//         base: {
//           color: '#32325d',
//           lineHeight: '18px',
//           fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
//           fontSmoothing: 'antialiased',
//           fontSize: '16px',
//           padding:'30px',
//           '::placeholder': {
//             color: '#aab7c4'
//           }
//         },
//         invalid: {
//           color: '#fa755a',
//           iconColor: '#fa755a'
//         }
//       }
     
//       var elements = stripe?.elements();
//         var cardElement = elements?.create('card',{style: style});
//         cardElement?.mount('#card-element');

//     const handleChange = async () => {
//         // const stripe = await getStripe();
       
        
//       const {token,TokenError} = await stripe.createToken(cardElement,{name:nameOnCard})
//       TokenError && message.error(TokenError.message);
//    console.log(token.id);
//    return token.id
   
//     }
 
//    const manageChange =async()=>{
    
//       const res= await handleChange()
      
//       res !=='' &&dispatch(AddNewCard(res)).then((result)=>result.payload.message !== 'Ok' ? 
//       toast('Please Enter Valid Details')
//       :handleRefetch())
      
//    }

   
//   return (
//     <Modal show={addCard} onHide={handleAddCardClose} className='modalDuration'>
//       <ToastContainer
//                     position="top-center"
//                     autoClose={3300}
//                     hideProgressBar
//                     newestOnTop
//                     closeOnClick
//                     rtl={false}
//                     pauseOnFocusLoss
//                     draggable
//                     pauseOnHover
//                 />
//         <Modal.Header closeButton className='border-0'>
//           <Modal.Title>ADD YOUR CARD</Modal.Title>
          
//         </Modal.Header>
//         <Modal.Body>
        
          
//         <div  >



            
//         <div className="register-form">
//         <div class="form-controller">
//           </div>
//         <input type='text' style={{marginBottom:'25px',marginTop:'0px'}}  placeholder='Name on Card' onChange={(e)=>setnameOnCard(e.target.value)} />
//           </div>
            
            
//            <div style={style.base} id="card-element"></div>
            
            
//             {/* <button  onClick={handleChange} id="card-button">Save Card</button> */}

//         </div>




//         </Modal.Body>
//         <Modal.Footer className='border-0'>
//           <Button variant="secondary" onClick={handleAddCardClose} className='closeBtn'>
//             Cancel 
//           </Button>
//           <Button disabled={loading}  id="card-button" variant="primary" onClick={ manageChange} className='saveBtn'>
//             Done
//           </Button>
//         </Modal.Footer>
//       </Modal>
//   );
// }

// export default AddCard;




import { CardCvcElement, CardExpiryElement, CardNumberElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { message } from 'antd';
import React, { useState } from 'react'
import { Button, Modal,Form  } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Cards from '../images/cards.png'
import { AddNewCard } from '../Redux/Features/Cardslice';
import { toast, ToastContainer } from 'react-toastify';

const stripePromise = loadStripe('pk_test_ZhcC8ZgxbuaVnZ5T9wrISWUx00Xl80CLQR');
function AddCard({handleAddCardClose,addCard,setrefetchcard}) {
  const isValidPasswordRegex = /^[a-zA-Z ]*$/;
  const options = {
    // passing the client secret obtained from the server
    clientSecret: 'pk_test_51KVu5xSAw1L3e61ZdB9X99ecpVyRng0of3WajbVAsNASA1kNEf9KpAm2tMUDJuxnVYU90rT1Yr32cUbT68QGe1iG00EyW4BCKg',
  };
  return (
    <>
    <Elements stripe={stripePromise} >
   < MyComponent handleAddCardClose={handleAddCardClose} addCard={addCard} setrefetchcard={setrefetchcard} />
   </Elements>
    </>
  );
}

export default AddCard;


const MyComponent = ({handleAddCardClose,addCard,setrefetchcard}) => {
  // console.log('props',props);
  // const stripe = useStripe();
  // const elements = useElements();

  // rest of the component
  // console.log('stripe',stripe);
  // console.log('elements',elements);

  // const handleSubmit=async (e)=>{
  //    e.preventDefault()
  //    const cardElement = elements.getElement(CardNumberElement);
  //   //  console.log(cardElement);
  //     await stripe.createToken(cardElement,{name:'Marcus'}).then((res)=>{
  //       console.log(res);
  //       res.token ? message.success('card added successfully'):
  //       message.info(res.error.message);
  //     })
  //   //  error && console.log(error);
  //   //  paymentMethod&& console.log(paymentMethod);
  // }
  // const [nameOnCard, setnameOnCard] = useState('')
  const [load, setload] = useState(false)
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch()
  const [nameOnCard, setnameOnCard] = useState('')
  const [nameOnCardError, setnameOnCardError] = useState('')
  const isValidPasswordRegex = /^[a-zA-Z ]*$/;
  const {loading}=useSelector(state=>state.Card)
  const handleSubmit =async(e)=>{
    if(!nameOnCard){
  
      setnameOnCardError('Name on Card is required')
    }else if(nameOnCard.length<3){
      
      setnameOnCardError('Name on card must be atleast 3 characters')
    }else if(nameOnCard.length>25){
      
      setnameOnCardError('Name should be less than 25 characters')
    }else if(!isValidPasswordRegex.test(nameOnCard)){
      
      setnameOnCardError('Name on Card must be alphabets')
    }else{
      setnameOnCardError('')
      setload(true)
    e.preventDefault()
    setload(true)
    
    const cardElement = elements.getElement(CardNumberElement);
   //  console.log(cardElement);
     await stripe.createToken(cardElement,{name:nameOnCard}).then((res)=>{
       console.log(res);

       res.token ? dispatch(AddNewCard(res.token.id)).then((result)=>{
         
         if (result.payload?.message === 'Ok') {
          handleAddCardClose()
          setload(false)
          message.success('Payment card added successfully ')
          setrefetchcard(true)
          setrefetchcard(false)
         }else{
           toast(result.payload);
         }
       }):
      //  message.info( {
      //   content:  res.error.message,
      //   className: 'custom-class',
      //   style: {
      //     marginTop: '28vh',
      //   },
      // }     );
      toast(res.error.message)
       setload(false)
     })
    }
    
  }

  return(
    <Modal show={addCard} onHide={handleAddCardClose} className='modalDuration'>
    <Modal.Header closeButton className='border-0'>
      <Modal.Title>Add a credit or debit card</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className='chooseLocation'>
        <div className="cardAccept mt-0">
            <p>We accepts all major credit and debit cards:</p>
            <div className="cards">
                <img src={Cards} alt="" />
            </div>
        </div>
        <div className="row accRow contactRow">                    
            <div className="col-lg-12 colRgRight p-0 align-self-center">
                <div className="rgRtWrap">
                    <div action="#" className="register-form addCardFrm">
                        <div className="formWrap FormRow frmCard row">
                        <div className="form-controller col-lg-12">
                                <label htmlFor="">Name On Card</label>
                                <input onChange={(e)=>
                                {
                                  setnameOnCard(e.target.value)
                                  setnameOnCardError('')
                                }
                                  } type="text" name="ln" placeholder='Name On Card' />
                                {nameOnCardError&& <p style={{ color: 'red' }}>{nameOnCardError}</p>}
                            </div> 
                            <div  className="form-controller col-lg-12">
                                <label htmlFor="">Card Number</label>
                                <div style={{border:'1px solid #E1E1E1 ',padding:'15px',width:'100%',backgroundColor:'#FCFCFC',borderRadius:'4px'}} >
                                <CardNumberElement />
                                </div>
                                
                            </div>
                             
                            <div className="form-controller col-lg-12">
                                {/* <label htmlFor=""></label> */}
                                <div className="expDateSelect">
                                    <div className="customs_selects">
                                    <div style={{border:'1px solid #E1E1E1  ',padding:'15px',width:'100%',backgroundColor:'#FCFCFC',borderRadius:'4px'}} >
                                    <CardExpiryElement />
                                    </div>
                                    </div>
                                    <div className="customs_selects">
                                    <div style={{border:'1px solid #E1E1E1 ',padding:'15px',width:'100%',backgroundColor:'#FCFCFC',borderRadius:'4px'}} >
                                      
                                    <CardCvcElement />
                                    </div>
                                    </div>
                                </div>
                            </div> 
                           
                        </div>
                    </div> 
                </div>
            </div>
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer className='border-0'>
      <Button variant="secondary" onClick={handleAddCardClose} className='closeBtn'>
        Cancel 
      </Button>
     {!load && !loading ? <Button disabled={load&&loading} variant="primary" onClick={handleSubmit} className='saveBtn'>
        ADD CARD
      </Button>:<Button  variant="danger">Loading ...</Button>} 
    </Modal.Footer>
    <ToastContainer
                    position="top-center"
                    autoClose={3300}
                    hideProgressBar
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
  </Modal>
  )
};





