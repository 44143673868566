import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import SpBanner from '../images/lamborgini.png'
import watch from '../images/watch.png'
import delIcon from '../images/delIcon.png'
import { GetOrdersById, GetShippingById } from '../Redux/Features/Orderslice';
import { ImLocation } from "react-icons/im";
import Skeleton from 'react-loading-skeleton';

import moment from 'moment';
function OrderCancelView() {
    const {SingleOrder} =useSelector(state=>state.Orders)
    const dispatch =useDispatch()
    const {id}=useParams()
    useEffect(() => {
        dispatch(GetOrdersById(id)).then((res)=>{
            // console.log(res?.payload?.data?.order?.id,'orders here');
            dispatch(GetShippingById(res?.payload?.data?.order?.shipping_address_id)).then((shipping)=>{
                // console.log(shipping,'by id');
            })
        })
        
      }, [dispatch])
  return (
    <div>
         <div className="bannerSec spBanner" style={{ backgroundImage: `url('${SpBanner}')` }}>
            <div className="WrapperBnr">
                <div className="container bnrCon h100 p-0">
                    <div className="orderSRow">
                        <div className="odHd">
                            <h1>Order Details</h1>
                        </div>
                        <div class="row cbRow orderSingleRow">
                            <div class="col-lg-5">
                                <div class="cbLeft h100">
                                <div class="cbLpro h100">{SingleOrder?.order_item &&<img src={SingleOrder?.order_item[0]?.product?.product_image[0].image} alt="" />}</div>
                                </div>
                            </div>
                            <div class="col-lg-7">
                                <div class="cbRight">
                                    <div class="orderDetailRt">
                                        <div className="orderDate">
                                        <div style={{display:'flex'}}>
                                            <span className='ordId'>PRODUCT CODE </span>&nbsp;<span style={{fontSize:'larger',fontWeight:'600'}}>({SingleOrder?.order_item &&SingleOrder?.order_item[0]?.product?.code})</span>
                                                </div>
                                                <span className='ordId'>ORDER # {SingleOrder?.code}</span>
                                                <span className='ordDt'> {moment.utc(SingleOrder?.transaction?.created_at).local().format('MMMM DD, h:mm a ')}</span>

                                        </div>
                                        <div className="orderedProduct">
                                        {SingleOrder?.order_item && <h2>
                                             <span>{SingleOrder?.order_item[0]?.product?.brand}</span>
                                             {SingleOrder?.order_item[0]?.product?.brand} {SingleOrder?.order_item[0]?.product?.title}
                                            </h2>}
                                            <h5>Quantity: {SingleOrder?.total_qty}</h5>
                                        </div>
                                        <div className="orderInfo">
                                            <div className="oiLeft orderCancel">
                                                <span className='alertOrder'>
                                                    {/* <i className="fas fa-shipping-fast"></i> */}
                                                    <img src={delIcon} alt="" />
                                                    <span>
                                                        Order has been cancelled
                                                        {SingleOrder?.order_item &&  <span className='cancelDate'>On {moment.utc(SingleOrder?.order_item[0]?.updated_at).local().format('MMMM DD YYYY')} </span>} 
                                                    </span>                                                
                                                </span>                                                
                                            </div>
                                            <div className="oiRt"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="orderPayment">
            <div className="container p-0">
                <div className="row opRow">
                    <div className="col-lg-6 colOpLt">
                        <div className="item-deliver">
                        <h5 style={{ color: '#F6491E' }}>
                                    Order Cancelled on
                                    {SingleOrder?.order_item && <span className='cancelDate'> {moment.utc(SingleOrder?.order_item[0]?.updated_at).local().format('MMMM DD ')} </span>}
                                </h5>
                            <div className="item-deliver--edit">
                                <span>Deliver To</span>
                            </div>
                            <div className="item-deliver--location">
                                <div className="item-deliver--location--icon">
                                <ImLocation color='#F87756' size={20}/>
                                </div>
                                <div className="item-deliver--location--locname">
                                <h2>
                                        <span>{SingleOrder?.shipping_address?.city}</span>
                                        {SingleOrder?.shipping_address?.address},{SingleOrder?.shipping_address?.zip}
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 colOpRt">
                    <div className="calcRow">
                        <div className="calcPart">
                            <div class="orderSum">
                                <h2>Order Summary</h2>
                                <div class="orders">
                                            <div class="orderin">
                                                <div class="ordItemName"><span>Total Item Price</span></div>
                                                <div class="itemPrice actualPrice"><span>{SingleOrder?.transaction?.item_amount ?`$ ${SingleOrder?.transaction?.item_amount}` :<Skeleton height={20} width={60}/>}</span></div>
                                            </div>
                                            <div class="orderin">
                                                <div class="ordItemName"><span>Shipping Price</span></div>
                                                <div class="itemPrice"><span>{SingleOrder?.transaction?.delivery_fee?`$ ${SingleOrder?.transaction?.delivery_fee}` :<Skeleton height={20}  width={60}/>} </span></div>
                                            </div>
                                            <div class="orderin">
                                                <div class="ordItemName"><span>Service Charges</span></div>
                                                <div class="itemPrice"><span>{SingleOrder?.transaction?.service_charge ?`$ ${SingleOrder?.transaction?.service_charge}`:<Skeleton height={20}  width={60}/>}</span></div>
                                            </div>
                                            {SingleOrder?.transaction?.discount &&
                                                <div class="orderin">
                                                    <div class="ordItemName"><span>Promocode Discount</span></div>
                                                    <div class="itemPrice"><span>{ SingleOrder?.transaction?.discount ?`- $ ${SingleOrder?.transaction?.discount}` :<Skeleton height={20}  width={60}/>}</span></div>
                                                </div>
                                            }
                                            <div class="orderin totalPrice">
                                                <div class="ordItemName"><span>TOTAL</span></div>
                                                <div class="itemPrice"><span>{SingleOrder?.transaction?.total_amount? `$ ${SingleOrder?.transaction?.total_amount}`:<Skeleton height={20}  width={60}/>}</span></div>
                                            </div>
                                        </div>
                                <div className="paidCred">
                                    <div class="paidviaCred">
                                        <div className="pcredwrap">                                            
                                            <div className="refundMsg">
                                                <div className='refifpaid'> 
                                                    <span><i class="fa fa-check"></i></span> Paid via credit card ending {SingleOrder?.transaction?.card_last4}   
                                                </div>
                                                <p>Refund Initiated</p>
                                            </div>  
                                        </div>                                                                           
                                    </div>                                    
                                </div>                        
                            </div>
                        </div>
                    </div>
                    
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default OrderCancelView;
