import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../../Axios';
import Cookies from 'js-cookie'
export const GetAllOrders = createAsyncThunk('orders/getall', async (obj, { rejectWithValue }) => {
  let userId = localStorage.getItem('revup userId')
  try {
    const res = await axios.get(`/order?offset=${obj.data * 10}&limit=10&where={"created_by":${userId}}&populate=%5B%22order_item%22%2C%22order_item.review%22%2C%22order_item.product%22%2C%22order_item.product.product_image%22%5D&sort=%5B%5B%22created_at%22%2C%22desc%22%5D%2C%5B%22order_item%22%2C%22product%22%2C%22product_image%22%2C%22is_thumbnail%22%2C%22desc%22%5D%5D`,
      { headers: { Authorization: `Bearer ${Cookies.get('token')}` } }
    )
    return res.data
  } catch (error) {
    return rejectWithValue(error)
  }
})
export const GetOrdersById = createAsyncThunk('orders/getbyid', async (obj, { rejectWithValue }) => {
  try {
    const res = await axios.get(`/order/${obj}?populate=%5B%22order_item%22%2C%22order_item.product%22%2C%22order_item.review%22%2C%22order_item.product.product_image%22%2C%22%2Bshipping_address%22%2C%22transaction%22%5D&sort=%5B%5B%22order_item%22%2C%22product%22%2C%22product_image%22%2C%22is_thumbnail%22%2C%22desc%22%5D%5D`,
      { headers: { Authorization: `Bearer ${Cookies.get('token')}` } }
    )
    return res.data
  } catch (error) {
    return rejectWithValue(error)
  }
})
export const GetShippingById = createAsyncThunk('orders/getshippingbyid', async (obj, { rejectWithValue }) => {
  // console.log(obj, 'single order idhghg');
  // try {
  //     const res=await  axios.get(`shipping_address/${obj}`,
  //     { headers: { Authorization: `Bearer ${Cookies.get('token')}` } }
  //     )
  //     return res.data
  // } catch (error) {
  //    return rejectWithValue(error) 
  // }
})
export const RateProduct = createAsyncThunk('orders/rateProduct', async (obj, { rejectWithValue }) => {
  // console.log(obj, 'obj in rate product');
  try {
    const res = await axios.post(`/review`,
      {
        "product_id": obj?.proId,
        "order_item_id": obj?.orderItemId,
        "rating": obj?.rating,
        "review": obj?.review

      },
      { headers: { Authorization: `Bearer ${Cookies.get('token')}` } }
    )
    return res.data
  } catch (error) {
    return rejectWithValue(error)
  }
})
export const UpdateProductReviewById = createAsyncThunk('orders/UpdateProductReviewById', async (obj, { rejectWithValue }) => {
  // console.log(obj, 'edit review product');
  try {
    const res = await axios.put(`/review/${obj?.id}`,
      {
        "active": obj?.active,
        "product_id": obj?.proId,
        "order_item_id": obj?.orderItemId,
        "rating": obj?.rating,
        "review": obj?.review,

      },
      { headers: { Authorization: `Bearer ${Cookies.get('token')}` } }
    )
    return res.data
  } catch (error) {
    return rejectWithValue(error)
  }
})


const initialState = {
  loading: 'false',
  orders: [],
  SingleOrder: {},
  orderCount: null
}

const Orderslice = createSlice({
  name: "second",
  initialState,
  reducers: {},
  extraReducers: {
    [GetAllOrders.pending]: (state, action) => {
      state.loading = true
      state.orders = {}
    },
    [GetAllOrders.fulfilled]: (state, action) => {
      state.loading = false
       state.orders=[...action.payload.data.orders]
      // state.orders.push(action.payload.data)
      state.orderCount = action.payload.data.count
      // console.log(action.payload.data.count, 'fetched orders');

    },
    [GetAllOrders.rejected]: (state, action) => {
      state.loading = false
    },
    [GetOrdersById.pending]: (state, action) => {
      state.loading = true
      state.SingleOrder = {}
    },
    [GetOrdersById.fulfilled]: (state, action) => {
      state.loading = false
      state.SingleOrder = action.payload.data.order
      // console.log(action.payload.data.order, 'fetched single orders');

    },
    [GetOrdersById.rejected]: (state, action) => {
      state.loading = false
    },
  }
});

// export const {} = Orderslice.actions

export default Orderslice.reducer