import React from 'react';
import AddPaymentView3 from '../Componants/AddPaymentView3';
function AddPayment3() {
  
  return (
    <div>
        <AddPaymentView3 />
    </div>
  );
}

export default AddPayment3;