import React from 'react';
import Footer from '../Componants/Footer';
import LoginView from '../Componants/LoginView';
import NavBar from '../Componants/NavBar';
function Login() {
  return (
    <div>
        <NavBar />
        <LoginView />
        <Footer/>
    </div>
  );
}

export default Login;
