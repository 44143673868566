import { message } from 'antd';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import SpBanner from '../images/lamborgini.png';
import regBg from '../images/regImg.png';
import { Register } from '../Redux/Features/Authslice';
import PasswordToggle from './PasswordToggle';
function UserInfoView() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [InputType,Icon] = PasswordToggle();
    const { register, handleSubmit,control, formState: { errors } } = useForm()


    const onSubmit = (data) => {

        console.log(data);
        dispatch(Register(data)).then((res) => {
            console.log('res', res);
            res?.payload?.response?.data?.error ? message.info(res.payload.response.data.error) : navigate('/verify-account')
        })


    }
    

    return (
        <div>
            <div className="bannerSec spBanner" style={{ backgroundImage: `url('${SpBanner}')` }}>
                <div className="WrapperBnr">

                </div>
            </div>
            <div className="accRegistration">
                <div className="container">
                    <div className="row accRow">
                        <div className="col-lg-6 colRgLeft p-0">
                            <div className="rgLeftWrap">
                                <div className="rgLcon h100">
                                    <div className="rgLHd">
                                        <h2><span>Enjoy the </span> <span>Experience with us!</span></h2>
                                    </div>
                                    <div className="rgLimg">
                                        <img src={regBg} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 colRgRight p-0 align-self-center">
                            <div className="rgRtWrap">
                                <div className="rgRtHd">

                                    <h2>Create Account</h2>
                                    <span>Please enter your account information</span>
                                </div>
                                <div action="#" class="register-form">
                                    <form onSubmit={handleSubmit(onSubmit)} >
                                        <div className="formWrap">
                                            <div className="form-controller">
                                                <label htmlFor="">First Name</label>
                                                <input   {...register('first_name', { required:' First name is required',pattern: /^[A-Za-z]+$/i,minLength:10,minLength:{value:3,message:'Please provide minimum 3 characters'} , maxLength:{value:15,message:'Please provide maximum 15 characters'},pattern:{value:/^[A-Za-z]+$/i,message:'First name Must be letters'} })} type="text" autocomplete="off" name="first_name" placeholder="First Name" />
                                               
                                                {errors?.first_name && <p style={{ color: 'red' }}>{errors?.first_name .message}</p>}
                                            </div>
                                            <div className="form-controller">
                                                <label htmlFor="">Last Name</label>


                                                <input {...register('last_name', { required:' Last name is required',pattern: /^[A-Za-z]+$/i,minLength:10,minLength:{value:3,message:'Please provide minimum 3 characters'} , maxLength:{value:15,message:'Please provide maximum 15 characters'},pattern:{value:/^[A-Za-z]+$/i,message:'Last name Must be letters'} })} type="text" autocomplete="off" name="last_name" placeholder="Last Name" />
                                                {/* {errors?.last_name?.type === "required" && <p  style={{ color: 'red' }} >Lastname is required</p>}
                                                {errors?.last_name?.type === "pattern" && <p  style={{ color: 'red' }}>Must be letters</p>} */}
                                                {errors?.last_name && <p style={{ color: 'red' }}>{errors?.last_name .message}</p>}
                                            </div>
                                            <div className="form-controller">
                                                <label htmlFor="">Email</label>
                                                <input {...register('email', { required: 'E-mail is required' })} type="email" name="email" placeholder="Email" />
                                                {errors?.email && <p style={{ color: 'red' }}>{errors?.email.message}</p>}
                                            </div>
                                            <div className="form-controller">
                                                <label htmlFor="">Password</label>
                                                <input type={InputType} {...register('password', { required: 'Password is required', minLength: { value: 6, message: ' Password must be atleast 6 characters' } })}  name="password" placeholder="Password" />
                                                <span className="passwordToggleIcon">
                                                    {Icon}
                                                </span>
                                                {errors?.password && <p style={{ color: 'red' }}>{errors?.password.message}</p>}
                                            </div>
                                            <div className="form-controller">
                                                <label htmlFor="">Phone</label>
                                                <input hidden {...register('phone_code')} defaultValue='91' type="text" name="phone_code" placeholder="phone" />

                                                <div className='uui'> 
                                                <input {...register('phone', { required: 'Phone is required',pattern:{value:/\d+/ ,message:'Phone must be numbers'} ,maxLength: { value: 10, message: ' Phone number must be 10 digits  ' }, minLength: { value: 10, message: ' Phone number must be 10 digits ' }, })} className='phnin' type="number" autocomplete="off"  name="phone" placeholder="Phone"  control={control}  />
                                                </div>

                                                {errors?.phone && <p style={{ color: 'red' }}>{errors?.phone.message}</p>}
                                                {/* {errors?.phone?.type === "required" && <p  style={{ color: 'red' }}>Phone is required</p>}
                                                {errors?.phone?.type === "pattern" && <p  style={{ color: 'red' }}>Must be letters</p>}
                                                
                                                {errors?.phone?.type === "maxLength" && <p  style={{ color: 'red' }}>Phone number must be 10 digits</p>} */}
                                            
                                             
                                            </div>
                                        </div>
                                        <p class="message pptu">By signing up, you're agree to our <a href="/terms">Privacy Policy & Terms of Use</a></p>
                                        <Link to="/create-account" ></Link>
                                        <button type="submit" class="btn">CREATE ACCOUNT</button>
                                    </form>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={3300}
                hideProgressBar
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default UserInfoView;
