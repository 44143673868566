import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SpBanner from '../images/lamborgini.png';
import regBg from '../images/regImg.png';
import Footer from '../Componants/Footer'
import { VerifyEmailCode } from '../Redux/Features/Userslice';
import { useDispatch } from 'react-redux';
import { ResendOtp, VerifyOTP } from '../Redux/Features/Authslice';
import { message } from 'antd';
function EmailVerification() {
    const [OTP, setOTP] = useState(new Array(6).fill(""));
    const { state } = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    let finalString = OTP.join("");
    const handleChange = (element, index) => {

        if (isNaN(element.value)) return false;
        setOTP([...OTP.map((d, idx) => (idx === index) ? element.value : d)]);


    }
    const inputfocus = (elmnt) => {

        if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
            const next = elmnt.target.tabIndex - 2;
            if (next > -1) {
                elmnt.target.form.elements[next].focus()
            }
        }
        else {
            console.log("next");
            const next = elmnt.target.tabIndex;
            if (next < 6 && !isNaN(elmnt.key)) {

                elmnt.target.form.elements[next].focus()
            }
        }

    }



    const handleVerify = () => {
        console.log('ggggg',finalString);
        if (!finalString) {
            message.info("Please enter verification Code")
         }else{
        dispatch(VerifyOTP({ sessionId: state.data, finalString })).then((res) => {
            console.log(res, 'res after OTP verification');
            if (res.payload.message === "OTP verified") {
                message.success("Code verified successfully")
                navigate('/new-password', { state: { data: state.data } })

            } else {
                message.info(res.payload.response.data.message)
            }
        })
    }
    }

    console.log(state, 'state');
    return (
        <div>
            <div className="bannerSec spBanner" style={{ backgroundImage: `url('${SpBanner}')` }}>
                <div className="WrapperBnr">

                </div>
            </div>
            <div className="accRegistration">
                <div className="container">
                    <div className="row accRow">
                        <div className="col-lg-6 colRgLeft p-0">
                            <div className="rgLeftWrap">
                                <div className="rgLcon h100">
                                    <div className="rgLHd">
                                        <h2><span>Enjoy the </span> <span>Experience with us!</span></h2>
                                    </div>
                                    <div className="rgLimg">
                                        <img src={regBg} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 colRgRight p-0">
                            <div className="rgRtWrap formstructure">
                                <div className="formBody">
                                    <div className="rgRtHd">
                                        <h2>Email Verification</h2>
                                        <span>Enter the verification code that was sent to your email address</span>
                                    </div>
                                    <div action="#" class="register-form">
                                        <div className="product--name epDetail">
                                            <div className="epForm register-form formLikeInline">
                                                <div className="epForm register-form formLikeInline">
                                                    <div className="form-controller">
                                                        <form className="formWrap formWrap--item changeItemWrp">
                                                            {
                                                                OTP.map((data, i) => {
                                                                    return (
                                                                        <>
                                                                            <input
                                                                                className='otp-field'
                                                                                type="text"
                                                                                name="otp"
                                                                                maxLength="1"
                                                                                key={i}
                                                                                value={data}
                                                                                onChange={e => handleChange(e.target, i)}
                                                                                onFocus={e => e.target.select()}
                                                                                onKeyUp={e => inputfocus(e)}
                                                                                tabIndex={i + 1}
                                                                            />
                                                                        </>
                                                                    );
                                                                })
                                                            }
                                                        </form>
                                                    </div>
                                                    {/* <Link to="/success"> */}
                                                    <button onClick={handleVerify} type="submit" class="btn btn-save">VERIFY</button>
                                                    {/* </Link>*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="footertemp">
                                        <p class="fp">Don't receive code? <span className='ss' onClick={() => dispatch(ResendOtp(state?.data)).then((resp) => resp.type === "auth/Resendotp/fulfilled" ? message.success('Verification code sent successfully') : message.warn('failed to sent verification code'))} >Resend</span></p>
                                    </div>
                                <div className="register-form">
                                    <p class="message">Don't have an account? <Link to='/user-info'><a href="#">Register</a></Link> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>

    );

}

export default EmailVerification;
