import React from 'react';
import { Button, Modal} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function ContactConfirmPopup({state,setconfirmPopup,support,subject}) {
const navigate =useNavigate()
// console.log(subject.slice(0,5),'sb in sb');
console.log('pop state is',state);
  //arrow function for handleclick
  const handleClick = () => {
    setconfirmPopup(false);
    subject==='Cancel Ride' ?navigate('/my-rides'):  navigate('/orders')
    
    
    
  }
  return (
    <div>
        <Modal show={support}  className='modalDuration support-modal'>
      
        <Modal.Body>
          <div className="suppPop">
            <div className='suppCon'>
                <span>Thank you for contacting us,we</span>
                <br/>
                <span> will get back to you as soon as possible</span>
            </div>
            <Button onClick={handleClick} className='supportBtn '>
                Done
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ContactConfirmPopup;
