import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from '../../Axios'
import Cookies from 'js-cookie'
import { getToken } from '../../Token'


export const GetAllCards = createAsyncThunk(
    'card/getall',
    async (obj,{rejectWithValue})=>{
          try {
              const res =await axios.get('/payments/cards?offset=0&limit=10',{headers:{Authorization:`Bearer ${Cookies.get('token')}`}})
              return  res.data.data.cards
              
          } catch (error) {
              return(error.message)
          }
    }
)
export const AddNewCard = createAsyncThunk(
    'card/AddCard',
    async (obj,{rejectWithValue})=>{
        // console.log('obj token is', String(obj));
          try {
              const res =await axios.post('/payments/cards',{
                
                    card_token: String(obj)
                  
              },
              {headers:{Authorization:`Bearer ${Cookies.get('token')}`}})
              return  res.data
              
          } catch (error) {
              return rejectWithValue(error.response.data.message)
          }
    }
)

export const MakeCardDefault = createAsyncThunk(
    'card/makeDefault',
    async (obj,{rejectWithValue})=>{
        // console.log('obj token is', String(obj));
          try {
              const res =await axios.post('/payments/card/default',{
                
                card_id: obj
                  
              },
              {headers:{Authorization:`Bearer ${Cookies.get('token')}`}})
              return  res.data
              
          } catch (error) {
              return(error.message)
          }
    }
)
export const DeleteUserCard = createAsyncThunk(
    'card/deleteCard',
    async (obj,{rejectWithValue})=>{
        // console.log('obj token is', String(obj));
          try {
              const res =await axios.post('payments/card/delete',{
                
                card_id: obj
                  
              },
              {headers:{Authorization:`Bearer ${Cookies.get('token')}`}})
              return  res.data
              
          } catch (error) {
              return(error.message)
          }
    }
)

const initialState = {
    loading:false,
    error:'',
    cards:[]
}

const Cardslice = createSlice({
  name: 'card',
  initialState,
  reducers: {},
  extraReducers:{
      [GetAllCards.pending]:(state)=>{
         state.loading=true
      },
      [GetAllCards.fulfilled]:(state,{payload})=>{
         state.cards=payload
         state.loading=false
      },
      [GetAllCards.rejected]:(state,{payload})=>{
         state.loading=payload
         state.loading=false
      },
      [AddNewCard.pending]:(state)=>{
         state.loading=true
      },
      [AddNewCard.fulfilled]:(state,{payload})=>{
        state.loading=false
      },
      [AddNewCard.rejected]:(state,{payload})=>{
         state.loading=false
         state.error=payload
      },

  }
});

// export const {} = Cardslice.actions

export default Cardslice.reducer