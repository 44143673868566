import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye,faEyeSlash} from '@fortawesome/fontawesome-free-solid'
import { library } from '@fortawesome/fontawesome-svg-core'
library.add(faEye,faEyeSlash);
function PasswordToggle() {
 const [visible,setVisible] = useState(false);
 const Icon = <FontAwesomeIcon icon={visible? "eye" : "eye-slash" } 
 onClick ={()=>setVisible(!visible)}/>
 const InputType = visible ? "text" : "password";
 return[InputType,Icon];
}
export default PasswordToggle; 