import React from 'react'
import {useNavigate} from 'react-router-dom'
function EmailEdit({user}) {
  const navigate =useNavigate()
  return (
    <div>
        <div className="EditWrp">
            <div className="EdtIn">
                <h2>Your Email address</h2>
                <span>{user?.user?.email}</span>
            </div>
        </div>
        
            <button onClick={()=>navigate('/email-change',{state:{email:user?.user?.email}})} className='editBtn'>CHANGE EMAIL</button>
        
    </div>
  );
}

export default EmailEdit;