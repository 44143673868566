import React, { useState } from 'react'
import NavBar from '../Componants/NavBar';
import SpBanner from '../images/lamborgini.png';
import Footer from '../Componants/Footer';
import Profile from '../images/prof.jpeg'
import Shopbanner from '../images/shopBanner.png'
import LeftArrow from '../images/left-arrow.svg'
import { useForm } from 'react-hook-form'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { UpdateUserDetails } from '../Redux/Features/Userslice';
import { message } from 'antd';
import { uploadImagesToS3 } from '../AWS S3/Aws'
function EditProfile() {
    const { register, handleSubmit, control, formState: { errors } } = useForm()
    const { state } = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [image, setimage] = useState()
    console.log(state, 'state in the edit');

    const onSubmit = async (data) => {
        console.log(image);
        console.log(data);
        if (image) {
            await uploadImagesToS3(image).then((res) => {
                console.log(res, 'res after awsssssss');
                res.location && dispatch(UpdateUserDetails({ first_name: data?.first_name, last_name: data?.last_name, image: res.location })).then((res) => {
                    console.log(res, 'res in the edit');
                    res?.payload?.message === "Updated" &&
                        message.info('Profile Updated Successfully')
                    navigate('/profile-view')
                })
            })
        } else {
            dispatch(UpdateUserDetails(data)).then((res) => {
                console.log(res, 'res in the edit');
                res?.payload?.message === "Updated" &&
                    message.info('Profile Updated Successfully')
                navigate('/profile-view')
            })
        }









    }

    return (
        <div>
            <NavBar />
            <div className="bannerSec spBanner shopBnr low-hieght" style={{ backgroundImage: `url('${Shopbanner}')` }}>
                <div className="WrapperBnr">
                </div>
            </div>
            <div className="editProfile pb60">
                <div className="container">
                    <div className="row epRow">
                        <div className="epIn col-lg-4">
                            <div className="epHd">
                                <h2 className='epHd'>
                                    <span >
                                        <Link to="/profile-view" > <img src={LeftArrow} alt="" /> </Link>
                                    </span>
                                    Edit Profile
                                </h2>
                            </div>
                            <div className="product--name epDetail">
                                <div className="ride--info ep--info">
                                    <div className="ride--info--thumb">
                                        <div className="ride--info--thumb__img">
                                            <img src={state?.user?.image ? state?.user?.image : `https://joeschmoe.io/api/v1/${state.user?.full_name}`} alt="Profile" />
                                        </div>
                                    </div>
                                    <div className="ride--info--detail">
                                        <h3>{state.user?.full_name}</h3>
                                        <input type="file" name="file-input" onChange={(e) => setimage(e.target.files[0])} id="file-input" class="file-input__input" />
                                        <label class="file-input__label" for="file-input">
                                           <span style={{cursor:'pointer'}} >Change Photo</span>
                                        </label>
                                        {/* <input type="file" onChange={(e) => setimage(e.target.files[0])} accept="image/apng, image/avif, image/gif, image/jpeg, image/png, image/svg+xml, image/webp"></input> */}
                                        {/* <span>Change Photo</span> */}

                                    </div>

                                    {/* <input type="file" name="file-input" id="file-input" class="file-input__input" />
                                    <label class="file-input__label" for="file-input">
                                        GHFHFF
                                    </label> */}
                                </div>
                                <div className="epForm register-form">
                                    <form onSubmit={handleSubmit(onSubmit)} >
                                        <div className="form-controller">
                                            <label htmlFor="">First Name</label>
                                            {/* <input type="text" autocomplete="off" name="first_name" defaultValue={state.user.first_name} /> */}
                                            <input defaultValue={state.user.first_name}   {...register('first_name', { required: ' First name is required', pattern: /^[A-Za-z]+$/i, minLength: { value: 3, message: 'Please provide minimum 3 alphabets' }, maxLength: { value: 15, message: ' First name should be less than 15 characters' }, pattern: { value: /^[A-Za-z]+$/i, message: 'Firstname Must be alphabets' } })} type="text" autocomplete="off" name="first_name" placeholder="First Name" />

                                            {errors?.first_name && <p style={{ color: 'red' }}>{errors?.first_name.message}</p>}
                                        </div>
                                        <div className="form-controller">
                                            <label htmlFor="">Last Name</label>
                                            {/* <input type="text" autocomplete="off" name="first_name" defaultValue={state.user.last_name} /> */}

                                            <input defaultValue={state.user.last_name} {...register('last_name', { required: ' Last name is required', pattern: /^[A-Za-z]+$/i,  minLength: { value: 3, message: 'Please provide minimum 3 alphabets' }, maxLength: { value: 15, message: ' Last name should be less than 15 characters' }, pattern: { value: /^[A-Za-z]+$/i, message: 'Lastname Must be alphabets' } })} type="text" autocomplete="off" name="last_name" placeholder="Last Name" />

                                            {errors?.last_name && <p style={{ color: 'red' }}>{errors?.last_name.message}</p>}

                                        </div>
                                        <button type="submit" class="btn btn-save">Save</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer noTopSpace />
        </div>
    );
}

export default EditProfile;