import React, { useEffect, useState } from 'react';
import SpBanner from '../images/lamborgini.png'
import tshirt from '../images/tshirt.png'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import SupportPopUp from './SupportPopUp';
import watch from '../images/watch.png'
import delIcon from '../images/delIcon.png'
import ReportIssue from './ReportIssue';
import Skeleton from 'react-loading-skeleton';

import { GetOrdersById } from '../Redux/Features/Orderslice';
import { ImLocation } from "react-icons/im";
import moment from 'moment';
function OrderDetailView() {

    const {id}=useParams()
    const dispatch =useDispatch()
    const [support, setSupport] = useState(false);
    const handleSupportClose = () => setSupport(false);
    const handleSupportShow = () => setSupport(true);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const {SingleOrder} =useSelector(state=>state.Orders)
    console.log(SingleOrder?.id,'SingleOrder in order detail');
    useEffect(() => {
      dispatch(GetOrdersById(id))
    }, [])

  return (
    <div>
        {
                show &&
                <ReportIssue category='shop' orderId={SingleOrder?.id} handleClose={handleClose} handleShow={handleShow} show={show} />
            }
        <div className="bannerSec spBanner" style={{ backgroundImage: `url('${SpBanner}')` }}>
            <div className="WrapperBnr">
                <div className="container bnrCon h100 p-0">
                    <div className="orderSRow">
                        <div className="odHd">
                            <h1>Order Details</h1>
                            <div onClick={handleShow} className="report">
                                    <span><i class="fas fa-flag"></i></span>
                                    <span>Report</span>
                                </div>
                        </div>
         {/* {SingleOrder?.order_item?.length }                */}
{SingleOrder?.order_item?.length === 1 ?
                        <div class="row cbRow orderSingleRow">
                            <div class="col-lg-5">
                                <div class="cbLeft h100">
                                    <div class="cbLpro h100">{SingleOrder?.order_item &&<img src={SingleOrder?.order_item[0]?.product?.product_image[0].image} alt="" />}</div>
                                </div>
                            </div>
                            <div class="col-lg-7">
                                <div class="cbRight">
                                    <div class="orderDetailRt">
                                        <div className="orderDate">
                                            <span className='ordId'>ORDER # {SingleOrder?.code}</span>
                                            <span className='ordDt'>{moment.utc(SingleOrder?.transaction?.created_at).local().format('MMMM DD, h:mm a ')}</span>
                                        </div>
                                        <div className="orderedProduct">
                                        {SingleOrder?.order_item && <h2>
                                             <span>{SingleOrder?.order_item[0]?.product?.brand}</span>
                                             {SingleOrder?.order_item[0]?.product?.brand} {SingleOrder?.order_item[0]?.product?.title}
                                            </h2>}
                                            <h5>Quantity: {SingleOrder?.total_qty}</h5>
                                        </div>
                                        <div className="orderInfo">
                                                <div className="oiLeft">
                                                    <span><img style={{marginRight:'8px'}} src={delIcon} alt="" />Your order is on the way</span>
                                                    <a target='blank' href={SingleOrder?.order_item&&SingleOrder?.order_item[0]?.public_url} >    <button className='oiBtn'>TRACK -KH0213525</button></a>
                                                </div>
                                                <div className="oiRt"></div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
:
SingleOrder?.order_item?.map((items)=>(
    <>
     <div class="row cbRow orderSingleRow">
                            <div class="col-lg-5">
                                <div class="cbLeft h100">
                                    <div class="cbLpro h100"><img width='100px' src={items.product?.product_image[0].image} alt="" /></div>
                                </div>
                            </div>
                            <div class="col-lg-7">
                                <div class="cbRight">
                                    <div class="orderDetailRt">
                                        <div className="orderDate">
                                            <span className='ordId'>ORDER # {SingleOrder?.code}</span>
                                            <span className='ordDt'>{moment.utc(SingleOrder?.transaction?.created_at).local().format('MMMM DD, h:mm a ')}</span>
                                        </div>
                                        <div className="orderedProduct">
                                        {SingleOrder?.order_item && <h2>
                                             <span>{items?.product?.brand}</span>
                                             {items?.product?.brand} {items?.product?.title}
                                            </h2>}
                                            <h5>Quantity: {items?.qty}</h5>
                                        </div>
                                        <div className="orderInfo">
                                                <div className="oiLeft">
                                                    <span><img style={{marginRight:'8px'}} src={delIcon} alt="" />Your order is on the way</span>
                                                    <a target='blank' href={SingleOrder?.order_item&&SingleOrder?.order_item[0]?.public_url} >    <button className='oiBtn'>TRACK -KH0213525</button></a>
                                                </div>
                                                <div className="oiRt"></div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
    </>
))}



                    </div>
                </div>
            </div>
        </div>
        <div className="orderPayment">
            <div className="container">
                <div className="row opRow">
                    <div className="col-lg-6 colOpLt">
                        <div className="item-deliver-small">
                        <div className="rideInfo">
                                    <span style={{color:'#F75A33'}}>Order Placed On {moment.utc(SingleOrder?.transaction?.updated_at).local().format('MMMM DD ')}  </span>
                                </div>
                            <div className="item-deliver--edit">
                                <span>Deliver To</span>
                                
                            </div>
                            <div className="item-deliver--location">
                                <div className="item-deliver--location--icon">
                                <ImLocation color='#F87756' size={20}/>
                                </div>
                                <div className="item-deliver--location--locname">
                                    <h2>
                                        <span>{SingleOrder?.shipping_address?.address}</span>
                                         {SingleOrder?.shipping_address?.city},{SingleOrder?.shipping_address?.zip}
                                    </h2>
                                </div>
                            </div>
                            <div className="item-deliver--cancel">
                             {SingleOrder?.order_item && SingleOrder?.order_item[0]?.status=== "Cancel Requested" ? <button disabled className='cancelBtn'>{SingleOrder?.order_item[0]?.status}</button> : <button onClick={handleSupportShow}className='cancelBtn'>CANCEL ORDER</button>}   
                                <a className='undrLne' href="#">Cancellation Policy</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 colOpRt">
                    <div className="calcRow">
                        <div className="calcPart">
                        <div className="orderDate">
                        <div style={{display:'flex'}}>
                                            <h6 style={{color:'#F6491E'}} className='ordId'>ORDER </h6>&nbsp;<h6 style={{fontSize:'larger',fontWeight:'600',color:'#F6491E'}}># {SingleOrder?.code}</h6>
                                                </div>
                                            <span className='ordDt'>{moment.utc(SingleOrder?.transaction?.created_at).local().format('MMMM DD, h:mm a ')}</span>
                                        </div>
                            <div class="orderSum">
                                <h2>Order Summary</h2>
                                <div class="orders">
                                            <div class="orderin">
                                                <div class="ordItemName"><span>Total Item Price</span></div>
                                                <div class="itemPrice actualPrice"><span>{SingleOrder?.transaction?.item_amount ?`$ ${SingleOrder?.transaction?.item_amount}` :<Skeleton height={20} width={60}/>}</span></div>
                                            </div>
                                            <div class="orderin">
                                                <div class="ordItemName"><span>Shipping Price</span></div>
                                                <div class="itemPrice"><span>{SingleOrder?.transaction?.delivery_fee?`$ ${SingleOrder?.transaction?.delivery_fee}` :<Skeleton height={20}  width={60}/>} </span></div>
                                            </div>
                                            <div class="orderin">
                                                <div class="ordItemName"><span>Service Charges</span></div>
                                                <div class="itemPrice"><span>{SingleOrder?.transaction?.service_charge ?`$ ${SingleOrder?.transaction?.service_charge}`:<Skeleton height={20}  width={60}/>}</span></div>
                                            </div>
                                            {SingleOrder?.transaction?.discount &&
                                                <div class="orderin">
                                                    <div class="ordItemName"><span>Promocode Discount</span></div>
                                                    <div class="itemPrice"><span>{ SingleOrder?.transaction?.discount ?`- $ ${SingleOrder?.transaction?.discount}` :<Skeleton height={20}  width={60}/>}</span></div>
                                                </div>
                                            }
                                            <div class="orderin totalPrice">
                                                <div class="ordItemName"><span>TOTAL</span></div>
                                                <div class="itemPrice"><span>{SingleOrder?.transaction?.total_amount? `$ ${SingleOrder?.transaction?.total_amount}`:<Skeleton height={20}  width={60}/>}</span></div>
                                            </div>
                                        </div>
                                <div className="paidCred">
                                    <div class="paidviaCred">
                                        <div className="pcredwrap">                                            
                                            <div className="refundMsg">
                                                <div className='refifpaid'> 
                                                    <span><i class="fa fa-check"></i></span> Paid via credit card ending {SingleOrder?.transaction?.card_last4}
                                                </div>
                                            </div>  
                                        </div>                                                                           
                                    </div>                                    
                                </div>                          
                            </div>
                        </div>
                    </div>
                    
                    </div>
                </div>
            </div>
        </div>
        {
                support && <SupportPopUp state={'order cancelation'} id={id} subjectt={'cancel order'} orderId={SingleOrder?.code}  handleSupportClose={handleSupportClose} support={support} />
            }
    </div>
  );
}

export default OrderDetailView;
