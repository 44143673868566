import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from '../../Axios'
import Cookies from 'js-cookie';
export const GetAllProducts = createAsyncThunk('product/getAll', async (obj, { rejectWithValue }) => {
    // console.log(obj, 'items to api get all product');


    try {
        if (obj.price === 'high') {
            const res = await axios.get(`/product?offset=${obj.data ? obj.data * 8 : 0 * 8}&where={"stock":{"$gte":${obj.showOutStock?1:0}}}&limit=8&populate=%5B%22product_image%22%5D&sort=[["unit_price","desc"],["product_image","is_thumbnail","desc"] ] `, {
                headers: { Authorization: `Bearer ${Cookies.get('token')}` }
            })
            return res.data.data

        }
        if (obj.price === 'low') {
            const res = await axios.get(`/product?offset=${obj.data ? obj.data * 8 : 0 * 8}&where={"stock":{"$gte":${obj.showOutStock?1:0}}}&limit=8&populate=%5B%22product_image%22%5D&sort=[["unit_price","asc"],["product_image","is_thumbnail","desc"]] `, {
                headers: { Authorization: `Bearer ${Cookies.get('token')}` }
            })
            return res.data.data

        }
        if (obj.price === 'new') {
            const res = await axios.get(`/product?offset=${obj.data ? obj.data * 8 : 0 * 8}&where={"stock":{"$gte":${obj.showOutStock?1:0}}}&limit=8&populate=%5B%22product_image%22%5D&sort=[["created_at","desc"  ],["product_image","is_thumbnail","desc"]] `, {
                headers: { Authorization: `Bearer ${Cookies.get('token')}` }
            })
            return res.data.data

        }
        if (obj.price === 'old') {
            const res = await axios.get(`/product?offset=${obj.data ? obj.data * 8 : 0 * 8}&where={"stock":{"$gte":${obj.showOutStock?1:0}}}&limit=8&populate=%5B%22product_image%22%5D&sort=[["created_at","asc"],["product_image","is_thumbnail","desc"]] `, {
                headers: { Authorization: `Bearer ${Cookies.get('token')}` }
            })
            return res.data.data

        }

        const res = await axios.get(`/product?offset=${obj ? obj * 8 : 0 * 8}&where={"stock":{"$gte":${obj.showOutStock?1:0}}}&limit=8&populate=%5B%22product_image%22%5D&sort=[["unit_price","desc"],["product_image","is_thumbnail","desc"]]`, {
            headers: { Authorization: `Bearer ${Cookies.get('token')}` }
        })
        return res.data.data





    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const GetSingleProduct = createAsyncThunk('product/getsingle', async (obj, { rejectWithValue }) => {
    // console.log(obj, 'product to api');
    try {
        const res = await axios.get(`/product/${obj}?populate=%5B%22product_image%22%2C%22review%22%2C%20%22review.user%22%5D&sort=%5B%5B%22review%22%2C%22created_at%22%2C%22desc%22%5D%2C%5B%22product_image%22%2C%22is_thumbnail%22%2C%22desc%22%5D%5D`, {
            headers: { Authorization: `Bearer ${Cookies.get('token')}` }
        })
        return res.data.data
        // console.log(res.status,'return single item');
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const AddtoCart = createAsyncThunk('product/AddtoCart', async (obj, { rejectWithValue }) => {
    // console.log(obj, 'product to Cart');
    try {
        const res = await axios.post(`/cart`, {
            product_id: obj,
            quantity: 1
        },
            {
                headers: { Authorization: `Bearer ${Cookies.get('token')}` }
            })
        return res.data.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const GetCart = createAsyncThunk('product/GetCart', async (obj, { rejectWithValue }) => {
    // console.log(obj, 'product to Cart');
    try {
        const res = await axios.get(`/cart?offset=0&limit=10`,
            {
                headers: { Authorization: `Bearer ${Cookies.get('token')}` }
            })
        return res.data.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const GetCartCount = createAsyncThunk('product/GetCartCount', async (obj, { rejectWithValue }) => {
    // console.log(obj, 'product to Cart');
    try {
        const res = await axios.get(`/cart/count`,
            {
                headers: { Authorization: `Bearer ${Cookies.get('token')}` }
            })
        return res.data.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const RemoveCartById = createAsyncThunk('product/RemoveCartById', async (obj, { rejectWithValue }) => {
    // console.log(obj, 'delete Cart id');
    try {
        const res = await axios.delete(`/cart/${obj}`,
            {
                headers: { Authorization: `Bearer ${Cookies.get('token')}` }
            })
        return res.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const UpdateCartById = createAsyncThunk('product/UpdateCartById', async (obj, { rejectWithValue }) => {
    // console.log(obj, 'update Cart id');
    const { count, id, active, cartId } = obj
    try {
        const res = await axios.put(`/cart/${cartId}`,
            {
                active: true,
                product_id: id,
                quantity: count
            },
            {
                headers: { Authorization: `Bearer ${Cookies.get('token')}` }
            })
        return res.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const SingleCartById = createAsyncThunk('product/UpdateCartById', async (obj, { rejectWithValue }) => {
    // console.log(obj, 'update Cart id');


    try {
        const res = await axios.get(`/cart/${obj}`,

            {
                headers: { Authorization: `Bearer ${Cookies.get('token')}` }
            })
        return res.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const ConfirmOrder = createAsyncThunk('product/ConfirmOrder', async (obj, { rejectWithValue }) => {

    // console.log(obj,'in my doubt');
    try {
        const res = await axios.post(`/product/confirm_order`,
            {

                "product":
                    obj?.length > 0 && obj.map((item) => {
                        return (
                            {
                                "id": item.product_id,
                                "qty": item.quantity
                            }
                        )
                    })



            },
            {
                headers: { Authorization: `Bearer ${Cookies.get('token')}` }
            })
        return res.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const ApplyProductCoupencode = createAsyncThunk(
    'data/Applycoupen',
    async (obj, { rejectWithValue }) => {


        try {
            const res = await axios.post(`/coupon_code/apply`, {


                code: obj.code,
                total_cost: obj.price.toString(),
                category: "shop"


            },
                { headers: { Authorization: `Bearer ${Cookies.get('token')}` } })


            return res.data


        } catch (error) {
            return rejectWithValue(error.response.data)

        }

    }
)
export const MakeProductPayment = createAsyncThunk(
    'data/MakeProductPayment',
    async (obj, { rejectWithValue }) => {
        // console.log(obj, 'obj inthe makepaymentdddddddddddddd');

        try {
            const res = await axios.post(`/user_payment/pay_order`,
                {
                    "coupon_id": obj?.coupon?.id,
                    "item_amount": parseFloat(obj?.price?.product_total),
                    "delivery_fee": obj?.price?.shipment_rate,
                    "service_charge": obj?.price?.service_charge,
                    "discount": parseFloat(obj?.coupon?.offer),
                    "total_amount": obj?.coupon?.total_cost ? parseFloat(obj?.coupon?.total_cost) : obj?.price?.total,
                    "cart_id": obj.cartIds,
                    "product": [
                        {
                            "id": obj?.product?.id,
                            "qty": obj?.qnty,
                            "amount": parseFloat(obj?.product?.unit_price)
                        }

                    ],
                    "source_token": obj?.card_id,
                    "easy_post_id": obj?.shipping?.easy_post_id,
                    "shipping_address_id": obj?.shipping_address_id,
                    "carrier": obj?.shipping?.carrier,
                    "service": obj?.shipping?.service
                },
                { headers: { Authorization: `Bearer ${Cookies.get('token')}` } })


            return res.data


        } catch (error) {
            return rejectWithValue(error.response.data)

        }

    }
)
export const CartMakeProductPayment = createAsyncThunk(
    'data/CartMakeProductPayment',
    async (obj, { rejectWithValue }) => {
        // console.log(obj, 'obj inthe makepaymentffffffffffffffffffff');

        try {
            const res = await axios.post(`/user_payment/pay_order`,
                {
                    "coupon_id": obj?.coupon?.id,
                    "item_amount":parseFloat(obj?.item?.price?.product_total) ,
                    "delivery_fee": obj?.item?.price?.shipment_rate,
                    "service_charge":parseFloat(obj?.item?.price?.service_charge) ,
                    "discount": parseFloat(obj?.coupon?.offer),
                    "total_amount": obj?.coupon?.total_cost ? parseFloat(obj?.coupon?.total_cost) : obj?.item?.price?.total,
                    "cart_id": obj.cartIds,
                    // "product": [
                    //   {
                    //     "id": obj?.product?.id,
                    //     "qty": obj?.qnty,
                    //     "amount":parseFloat(obj?.product?.unit_price) 
                    //   }

                    // ],
                    "product": obj.item.product.map((i) => (
                        {
                            "id": i?.product_id,
                            "qty": i?.quantity,
                            "amount": parseFloat(i?.unit_price)
                        }
                    )),
                    "source_token": obj?.item?.card_id,
                    "easy_post_id": obj?.item?.shipping?.easy_post_id,
                    "shipping_address_id": obj?.item?.shipping_address_id,
                    "carrier": obj?.item?.shipping?.carrier,
                    "service": obj?.item?.shipping?.service
                },
                { headers: { Authorization: `Bearer ${Cookies.get('token')}` } })


            return res.data


        } catch (error) {
            return rejectWithValue(error.response.data)

        }

    }
)



const initialState = {
    loading: false,
    orderPriceloading: false,
    Products: {},
    SingleProduct: {},
    CartItems: {},
    CartCount: {},
    error: {},
    orderPrice: {},
    ammountError: '',
    ProductCoupenError: '',
    CoponOffer: {},
    ShipmentDetails: {},
    ProductpaymentLoading: false
}

const Shopslice = createSlice({
    name: "second",
    initialState,
    reducers: {},
    extraReducers: {
        [GetAllProducts.pending]: (state, action) => {
            state.loading = true
        },
        [GetAllProducts.fulfilled]: (state, action) => {
            state.loading = false
            state.Products = action.payload
        },
        [GetAllProducts.rejected]: (state, action) => {
            state.loading = false
            state.error = action.payload
        },
        [GetSingleProduct.pending]: (state, action) => {
            state.loading = true
            state.SingleProduct = {}
        },
        [GetSingleProduct.fulfilled]: (state, action) => {
            state.loading = false
            state.SingleProduct = action.payload
        },
        [GetSingleProduct.rejected]: (state, action) => {
            state.loading = false
            state.error = action.payload
        },
        [GetCart.pending]: (state, action) => {
            state.loading = true
        },
        [GetCart.fulfilled]: (state, action) => {
            state.loading = false
            state.CartItems = action.payload
        },
        [GetCart.rejected]: (state, action) => {
            state.loading = false
            state.error = action.payload
        },
        [GetCartCount.pending]: (state, action) => {
            state.loading = true
        },
        [GetCartCount.fulfilled]: (state, action) => {
            state.loading = false
            state.CartCount = action.payload
        },
        [GetCartCount.rejected]: (state, action) => {
            state.loading = false
            state.error = action.payload
        },
        [ConfirmOrder.pending]: (state, action) => {
            state.orderPriceloading = true
            state.ammountError = ''
            state.orderPrice = {}
        },
        [ConfirmOrder.fulfilled]: (state, action) => {
            state.orderPriceloading = false
            // console.log(action.payload.data, 'action.payload');
            state.orderPrice = action.payload.data.rate
            state.ShipmentDetails = action.payload.data.shipment
        },
        [ConfirmOrder.rejected]: (state, action) => {
            state.orderPriceloading = false
            // console.log(action.payload.message, 'for me');
            state.ammountError = action.payload.message
        },
        [ApplyProductCoupencode.pending]: (state, action) => {
            state.ProductCoupenError = ''
            state.CoponOffer = {}

        },
        [ApplyProductCoupencode.fulfilled]: (state, action) => {

            // console.log(action.payload.data, 'action.payload');
            state.CoponOffer = action.payload.data
        },
        [ApplyProductCoupencode.rejected]: (state, action) => {

            state.ProductCoupenError = action.payload.message

        },
        [MakeProductPayment.pending]: (state, action) => {

            state.ProductpaymentLoading = true

        },
        [MakeProductPayment.fulfilled]: (state, action) => {
            state.ProductpaymentLoading = false


        },
        [MakeProductPayment.rejected]: (state, action) => {

            state.ProductpaymentLoading = false

        },
    }
});



export default Shopslice.reducer