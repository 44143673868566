import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import Skeleton from 'react-loading-skeleton'
import ScaleLoader from "react-spinners/ScaleLoader";
import { GetAllProducts } from '../Redux/Features/Shopslice';
// 
import 'bootstrap/dist/css/bootstrap.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover'
import Button from 'react-bootstrap/Button';
import { Dropdown } from 'react-bootstrap';
import { message } from 'antd';
import Cookies from 'js-cookie';
function ShopView() {
    const dispatch = useDispatch()
    const [data, setpage] = useState(0)
    const [price, setprice] = useState('new')
    const [showOutStock, setshowOutStock] = useState(false)
    const { id } = useParams()
    
    const [productCount, setproductCount] = useState(1)
    const { Products,loading } = useSelector(state => state.Shop)
    const navigate = useNavigate()
    const handlePageClick = async (data) => {
       
        navigate(`/shop/${data + 1}`)
        setpage(data)
        dispatch(GetAllProducts({ price, data }))

    }
    const handleFilter =(e,item)=>{
        e.preventDefault()
        dispatch(GetAllProducts({ price, data: id - 1,showOutStock })).then((res) => {
            setproductCount(res.payload.count, 'shop');
        })
        setpage(id - 1)
        
    }
    

    useEffect(() => {
        console.log(data, 'data');
        dispatch(GetAllProducts({ price, data: id - 1,showOutStock })).then((res) => {
            // setproductCount(res.payload.count, 'shop');
            // console.log(res.payload,'res after block');
            if (res?.payload?.statusCode===401) {
                message.error('Account is blocked by admin,please contact revup support team')
                Cookies.remove('token')
                navigate('/login')
                
                
            } else {
                setproductCount(res.payload.count, 'shop');  
            }
        })
        setpage(id - 1)
    }, [dispatch,price,showOutStock ])
    
    return (
        <div className='shopWrapper'>
            <div className="container">
                {loading ? 
                <div className="shopRow">
                <div className="shopHd">
                    <div className="shopHdLeft">
                        </div>
                    

                        </div>
                        <div style={{marginTop:'5%',marginLeft:'42%',marginBottom:'5%'}} >
                        <ScaleLoader color='#000' loading={loading}  size={200} />

                        </div>
                        </div>
                 :
                
<div className="shopRow">
                    <div className="shopHd">
                        <div className="shopHdLeft">
                            <h2>All Products</h2>
                        </div>
                        <div className="shopHdRt">
                            <div className="shopRtItem">
                                <span> <i class="fad fa-sort-alt"></i> Filter By</span>
                                <div class="customs_selects">
                                    <Dropdown>
                                        <Dropdown.Toggle id="dropdown-basic" className='price-choose'>
                                            Price
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className='dropdown_filter'>
                                            <div className="price drop-item">
                                                <span>Price</span>
                                                <form action="#" className='filterfrm'>
                                                    <p>
                                                        <input checked={price==='low'} onClick={()=>setprice('low')} type="radio" id="test1" name="radio-group" />
                                                        <label for="test1">Low to High</label>
                                                    </p>
                                                    <p>
                                                        <input checked={price==='high'} onClick={()=>setprice('high')} type="radio" id="test2" name="radio-group" />
                                                        <label for="test2">High to Low</label>
                                                    </p>
                                                </form>
                                            </div>

                                            <div className="availability drop-item">
                                                <span>Availability</span>
                                                <form className='filterfrm'>
                                                    <div class="form-group frmgaping">
                                                        {showOutStock}
                                                        <input onChange={()=>setshowOutStock(!showOutStock)} checked={showOutStock} type="checkbox" id="html" />
                                                        <label for="html" className='stockstatus'>Exclude out of stock</label>
                                                    </div>
                                                    {/* <button type='submit' >submit</button> */}
                                                </form>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                
                            </div>
                            <div className="shopRtItem">
                                <span> <i class="fad fa-shopping-bag"></i> Product</span>
                                <div class="customs_selects">
                                    <Dropdown >
                                        <Dropdown.Toggle id="dropdown-basic" className='price-choose'>
                                            All
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className='dropdown_filter'>

                                            <div className="display drop-item">
                                                <span>Display</span>
                                                <form onSubmit={(e)=>handleFilter(e)} action="#" className='filterfrm'>
                                                    <p className='frmgaping'>
                                                        <input checked={price==='new'} onClick={()=>setprice('new')} type="radio" id="test3" name="radio-group" />
                                                        <label for="test3">Newly added first</label>
                                                    </p>
                                                    <p className='frmgaping'>
                                                        <input checked={price==='old'} onClick={()=>setprice('old')} type="radio" id="test4" name="radio-group" />
                                                        <label for="test4">Newly added last</label>
                                                    </p>
                                                    {/* <button type='close'>submit</button> */}
                                                </form>
                                            </div>
                                            <div className="availability drop-item">
                                                <span>Availability</span>
                                                <form className='filterfrm'>
                                                    <div class="form-group frmgaping">
                                                        {showOutStock}
                                                        <input onChange={()=>setshowOutStock(!showOutStock)} checked={showOutStock} type="checkbox" id="html" />
                                                        <label for="html" className='stockstatus'>Exclude out of stock</label>
                                                    </div>
                                                    {/* <button type='submit' >submit</button> */}
                                                </form>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    {/* <select onChange={(e)=>setprice(e.target.value)} name="product" class="customs_sel_box">
                                        
                                        <option value="new">newly added first</option>
                                        <option value="old">newly added last</option>
                                        
                                    </select> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="shopList row">

                        {Products?.products ? Products?.products?.map((item) => (
                            <>
                                <div className="col-lg-3 col-sm-6 col-12 p-0">

                                    <div onClick={() => navigate(`/shop-detail/${item.id}`)} className="productItem linkTo">
                                        <div className="prodThumb">
                                            <img src={item.product_image[0].image} alt="" />
                                        </div>
                                        <div className="prodDetail">
                                            <div class="review">
                                                <span><i class="fa fa-star" aria-hidden="true"></i> {item?.rating_avg}</span>
                                            </div>
                                            <h2 className='prodName'>{item.title.length >15 ? `${item.title.substr(0,15)} ...`:item.title}<br/> {item.brand.length>15 ? `${item.brand.substr(0,15)} ...`:item.brand}</h2>
                                            <span className='prodPrice'>${item.unit_price}</span>
                                        </div>
                                    </div>

                                </div>
                            </>


                        )) :

                            <>

                                {
                                    [...Array(8)].map((_, i) => (
                                        <>
                                            <div className="col-lg-3 col-sm-6 col-12 p-0">

                                                <div className="productItem linkTo">
                                                    <div className="prodThumb">
                                                        <Skeleton height={100} width={100} />
                                                    </div>
                                                    <div className="prodDetail">
                                                        <div class="review">
                                                            <span><Skeleton /></span>
                                                        </div>
                                                        <h2 className='prodName'><Skeleton /> <Skeleton /> </h2>
                                                        <span className='prodPrice'><Skeleton /> </span>
                                                    </div>
                                                </div>

                                            </div>
                                        </>

                                    ))
                                }

                            </>




                        }


                    </div>
                    <div className="shopPagination">
                        <div className="paginationRow">
                            <div className="page">
                                {[...Array(Math.ceil(productCount / 8))].map((_, i) => (
                                    <>
                                        <span onClick={() => handlePageClick(i)} className={i === data ? "page-item active" : 'page-item'}>
                                            {i + 1}
                                        </span>
                                    </>
                                ))}

                            </div>
                        </div>
                        
                    </div>
                </div>


                }
                
            </div>
        </div>
    );
}

export default ShopView;
