import React, { useState } from 'react';
import NavBar from '../Componants/NavBar';
import SpBanner from '../images/lamborgini.png';
import Footer from '../Componants/Footer';
import LeftArrow from '../images/left-arrow.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { message } from 'antd';
import { VerifyEmailCode } from '../Redux/Features/Userslice';
import { ResendOtp } from '../Redux/Features/Authslice';
function VerifyEmail() {
    const [OTP, setOTP] = useState(new Array(6).fill(""));
    const { state } = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleChange = (element, index) => {

        if (isNaN(element.value)) return false;
        setOTP([...OTP.map((d, idx) => (idx === index) ? element.value : d)]);


    }
    const inputfocus = (elmnt) => {

        if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
            const next = elmnt.target.tabIndex - 2;
            if (next > -1) {
                elmnt.target.form.elements[next].focus()
            }
        }
        else {
            console.log("next");
            const next = elmnt.target.tabIndex;
            if (next < 6 && !isNaN(elmnt.key)) {

                elmnt.target.form.elements[next].focus()
            }
        }

    }
    let finalString = OTP.join("");


    //   function for OTP verification
    const handleVerifyOtp = () => {
        //   && 
console.log('ggggg', finalString);
         if (finalString ==='') {
            message.info("Please enter verification code")
         }else{
            dispatch(VerifyEmailCode({ sessionId: state.data, finalString })).then((res) => {
                console.log(res);
    
       if (res.type==="user/verifyEmailCode/fulfilled") {
           message.success(res.payload.message);
           navigate('/success',{state:{clear:true,case:''}})
       } else {
           message.info(res.payload.message)
       }
    
                // console.log(res?.payload?.message, 'res after OTP verification');
                // message.info(res?.payload?.response?.data?.message || res?.payload?.message)
                // res?.payload?.message === "OTP verified" && navigate('/success')
    
    
            })
         }
        

    }
    console.log(state,'in verify email');
    return (
        <div>
            <NavBar />
            <div className="bannerSec spBanner" style={{ backgroundImage: `url('${SpBanner}')` }}>
                <div className="WrapperBnr">
                </div>
            </div>
            <div className="editProfile pb60">
                <div className="container">
                    <div className="row epRow">
                        <div className="epIn col-lg-6">
                            <div className="epHd ep-change">
                                <h2 className='epHd'>
                                    <span>
                                        <Link to="/profile-view" > <img src={LeftArrow} alt="" /> </Link>
                                    </span>
                                    Verify Email
                                </h2>
                                <span className='ep-change--sub'>We send a six digit verification code to {state.email}</span>
                            </div>
                            <div className="product--name epDetail">
                                <div className="epForm register-form">
                                    <div className="form-controller">
                                        <form className="formWrap formWrap--item changeItemWrp">
                                            {
                                                OTP.map((data, i) => {
                                                    return (
                                                        <>
                                                            <input
                                                                className='otp-field'
                                                                type="text"
                                                                name="otp"
                                                                maxLength="1"
                                                                key={i}
                                                                value={data}
                                                                onChange={e => handleChange(e.target, i)}
                                                                onFocus={e => e.target.select()}
                                                                onKeyUp={e => inputfocus(e)}
                                                                tabIndex={i + 1}
                                                            />
                                                        </>
                                                    );
                                                })
                                            }
                                        </form>
                                    </div>
                                    {/* <Link to="/success"> */}
                                    <button onClick={handleVerifyOtp} type="submit" class="btn btn-save">NEXT</button>
                                    {/* </Link>                             */}
                                </div>
                                <div className="footertemp">
                                <p class="fp">Don't receive code? <span className='ss' onClick={()=>dispatch(ResendOtp(state?.data)).then((resp)=> resp.type=== "auth/Resendotp/fulfilled"? message.success('Verification code sent successfully' ) : message.warn('failed to sent verification code') )} >Resend</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer noTopSpace />
        </div>
    );
}

export default VerifyEmail;