import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import BlueCar from '../images/car-blue.png';
import SpBanner from '../images/lamborgini.png'
import Profile from '../images/prof.jpeg'
import Rating from 'react-rating';
import { ImLocation } from "react-icons/im";
import { RiStarFill, RiStarLine } from "react-icons/ri";
import { RideDetailsById } from '../Redux/Features/Dataslice';
import { UpdateReviewById } from '../Redux/Features/Reviewslice';
import { message } from 'antd';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import ReportIssue from './ReportIssue';

function RideDetail4View() {
    const { state } = useLocation();
    const { RideDetailsUser } = useSelector(State => State.Data)
    const [refetch, setrefetch] = useState(false)
    const [edit, setEdit] = useState(false)
    const [Rate, setRate] = useState()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [review, setreview] = useState(RideDetailsUser?.review?.review)
    const Startvalue = RideDetailsUser?.ride_log?.ride_start?.split(",");
    const Endvalue = RideDetailsUser?.ride_log?.ride_start?.split(",");
    const dispatch = useDispatch()
    const { register, handleSubmit, control, formState: { errors } } = useForm()


    const onSubmit = (data) => {

        console.log(data);

        dispatch(UpdateReviewById({ id: RideDetailsUser?.review?.id, active: RideDetailsUser?.review?.active, vehicleId: RideDetailsUser?.vehicle_id, rating: Rate, rideId: RideDetailsUser?.id, review }))
            .then((res) => {
                console.log(res, 'after rev');
                res.payload.message === "Updated" && message.info('Review Updated')

                setrefetch(true)
                setEdit(false)
                setrefetch(false)

            })




    }
    // function for update review
    const handleUpdateReview = () => {


    }
    useEffect(() => {
        state?.item?.id && dispatch(RideDetailsById(state.item.id))
        //  dispatch(RideDetailsById())
        setRate()
    }, [dispatch, state?.item?.id, refetch])
    console.log(RideDetailsUser, 'RideDetailsUser');
    console.log(state.item.id, 'state.item.id');
    return (
        <div>
            {
                show &&
                <ReportIssue category='ride' Ride_code={RideDetailsUser.id} handleClose={handleClose} handleShow={handleShow} show={show} />
            }
            <div className="bannerSec spBanner" style={{ backgroundImage: `url('${SpBanner}')` }}>
                <div className="WrapperBnr confirmBooking">
                    <div className="container bnrCon h100 p-0">
                        <div className="cofirmBookingOnBnr">
                            <div className="row cbRow">
                                <div class="odHd">
                                    <h1>Ride Details</h1>
                                    {/* {RideDetailsUser?.ride_report?.active? <h1>active</h1>:<h1>not active</h1>} */}
                                    {!RideDetailsUser?.ride_report?.active &&
                                        <div onClick={handleShow} class="report">
                                            <span ><i class="fas fa-flag"></i></span>
                                            <span>Report</span>
                                        </div>
                                    }

                                </div>
                                <div className="col-lg-6">
                                    <div className="cbLeft h100">
                                        <div className="cbLcar h100">
                                            <img src={RideDetailsUser?.vehicle?.image} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="cbRight">
                                        <div className="cbRHd">
                                            <span className='orderId'>ORDER # {RideDetailsUser?.ride_code}</span>
                                            <h2>{RideDetailsUser?.vehicle?.name}</h2>
                                            <h6 className='orderDate'>{moment.utc(RideDetailsUser?.created_at).local().format('MMMM DD , h:mm a').trim()}</h6>
                                        </div>
                                        <ul className='bookingDetail'>
                                            <li className='bdItem'>
                                                <div className="bdLeft">
                                                    <span>Start Trip:</span>
                                                </div>
                                                <div className="bdRight">
                                                    <div className="bdRicon">
                                                        <span className='dot'>
                                                        </span>
                                                    </div>
                                                    <div className="optedItem">

                                                        <h3>{RideDetailsUser?.ride_log?.ride_start.slice(0, 6)}</h3>
                                                        {RideDetailsUser?.ride_log?.start_diff && <div style={{ display: 'flex' }}>  <p  >{`${Startvalue && Startvalue[2]}`}</p><p style={{ color: 'red' }} >{`(${RideDetailsUser?.ride_log?.start_diff})`}  </p>  </div>}
                                                    </div>
                                                </div>
                                            </li>
                                            <li className='bdItem'>
                                                <div className="bdLeft">
                                                    <span>End Trip:</span>
                                                </div>
                                                <div className="bdRight">
                                                    <div className="bdRicon">
                                                        <span className='dot withWhite'>

                                                        </span>
                                                    </div>
                                                    <div className="optedItem">

                                                        <h3>{RideDetailsUser?.ride_log?.ride_end?.slice(0, 6)}</h3>
                                                        {RideDetailsUser?.ride_log?.end_diff && <div style={{ display: 'flex' }}>  <p  >{`${Endvalue && Endvalue[2]}`}</p> <p style={{ color: '#479D4A' }} >{`(${RideDetailsUser?.ride_log?.end_diff})`}  </p>  </div>}
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="totalHrs">
                                            <h3><i class="fas fa-stopwatch"></i> {RideDetailsUser?.ride_log?.duration}</h3>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rideDetail">
                <div class="container p-0">
                    <div class="row opRow">
                        <div class="col-lg-7 colOpLt">
                            <div className="colOptions">
                                <div className="userCal">
                                    <div className="userLeft">
                                        <img src={RideDetailsUser?.driver?.image ? RideDetailsUser?.driver?.image : `https://joeschmoe.io/api/v1/${RideDetailsUser?.driver?.full_name}`} alt="" />
                                    </div>
                                    <div className="userRt">
                                        <span className='usrNm'>{RideDetailsUser?.driver?.full_name}</span>

                                    </div>
                                </div>
                                <div className="rideInfo">
                                    <span>Your Ride Has Completed </span>
                                </div>
                                <div className="ratingReview">
                                    <div className="rating">
                                        <h3>Rating & Review</h3>
                                        <div className="ratingAlter">
                                            <div class="ratingStars">
                                                <Rating
                                                    onChange={(rate) => setRate(rate)}
                                                    initialRating={Rate ? Rate : RideDetailsUser?.review?.rating}
                                                    emptySymbol={<RiStarLine size={24} color='#DEDEDE' />}
                                                    fullSymbol={<RiStarFill size={24} color='gold' />}
                                                    readonly={!edit}

                                                />
                                            </div>
                                            <div className="reviewEdit">
                                                {!edit && <button onClick={() => setEdit(!edit)} >Edit Review</button>}
                                            </div>
                                        </div>

                                        <div className="reviewDec">
                                            {edit ?
                                                <>
                                                    <form onSubmit={handleSubmit(onSubmit)} >
                                                        <textarea {...register('review', { required: ' Review is required', pattern: [/[a-z]/, /[A-Z]/, /[0-9]/], minLength: { value: 5, message: 'Review must be atleast 5 characters' }, maxLength: { value: 100, message: ' Review should be less than 100 characters' } })} className='reviewEdi' onChange={(e) => setreview(e.target.value)} maxlength="100" defaultValue={RideDetailsUser?.review?.review} name='review' />
                                                        {errors?.review && <p style={{ color: 'red' }}>{errors?.review.message}</p>}
                                                        <div className="customAmt">
                                                            <div className="cusInpBtn">

                                                                <button   >DONE</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </>
                                                : <p>{RideDetailsUser?.review?.review}</p>}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 colOpRt">
                            <div class="item-deliver">
                                <div class="item-deliver--edit">
                                    <span>Pickup Location:</span>
                                </div>
                                <div class="item-deliver--location">
                                    <div class="item-deliver--location--icon">
                                        {/* <i class="fad fa-map-marker-alt"></i> */}
                                        <ImLocation color='#F6491E' size={22} />
                                    </div>
                                    <div class="item-deliver--location--locname">
                                        <h2><span>Worls Trade Center</span>285 Fulton St, New York,NY 10007</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="calcRow bdrTp">
                                <div className="calcPart">
                                    <div class="orderSum">
                                        <h2>Order Summary</h2>
                                        <div class="orders">
                                            <div class="orderin">
                                                <div class="ordItemName"><span>Ride Cost (${RideDetailsUser?.user_payment?.cost_per_hour}/Hour)</span></div>
                                                <div class="itemPrice actualPrice"><span>$ {RideDetailsUser?.user_payment?.ride_cost} </span></div>
                                            </div>
                                            <div class="orderin">
                                                <div class="ordItemName"><span>Service Charges</span></div>
                                                <div class="itemPrice"><span>$ {RideDetailsUser?.user_payment?.service_charge}</span></div>
                                            </div>
                                            {RideDetailsUser?.user_payment?.discount &&
                                                <div class="orderin">
                                                    <div class="ordItemName"><span>Coupon Code Applied</span></div>
                                                    <div class="itemPrice"><span>-${RideDetailsUser?.user_payment?.discount}</span></div>
                                                </div>
                                            }

                                            <div class="orderin totalPrice">
                                                <div class="ordItemName"><span>TOTAL</span></div>
                                                <div class="itemPrice"><span>${RideDetailsUser?.user_payment?.total_amount}</span></div>
                                            </div>
                                        </div>
                                        <div className="paidCred">
                                            <div class="paidviaCred">
                                                <div className="pcredwrap">
                                                    <div className="refundMsg">
                                                        <div className='refifpaid'>
                                                            <span><i class="fa fa-check"></i></span> Paid via  card ending {RideDetailsUser?.user_payment?.card_last4}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RideDetail4View;
