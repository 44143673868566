import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../../Axios';
import Cookies from 'js-cookie'
export const ReviewAndTip = createAsyncThunk('review/tip', async (obj, { rejectWithValue }) => {
  // console.log('user Items', obj);
  const { Ammount, Review, cardId, driverId, rating, vehicleId, rideId } = obj
  try {
    const res =await axios.post('/review/rating_tip', {
      rating: rating,
      review: Review,
      ride_id: rideId,
      vehicle_id: vehicleId,
      driver_id: driverId,
      amount:parseInt(Ammount) ,
      card_id: cardId ?cardId:'rq123233'

    },
      { headers: { Authorization: `Bearer ${Cookies.get('token')}` } }

    )
    return res.data

  } catch (error) {
    return rejectWithValue(error)
  }

})
export const UpdateReviewById = createAsyncThunk('review/updateReview', async (obj, { rejectWithValue }) => {
  // console.log('user Items', obj);
  const { active,id,rating,review,rideId,vehicleId } = obj
  try {
    const res = await axios.put(`/review/${id}`, {
      active,
      rating,
      review,
      ride_id: rideId,
      vehicle_id: vehicleId

    },
      { headers: { Authorization: `Bearer ${Cookies.get('token')}` } }

    )
    return res.data
  } catch (error) {

  }

})

const initialState = {

}

const Reviewslice = createSlice({
  name: "second",
  initialState,
  reducers: {},
  extraReducers: {}
});

// export const {} = Review.actions

export default Reviewslice.reducer