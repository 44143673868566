import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ApplyProductCoupencode, ConfirmOrder, SingleCartById } from '../Redux/Features/Shopslice';
import { useForm } from 'react-hook-form';
import remvicn from '../images/offers.png';
import Skeleton from 'react-loading-skeleton';
import watch from '../images/watch.png'
import tickmark from '../images/tickmark.png'
import { message } from 'antd';

import { RemoveCoupencode, ApplyCoupencode } from '../Redux/Features/Dataslice';
import { toast,ToastContainer } from 'react-toastify';
// import { ToastContainer } from 'react-bootstrap';
function CbpView() {
    const [increment, setIncrement] = useState(1)
    const [CouponCode, setCouponCode] = useState(1)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [code, setcode] = useState()
    const [itm, setitm] = useState(false)
    const [coupenData, setcoupenData] = useState({})
    const { id } = useParams()
    const { state } = useLocation()
    const { register, handleSubmit, formState: { errors } } = useForm()
    // const { SingleProduct, loading } = useSelector(state => state.Shop)
    const { bookingDetails, coupenerror, coupenOffer, loading, CoupenRemove, error } = useSelector(state => state.Data)
    const { CartItems, orderPrice, ammountError, ProductCoupenError, CoponOffer, ShipmentDetails,SingleProduct } = useSelector(state => state.Shop)
    const handleIncrement = () => {
        setIncrement(increment + 1);
    }
    const handleDecrement = () => {
        if (increment !== 1) {
            setIncrement(increment - 1);
        }
    }

    const onSubmit = (detail) => {
        console.log(detail, 'detail');
        dispatch(ApplyProductCoupencode({ code: detail?.proCode, price: orderPrice?.total - orderPrice?.shipment_rate })).then((response) => {
            console.log(response, 'response');
            if (response.type !== 'data/Applycoupen/rejected') {
                sessionStorage.setItem('reFetch', true)
                toast('Promo code applied successfully')
                console.log(sessionStorage.getItem('reFetch'), 'under log');
                // setitm(true)

            }
            // if(response.type=== "data/Applycoupen/fulfilled"){
            //     message.success('Coupon Applied Successfully')
            // }
        })

    }



    useEffect(() => {

        dispatch(ConfirmOrder([{ product_id: parseInt(id), quantity: increment }])).then((res) => {
            console.log(res?.payload?.data?.rate?.total, 'res in cbview');
            if (res.payload.message=== "Ok") {
                coupenOffer.offer && sessionStorage.getItem('reFetch') &&dispatch(ApplyCoupencode({ code, price: res?.payload?.data?.rate?.total.toString(),type:'shop' }))


                .then((res) => {


                    if (res.type !== 'data/Applycoupen/rejected') {
                        sessionStorage.setItem('reFetch', true)
                        // message.info('Promo code applied successfully')
                        console.log(sessionStorage.getItem('reFetch'), 'under log');
                        setitm(true)

                    }
                }

                )
                
            }
        })
        // dispatch(ConfirmOrder()).then((res)=>{
        //     console.log(res.payload.message, 'res after confirming order');


        // })
    }, [dispatch, increment])
    console.log(orderPrice, 'orderPrice');
    return (
        <div>
            <div className="productConfirmBkg">
                <div className="container">
                    <div className="pcbRow row">
                        <div className="col-lg-5 col-sm-12 pcbColL">
                            <h2 style={{opacity:'0.6',fontSize:'xx-large',fontWeight:'400'}}>Confirm Your Booking</h2>
                            <div className="pcbLimg">
                                <img src={state?.SingleProduct?.product?.product_image[0].image} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-7 col-sm-12 pcbColR">
                            <div className="prodDetailDec prodDec">
                                <div className="pdHd">
                                    <span>{state?.SingleProduct?.product?.brand}</span>
                                    <h1>{state?.SingleProduct?.product?.title}</h1>
                                </div>
                                <div className="price proPrice">
                                    <h2>${state?.SingleProduct?.product?.unit_price}</h2>
                                </div>
                                <div className="countAlter">
                                    <span className='minus otrCircle' onClick={SingleProduct?.product?.stock === 0 ?message.info('Requested stock not available'): handleDecrement}>-</span>
                                    <span className="countNo">{increment}</span>
                                    <span className='plus otrCircle' onClick={handleIncrement}>+</span>
                                </div>
                                <div className="importantNote">
                                    <p> <img width={20} height={20} src={tickmark} alt=''/> Safe and secure payments. 100% authentic products</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {!ammountError ?
                        <div className="row calcRow pcb-payment-row">
                            <div className="col-lg-6 pl-0">
                                <div className="calcPart">

                                    {coupenOffer.offer && sessionStorage.getItem('reFetch') ? <div className="promoApplied">
                                        <div className="paLeft">
                                            <div className="iconpa">
                                                <img src={remvicn} alt="" />
                                            </div>
                                            <div className="patext">
                                                {coupenOffer.type === 'price_discount' ? <span>promo code Applied</span> : <span>You have got a {coupenOffer?.offer} <br />  you will get it during the time of service</span>}
                                                {/* <span>{coupenOffer.type === 'price_discount' ? 'Promocode applied' : `You have got a ${coupenOffer?.offer}  you will get it during the time of service`}</span> */}
                                            </div>
                                        </div>
                                        <div className="paRt">
                                            <div className="paremove">
                                                {coupenOffer ? <span onClick={() => dispatch(RemoveCoupencode({ discount: coupenOffer?.offer, price: coupenOffer.total_cost })).then((res) => {
                                                    console.log('Remove coupencode', res);
                                                    if (res.type === 'data/Removecoupen/fulfilled') {
                                                        toast('Promo code removed successfully')
                                                        sessionStorage.removeItem('reFetch')
                                                        setitm(false)

                                                        setcoupenData(res.payload)


                                                    }


                                                })} >Remove</span>

                                                    :
                                                    <>

                                                    </>
                                                }

                                            </div>
                                        </div>
                                    </div> :
                                    <>
                                    <h6>Do you have promo code ?</h6>
                                     <div className="promoInput">
                                        <input value={code} onChange={(e) => setcode(e.target.value)} type="text" placeholder='Enter Promo Code' />
                                        <button disabled={loading} onClick={() => !code ? toast('Enter Promo Code') : dispatch(ApplyCoupencode({ code, price: orderPrice?.total.toString(),type:'shop' }))


                                            .then((res) => {


                                                if (res.type !== 'data/Applycoupen/rejected') {
                                                    sessionStorage.setItem('reFetch', true)
                                                    toast('Promo code applied successfully')
                                                    console.log(sessionStorage.getItem('reFetch'), 'under log');
                                                    setitm(true)

                                                }
                                            }

                                            )
                                        } >APPLY</button>
                                       

                                    </div>
                                    </>
                                    }

{coupenerror && <h6>{coupenerror}</h6>}

                                </div>

                            </div>


                            <div className="col-lg-6 pr-0">
                                <div className="calcPart">

                                    <div className="orderSum">
                                        <h2>Order Summary</h2>
                                        <div className="orders">
                                            <div className="orderin">
                                                <div className="ordItemName">
                                                    <span>Total Item Price </span>
                                                </div>
                                                <div className="itemPrice actualPrice">
                                                    {orderPrice?.product_total ? <span>${orderPrice?.product_total}</span> : <span><Skeleton height={15} width={100} /></span>}
                                                </div>
                                            </div>
                                            <div className="orderin">
                                                <div className="ordItemName">
                                                    <span>Service Charges</span>
                                                </div>
                                                <div className="itemPrice">
                                                    {orderPrice?.service_charge ? <span>${orderPrice?.service_charge}</span> : <span><Skeleton height={15} width={100} /></span>}
                                                </div>
                                            </div>
                                            <div className="orderin">
                                                <div className="ordItemName">
                                                    <span>Shipping Charges</span>
                                                </div>
                                                <div className="itemPrice">
                                                    {orderPrice?.service_charge ? <span>${orderPrice?.shipment_rate}</span> : <span><Skeleton height={15} width={100} /></span>}
                                                </div>
                                            </div>
                                            {

                                                sessionStorage.getItem('reFetch') && coupenOffer.type === 'price_discount' &&
                                                <div className="orderin">
                                                    <div className="ordItemName">
                                                        <span>Coupon Code Applied </span>


                                                    </div>
                                                    <div className="itemPrice">
                                                        <span>- {coupenOffer && coupenOffer?.offer}</span>
                                                    </div>
                                                </div>


                                            }

                                            <div className="orderin totalPrice">
                                                <div className="ordItemName">
                                                    <span>TOTAL</span>
                                                </div>
                                                <div className="itemPrice">
                                                {/* {CartCount?.count >0&&<span>{CartCount?.count === 0  ? `${CartCount.count} Item in your cart`:(CartCount?.count === 1?`${CartCount.count} Item in your cart`:`${CartCount.count} Items in your cart`)} </span>} */}
                                                {/* {sessionStorage.getItem('reFetch') ||!orderPrice?.total ? <span><Skeleton/></span>: <span>$  {sessionStorage.getItem('reFetch') && coupenOffer.total_cost ? coupenOffer.total_cost : orderPrice?.total}</span> } */}
                                                  {sessionStorage.getItem('reFetch') && coupenOffer.total_cost ? <span>{coupenOffer.total_cost}</span>: orderPrice?.total? <span>{orderPrice?.total}</span>:<span><Skeleton height={15} width={100} /></span>  }
                                                    {/* {CoponOffer?.total_cost ? <span>${ CoponOffer?.total_cost }</span> :<span>${ orderPrice?.total-orderPrice?.shipment_rate }</span>   } */}
                                                    {/* {  orderPrice?.service_charge?<span>${orderPrice?.total-orderPrice?.shipment_rate}</span>: <span><Skeleton height={15} width={100} /></span> }   */}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="proceedBtn btnPayProceed">
                                    <button onClick={() => navigate('/add-payment2', { state: { orderPrice: orderPrice, product: state?.SingleProduct?.product, qnty: increment, shipping_details: ShipmentDetails,couponOffer:coupenOffer,code:code } })} >PROCEED</button>
                                </div>
                            </div>
                        </div> :
                        <div className="row calcRow pcb-payment-row">
                            <div className="col-lg-6 pl-0">
                                <div className="calcPart">
                                    <h6>{ammountError === 'No shipping Address' ? `${ammountError} found, please add shipping address  to see order summary` : `Requested stock not available `}</h6>
                                </div>

                            </div>
                        </div>
                    }

                </div>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={3300}
                hideProgressBar
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default CbpView;
