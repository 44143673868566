import React,{useState} from 'react';
import { Button, Modal,Form  } from 'react-bootstrap';
function DurationPopUp({duration,handleDurationClose,setdistance,distance}) {
  const [radio,setRadio] = useState('false')
  return (
    <div>
      <Modal show={duration} onHide={handleDurationClose} className='modalDuration'>
        <Modal.Header closeButton>
          <Modal.Title>Set Duration</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='chooseDuration'>
          <Form>

            <Form.Check className='durationCheck'  inline label="1 Hour" type="radio" id="radio1" value="1" checked={distance === '1'} onChange={(e)=>{setdistance(e.target.value)}}/>

            <Form.Check className='durationCheck'  inline label="2 Hour" type="radio" id="radio2" value="2" checked={distance === "2"} onChange={(e)=>{setdistance(e.target.value)}}/>
                                            
            <Form.Check className='durationCheck'  inline label="3 Hour" type="radio" id="radio3" value="3" checked={distance === "3"} onChange={(e)=>{setdistance(e.target.value)}}/>
                                            
            <Form.Check className='durationCheck' inline label="4 Hour" type="radio" id="radio4" value="4" checked={distance === "4"} onChange={(e)=>{setdistance(e.target.value)}}/> 

            <Form.Check className='durationCheck' inline label="5 Hour" type="radio" id="radio5" value="5" checked={distance === "5"} onChange={(e)=>{setdistance(e.target.value)}}/>

            <Form.Check className='durationCheck' inline label="6 Hour" type="radio" id="radio6" value="6" checked={distance === "6"} onChange={(e)=>{setdistance(e.target.value)}}/>
                                            
            <Form.Check className='durationCheck' inline label="7 Hour" type="radio" id="radio7" value="7" checked={distance === "7"} onChange={(e)=>{setdistance(e.target.value)}}/>
                                            
            <Form.Check className='durationCheck' inline label="8 Hour" type="radio" id="radio8" value="8" checked={distance === "8"} onChange={(e)=>{setdistance(e.target.value)}}/> 
            <Form.Check className='durationCheck' inline label="9 Hour" type="radio" id="radio9" value="9" checked={distance === "9"} onChange={(e)=>{setdistance(e.target.value)}}/> 
            <Form.Check className='durationCheck' inline label="10 Hour" type="radio" id="radio10" value="10" checked={distance === "10"} onChange={(e)=>{setdistance(e.target.value)}}/> 
            <Form.Check className='durationCheck' inline label="11 Hour" type="radio" id="radio11" value="11" checked={distance === "11"} onChange={(e)=>{setdistance(e.target.value)}}/> 
            <Form.Check className='durationCheck' inline label="12 Hour" type="radio" id="radio12" value="12" checked={distance === "12"} onChange={(e)=>{setdistance(e.target.value)}}/> 
            <Form.Check className='durationCheck' inline label="13 Hour" type="radio" id="radio13" value="13" checked={distance === "13"} onChange={(e)=>{setdistance(e.target.value)}}/> 
            <Form.Check className='durationCheck' inline label="14 Hour" type="radio" id="radio14" value="14" checked={distance === "14"} onChange={(e)=>{setdistance(e.target.value)}}/> 
            <Form.Check className='durationCheck' inline label="15 Hour" type="radio" id="radio15" value="15" checked={distance === "15"} onChange={(e)=>{setdistance(e.target.value)}}/> 
            <Form.Check className='durationCheck' inline label="16 Hour" type="radio" id="radio16" value="16" checked={distance === "16"} onChange={(e)=>{setdistance(e.target.value)}}/> 
            <Form.Check className='durationCheck' inline label="17 Hour" type="radio" id="radio17" value="17" checked={distance === "17"} onChange={(e)=>{setdistance(e.target.value)}}/> 
            <Form.Check className='durationCheck' inline label="18 Hour" type="radio" id="radio18" value="18" checked={distance === "18"} onChange={(e)=>{setdistance(e.target.value)}}/> 
            <Form.Check className='durationCheck' inline label="19 Hour" type="radio" id="radio19" value="19" checked={distance === "19"} onChange={(e)=>{setdistance(e.target.value)}}/> 
            <Form.Check className='durationCheck' inline label="20 Hour" type="radio" id="radio20" value="20" checked={distance === "20"} onChange={(e)=>{setdistance(e.target.value)}}/> 
            <Form.Check className='durationCheck' inline label="21 Hour" type="radio" id="radio21" value="21" checked={distance === "21"} onChange={(e)=>{setdistance(e.target.value)}}/> 
            <Form.Check className='durationCheck' inline label="22 Hour" type="radio" id="radio22" value="22" checked={distance === "22"} onChange={(e)=>{setdistance(e.target.value)}}/> 
            <Form.Check className='durationCheck' inline label="23 Hour" type="radio" id="radio23" value="23" checked={distance === "23"} onChange={(e)=>{setdistance(e.target.value)}}/> 
            <Form.Check className='durationCheck' inline label="24 Hour" type="radio" id="radio24" value="24" checked={distance === "24"} onChange={(e)=>{setdistance(e.target.value)}}/> 

          </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDurationClose} className='closeBtn'>
            Cancel 
          </Button>
          <Button variant="primary" onClick={handleDurationClose} className='saveBtn'>
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DurationPopUp;
