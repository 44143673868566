import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { GetLoggedinUser } from '../Redux/Features/Authslice';
import { CancelRide, ContactUs } from '../Redux/Features/Dataslice';
import ContactConfirmPopup from './Contactconfirmpopup';
import { useForm } from 'react-hook-form';
import { message } from 'antd';
import { CancelOrder } from '../Redux/Features/Dataslice';
import Cookies from 'js-cookie';

function ContactView() {
    const { state } = useLocation()
    const { user } = useSelector(State => State.Auth)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [time, settime] = useState('Morning')
    const [wayTocontact, setwayTocontact] = useState("Phone only")
    const [subject, setsubject] = useState()
    const [rating, setRating] = useState()
    const [isError, setisError] = useState(false)
    const [confirmPopup, setconfirmPopup] = useState(false)
    const { register, handleSubmit, control, formState: { errors } } = useForm(
        {
            defaultValues: {
                name:state?.user?.full_name? state?.user?.user?.full_name:localStorage.getItem('revup user'),
                email:state?.user?.user?.email? state?.user?.user?.email:localStorage.getItem('email'),
                phone:state?.user?.user?.email? state?.user?.user?.phone:localStorage.getItem('phone'),
                subject:state?.subject,

            }
        }
    )
    
// console.log(user?.user?.full_name,'user');
console.log(state,'subject issssssssssss');
console.log(state?.subject?.startsWith('Order'));
    const onSubmit = (data) => {

        console.log(data, 'data');
        dispatch(ContactUs({ name: data.name, email: data.email, phone_code: user?.user?.phone_code, phone: data.phone, time: data.time, wayTocontact: data.wayTocontact, message: data.message, subject: data.subject })).then((res) => {
            // console.log(res, 'response is ');
            if (res.type === 'data/contactUs/fulfilled') {
                setconfirmPopup(true)
                console.log(state?.subject,'subbbb');
                // if (state?.subject=== "cancel order") {
                //     navigate('/orders')
                // }else if (state?.subject=== "Cancel Ride"){
                //     dispatch(CancelRide(state?.Ride_id)).then((res) => {
                //         // console.log(res, 'cancel ride');
                //     }) 
                // }else{
                //     navigate('/')
                // }
                state.subject.startsWith('Order')? 
                
                dispatch(CancelOrder(state?.orderId)).then(()=>{
                    navigate('/orders')
                }) 
                
                :
                 dispatch(CancelRide(state?.Ride_id)).then((res) => {
                    // console.log(res, 'cancel ride');
                }) 



            }
        })



    }
    const handleContact = ({ name, email, phone_code, phone, time, wayTocontact, message }) => {

        // console.log(name,email);
        // dispatch(ContactUs( {name, email, phone_code, phone, time, wayTocontact, message} )).then((res) => {
        //     console.log(res, 'response is ');
        //     if (res.type === 'data/contactUs/fulfilled') {
        //           setconfirmPopup(true)
        //           state?.subject ? dispatch(CancelRide(state?.Ride_id)).then((res)=>{
        //               console.log(res,'cancel ride');
        //           }) : console.log('not cancelled');



        //     }
        // })

    }
    //UseEffect for contact view
    useEffect(() => {
        dispatch(GetLoggedinUser()).then((res)=>{
            
            if (res?.payload?.response?.data?.statusCode===401) {
                message.error('Account is blocked by admin,please contact revup support team')
                Cookies.remove('token')
                navigate('/login')
                
                
            }
        })
    }, [dispatch])
    
    return (
        <div>
            <div className="accRegistration ContactUs">
                <div className="container">
                    <div className="row accRow contactRow">
                        <div className="col-lg-12 colRgRight p-0 align-self-center">
                            <div className="rgRtWrap">

                                <div action="#" class="register-form">
                                    <form onSubmit={handleSubmit(onSubmit)} >
                                        <div className="formWrap FormRow">
                                            <div className="form-controller col-lg-6">
                                                <label htmlFor="">Name</label>
                                                <input {...register('name', { required: 'Name is required',minLength: { value: 3, message: 'Name must be atleast 3 characters' }, pattern: { value: /^[a-zA-Z ]*$/, message: 'Name Must be Alphabets' },maxLength:{value:20,message:'Name must be less than 20 alphabets'} })} type="text"  name="name" placeholder="First Name" />
                                                {errors?.name && <p style={{ color: 'red' }}>{errors?.name.message}</p>}

                                            </div>
                                            <div className="form-controller col-lg-6">
                                                <label htmlFor="">Email</label>
                                                <input {...register('email', {
                                                    required: 'E-mail is required', pattern: {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                        message: "Invalid email address",
                                                        
                                                    },
                                                    maxLength:{value:50,message:'E-mail must be less than 50 characters'}
                                                })} type="text"  name="email" placeholder="Email" />
                                                {errors?.email && <p style={{ color: 'red' }}>{errors?.email.message}</p>}
                                            </div>
                                            <div className="form-controller col-lg-6">
                                                <label htmlFor="">Phone</label>
                                                <input {...register('phone', { required: 'Phone number is required',pattern:{value:/\d+/ ,message:'Phone must be numbers'} ,maxLength: { value: 10, message: ' Phone number must be 10 digits  ' }, minLength: { value: 10, message: ' Phone number must be 10 digits ' }, })} type="number" control={control} min='0'  name="phone" placeholder="Phone" />
                                                {errors?.phone && <p style={{ color: 'red' }}>{errors?.phone.message}</p>}
                                            </div>
                                            <div className="form-controller col-lg-6">
                                                <label htmlFor="">Best time to contact</label>



                                                {/* use form start */}

                                                <select name="time"
                                                    {...register('time', { required: 'Best time to contact is Required' })}>
                                                    <option value='' selected disabled hidden>Select</option>
                                                    <option value="Morning">Morning</option>
                                                    <option value="Afternoon">Afternoon</option>
                                                    <option value="Evening">Evening</option>
                                                    <option value="Anytime">Anytime</option>
                                                </select>
                                                {errors.time && <p style={{ color: 'red' }}> {errors.time.message}</p>}



                                            </div>
                                            <div className="form-controller col-lg-6">
                                                <label htmlFor="">Best way to contact</label>

                                                <div class="customs_selects">
                                                    <select {...register("wayTocontact", { required: "Best way to contact is Required" })} onClick={(e) => setwayTocontact(e.target.value)} name="wayTocontact" class="customs_sel_box">
                                                        <option value='' selected disabled hidden>Select</option>
                                                        <option value="Phone only">Phone only</option>
                                                        <option value="Email only">Email only</option>
                                                        <option value="Both">Both</option>
                                                    </select>
                                                    {errors?.wayTocontact && <p style={{ color: 'red' }}>{errors?.wayTocontact.message}</p>}
                                                </div>
                                            </div>
                                            <div className="form-controller col-lg-6">
                                                <label htmlFor="">Subject</label>
                                                <input {...register('subject', { required: 'Subject is required',maxLength: { value: 50, message: ' Subject must be less than 50 characters  ' }, minLength: { value: 5, message: ' Subject should be atleast 5 characters ' } })}  type="text" name="subject" placeholder="Subject" />
                                                {errors?.subject && <p style={{ color: 'red' }}>{errors?.subject.message}</p>}
                                            </div>

                                            <div className="form-controller col-lg-12 p-0">
                                                <label htmlFor="">Message</label>
                                                <textarea {...register("message", { required: 'Message is required', minLength: { value: 6, message: ' Message must be atleast 6 characters' }, maxLength: { value: 100, message: 'Message must be lessthan 100 charecters ' } })} onChange={(e) => setsubject(e.target.value)} maxLength={100} name="message" id="" cols="30" >


                                                </textarea>
                                                {errors?.message && <p style={{ color: 'red' }}>{errors?.message.message}</p>}
                                            </div>
                                            {/* {isError &&  <span style={{color:'red'}} >message required</span>}  */}
                                        </div>
                                        <button type="submit" class="btn contactBtn">SUBMIT</button>







                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {
                confirmPopup && <ContactConfirmPopup setconfirmPopup={setconfirmPopup} support={confirmPopup} subject={state?.subject} />
            }
        </div>
    );
}

export default ContactView;