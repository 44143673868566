import React from 'react'
import { useSelector } from 'react-redux';

function BellIcon() {
  const {UserNoticationCount}=useSelector(State=>State.User)
  return (
    <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 23.442 26">
            <g id="Group_1452" data-name="Group 1452" transform="translate(-1708.558 -61.692)">
                <path id="bell" d="M73.582,20.691a4.04,4.04,0,0,1-1.228-1.672,19.343,19.343,0,0,1-1.28-7.643c0-.01,0-.02,0-.029a7.377,7.377,0,0,0-4.343-6.691V2.9A2.9,2.9,0,0,0,63.841,0H63.6a2.9,2.9,0,0,0-2.892,2.9v1.76a7.377,7.377,0,0,0-4.343,6.72,19.343,19.343,0,0,1-1.28,7.643,4.04,4.04,0,0,1-1.228,1.672.74.74,0,0,0-.407.842.775.775,0,0,0,.764.6H59.8a3.922,3.922,0,0,0,7.843,0h5.583a.775.775,0,0,0,.764-.6A.741.741,0,0,0,73.582,20.691ZM62.233,2.9A1.371,1.371,0,0,1,63.6,1.526h.24A1.371,1.371,0,0,1,65.209,2.9V4.162a7.377,7.377,0,0,0-2.976,0V2.9ZM66.788,20.6H55.952a7.984,7.984,0,0,0,.4-.7,19.285,19.285,0,0,0,1.539-8.526,5.831,5.831,0,1,1,11.662,0c0,.009,0,.019,0,.028A19.246,19.246,0,0,0,71.09,19.9a7.991,7.991,0,0,0,.4.7Z" transform="translate(1655.124 61.692)" fill="#fff"/>
                <g id="Ellipse_36" data-name="Ellipse 36" transform="translate(1721 64.692)" fill="#ff3d00" stroke="#fff" stroke-width="1">
                  {UserNoticationCount.count > 0 &&
                  <>
                <circle cx="5.5" cy="5.5" r="5.5" stroke="none"/>
                <circle cx="5.5" cy="5.5" r="5" fill="none"/> 
                </>
              }
                </g>
            </g>
        </svg>
    </div>
  );
}

export default BellIcon;