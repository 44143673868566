import React, { useEffect } from 'react';
import appStore from '../images/appstore.png'
import gplay from '../images/gplay.png'
import bg from '../images/bg-banner.png'
import bnrCar from '../images/bnrCar.png'
import servCar from '../images/bnrCar1.png'
import Deal from '../images/deal.svg'
import dollar from '../images/dollar.svg'
import call from '../images/call.svg'
import car from '../images/car.png'
import location from '../images/location.png'
import book from '../images/book.png'
import Faqs from '../Componants/Faqs';
import NavBar from '../Componants/NavBar'
import CarSlider from '../Componants/CarSlider'
import Footer from '../Componants/Footer'
import ShopCta from '../Componants/ShopCta';
import GadgetCta from '../Componants/GadgetCta';
import BookCabCta from '../Componants/BookCabCta';
import { Accordion } from 'react-bootstrap';





function Home() {
    
 
  useEffect(() => {
    // location.reload();
    window.scrollTo({ behavior: 'smooth' });
  }, [])
  
  
  return ( 
        <div>
            <NavBar/>
            <div className="bannerSec" style={{ backgroundImage: `url('${bg}')` }}>
                    <div className="WrapperBnr">
                        <div className="container bnrCon h100">
                            <div className="row h100">
                                <div className="col-lg-5 colBnrLeft">
                                    <div className="bnrLeft">                                       
                                        <h1><span>Enjoy the</span> <span>Experience with us!</span></h1>
                                        <p>Donec felis neque, pellentesque vel enim at, hendrerit vulputate augue. Donec eget odio in lorem congue</p>
                                    </div>
                                    <div className="bnrLBtns">
                                        <div className="btnbnr">
                                            <img src={appStore} alt="" />
                                        </div>
                                        <div className="btnbnr">
                                            <img src={gplay} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-7 align-self-center colBnrRight h100">
                                    <div className="bnrRight h100">
                                        <div className="bnrRin h100">
                                            <img src={bnrCar} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            <div className="prodSlider">
                <CarSlider/> 
            </div>
            <BookCabCta />
            <div className="servicesSec">                
                <div className="container">
                <div className="servHd">
                    <span>SERVICES</span>
                    <h2>Feel the best experience with our rental deals.</h2>
                </div>
                    <div className="row servRow">
                        <div className="col-lg-6 serColL">
                            <div className="serImg">
                                <img src={servCar} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 serColR">
                            <div className="servList">
                                <ul className='servUl'>
                                    <li className='serli'>
                                        <div className="liLft">
                                            <img src={Deal} alt="" />
                                        </div>
                                        <div className="liRight">
                                            <h3>Deals for Every Budget</h3>
                                            <p>Donec felis neque, pellentesque vel enim at, hendrerit vulputate augue. Donec eget odio in lorem congue</p>
                                        </div>
                                    </li>
                                    <li className='serli'>
                                        <div className="liLft">
                                            <img src={dollar} alt="" />
                                        </div>
                                        <div className="liRight">
                                            <h3>Best Price Guarantee</h3>
                                            <p>Donec felis neque, pellentesque vel enim at, hendrerit vulputate augue. Donec eget odio in lorem congue</p>
                                        </div>
                                    </li>
                                    <li className='serli'>
                                        <div className="liLft">
                                            <img src={call} alt="" />
                                        </div>
                                        <div className="liRight">
                                            <h3>Support 24/7</h3>
                                            <p>Donec felis neque, pellentesque vel enim at, hendrerit vulputate augue. Donec eget odio in lorem congue</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <GadgetCta />
            <div className="howItWork pt60 pb60">
                <div className="container">
                    <div className="secHd">
                        <span>HOW IT WORKS</span>
                        <h2>Revup following 3 working steps</h2>
                    </div>
                    <div className="row hiwRow">
                        <div className="col-lg-4">
                            <div className="hiwItem">
                                <div className="hiwThumb">
                                    <img src={car} alt="" />
                                </div>
                                <div className="hiwDec">
                                    <h3>Choose Car</h3>
                                    <p>Donec felis neque, pellentesque vel enim at, hendrerit vulputate augue. Donec eget odio in lorem congue</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="hiwItem">
                                <div className="hiwThumb">
                                    <img src={location} alt="" />
                                </div>
                                <div className="hiwDec">
                                    <h3>Pickup Date And Location</h3>
                                    <p>Donec felis neque, pellentesque vel enim at, hendrerit vulputate augue. Donec eget odio in lorem congue</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="hiwItem">
                                <div className="hiwThumb">
                                    <img src={book} alt="" />
                                </div>
                                <div className="hiwDec">
                                    <h3>Book Your Car</h3>
                                    <p>Donec felis neque, pellentesque vel enim at, hendrerit vulputate augue. Donec eget odio in lorem congue</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Faqs/> */}
            <div style={{backgroundColor:'#F6F6F6'}}>
                <div style={{display:'flex',textAlign:'center',justifyContent:'center',flexDirection:'column'}} >
                    <h6 style={{color:'110403'}} >FAQ'S</h6>
                    <h2>Do you have any question?</h2>
                </div>
            <div style={{backgroundColor:'#F6F6F6'}} className="container faqsSec faqPgIn pb60">
                  <Accordion defaultActiveKey="0" flush class="faqAcc">
                    <Accordion.Item eventKey="0" className='itemAccord'>
                        <Accordion.Header>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Accordion.Header>
                        <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                        cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                        est laborum.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1" className='itemAccord'>
                        <Accordion.Header>Ut enim ad minim veniam</Accordion.Header>
                        <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                        cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                        est laborum.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2" className='itemAccord'>
                        <Accordion.Header>Ut enim ad minim veniam</Accordion.Header>
                        <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                        cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                        est laborum.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3" className='itemAccord'>
                        <Accordion.Header>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Accordion.Header>
                        <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                        cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                        est laborum.
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                </div>
            </div>
            
            <ShopCta/>
            <Footer/>
        </div>
  );
}

export default Home;
