import React from 'react';
import AddPaymentView2 from '../Componants/AddPaymentView2';
function AddPayment2() {
  
  return (
    <div>
        <AddPaymentView2 />
    </div>
  );
}

export default AddPayment2;
