import React from 'react';
import Footer from '../Componants/Footer';
import NavBar from '../Componants/NavBar';
import RideDetail3View from '../Componants/RideDetail3View';

function RideDetail3() {
  return (
    <div>
        <NavBar />
        <RideDetail3View />
        <Footer noTopSpace />
    </div>
  );
}

export default RideDetail3;
