import { message } from 'antd';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AddNewShippingAddres, GetAllShippingAddress, GetAllState } from '../Redux/Features/Userslice';

function AddNewAddress({ setAddAdress }) {

    const { register, handleSubmit, control, formState: { errors } } = useForm()
    const { states } = useSelector(state => state.User)
    const dispatch = useDispatch()
    const [items] = useState(['one', 'two', 'three'])
    const onSubmit = (data) => {

        // console.log(data, 'sD');
        dispatch(AddNewShippingAddres(data)).then((res) => {
            console.log(res, 'res after adding new ship');
            if (res?.payload?.message === "Created") {
                message.info('Shipping Address Added Successfully')
                setAddAdress(false)
                dispatch(GetAllShippingAddress())
            } else {
                message.warning('Shipping Address Verification failed')
            }

        })



    }

    useEffect(() => {
        dispatch(GetAllState()).then((res) => {
            console.log(res, 'res after state');
        })
    }, [dispatch])
    console.log(states, 'states');
    return (
        <div>
            <div className="rgRtWrap">
                <div className="rgRtHd addNwAdrsHd">
                    <h2>Add Shipping Address</h2>
                </div>
                <div action="#" className="register-form addNewForm">
                    <form onSubmit={handleSubmit(onSubmit)} >
                        <div className="formWrap">
                            <div className="form-controller">
                                <label for="">Name</label>

                                <input {...register('name', { required: ' Name is required', minLength: { value: 3, message: 'Name must be atleast 3 characters' }, pattern: { value: /^[a-zA-Z ]*$/, message: 'Name Must be Alphabets' },maxLength:{value:20,message:'Name must be less than 20 alphabets'} })} type="text" autocomplete="off" name="name" placeholder=" Name" />
                                {errors?.name && <p style={{ color: 'red' }}>{errors?.name.message}</p>}
                            </div>
                            <div className="form-controller">
                                <label for="">Address</label>
                                <input {...register('address', { required: ' Address is required', pattern: [/[a-z]/, /[A-Z]/, /[0-9]/], minLength: { value: 3, message: 'Address must be atleast 3 characters' },maxLength:{value:70,message:'Address must be less than 70 characters'} })} type="text" autocomplete="off" name="address" placeholder=" Address    " />
                                {errors?.address && <p style={{ color: 'red' }}>{errors?.address.message}</p>}
                            </div>
                            <div className="form-controller">
                                <label for="">Unit/Floor</label>
                                <input {...register('unit', { required: ' Unit/floor is required', pattern: [/[a-z]/, /[A-Z]/, /[0-9]/], minLength: { value: 3, message: 'Unit/floor must be atleast 3 characters' },maxLength:{value:30,message:'Unit/floor must be less than 30 characters'} })} type="text" autocomplete="off" name="unit" placeholder=" Unit/Floor" />
                                {errors?.unit && <p style={{ color: 'red' }}>{errors?.unit.message}</p>}
                            </div>
                            <div className="form-controller">
                                <label for="">Landmark</label>
                                <input {...register('Landmark', { pattern: [/[a-z]/, /[A-Z]/, /[0-9]/], minLength: { value: 3, message: 'Landmark must be atleast 3 characters' },maxLength:{value:70,message:'Landmark must be less than 70 characters'} })} type="text" autocomplete="off" name="Landmark" placeholder=" Landmark    " />
                                {errors?.Landmark && <p style={{ color: 'red' }}>{errors?.Landmark.message}</p>}
                            </div>
                            <div className="form-controller">
                                <label for="">City</label>
                                <input {...register('city', { required: ' City is required', minLength: { value: 3, message: 'City must be atleast 3 characters' }, pattern: { value: /^[a-zA-Z ]*$/, message: 'Name Must be Alphabets' },maxLength:{value:30,message:'City must be less than 30 characters'} })} type="text" autocomplete="off" name="city" placeholder=" City" />
                                {errors?.city && <p style={{ color: 'red' }}>{errors?.city.message}</p>}
                            </div>
                           
                            <div className="form-row row">
                            <div className="form-controller col-lg-6">
                                <label for="">State</label>


                                {/* use form start */}

                                <select className='select-state' {...register("state_id", { required: 'State is required' })} name='state_id'>
                                    {states?.states?.map((item) => (
                                        <>
                                            <option selected disabled hidden>Select</option>
                                            <option value={item.id}>{item.name}</option>
                                        </>
                                    ))}
                                </select>
                                {errors?.state_id && <p style={{ color: 'red' }}>{errors?.state_id.message}</p>}

                            </div>
                            <div className="form-controller col-lg-6">
                                <label for="">Zip Code</label>
                                <input {...register('zip_code', { required: 'Zip Code is required', pattern: { value: /\d+/, message: 'Zip Code must be numbers' }, minLength: { value: 5, message: ' Zip Code  must be atleast 5 digits ' }, maxLength: { value: 7, message: 'Zip code should be less than 7 digits' } })} className='phnin' type="number" autocomplete="off" name="zip_code" placeholder="Zip Code" control={control} />
                                {errors?.zip_code && <p style={{ color: 'red' }}>{errors?.zip_code.message}</p>}
                            </div>
                            </div>
                            
                            </div>




                            <div className="formFooter addFoo">
                            <div className="ff-left">
                                {/* <div class="new">
                                    <form>
                                        <div class="form-group">
                                            <input type="checkbox" id="html" />
                                            <label for="html" class="dafcheck">Save as default</label>
                                        </div>
                                    </form>
                                </div> */}
                            </div>
                        </div>

                        <button type="submit" className="btn">Save</button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default AddNewAddress;










