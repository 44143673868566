import React, { useEffect } from 'react';
import { Button, Modal} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { GetLoggedinUser } from '../Redux/Features/Authslice';
import { useDispatch, useSelector } from 'react-redux';
function SupportPopUp({state,handleSupportClose,support,Ride_id,subjectt,id,orderId}) {
  const dispatch = useDispatch()
  const { user } = useSelector(State => State.Auth)
const navigate =useNavigate()
console.log('Ride_id is',Ride_id);
console.log(state,'state am');
  //arrow function for handleclick
  const handleClick = () => {
    navigate('/contact-us',{state:{item:state.item,subject:subjectt?`Order Cancel Request - order # ${orderId}`:'Cancel Ride',Ride_id,user,orderId:id}})
    // handleSupportClose();
  }
  useEffect(() => {
    dispatch(GetLoggedinUser())
  }, [dispatch])
  console.log(id,'id in support pop up');
  return (
    <div>
      <Modal  show={support} onHide={handleSupportClose} className='modalDuration  support-modal '>
        <Modal.Body >
          <div className="suppPop">
            <div className='suppCon'>
                <span>Please contact Revup support team to reschedule the appointment</span>
            </div>
            <Button onClick={handleClick} className='supportBtn'>
                CONTACT SUPPORT
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default SupportPopUp;