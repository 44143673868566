import { message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Cards from '../images/cards.png';
import MasterCard from '../images/master.png';
import VisaCard from '../images/visa.png';
import { GetAllCards, MakeCardDefault } from '../Redux/Features/Cardslice';
import { BookMyRides } from '../Redux/Features/Dataslice';
import AddCard from './AddCard';
function Tabs() {
    const [toggle, setToggle] = useState(1);
    console.log((Cards, 'Cards are'));
    const [extraClass, setExtraClass] = useState(Cards[0]?.id);
    const [addCard, setAddCard] = useState(false);
    const [refetchcard, setrefetchcard] = useState(false);
    const [SelectedCard, setSelectedCard] = useState({});
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { cards } = useSelector(state => state.Card)
    const { bookingError, loading, bookError } = useSelector(state => state.Data)
    const { state } = useLocation()

    const { Ridecost, serviceCharge, offer, total, product_image, vehicle_name, number_plate, location_title, location_spot, duration, start, end, vehicle_rate, location_id, vehicle_id, coupen_id, start_at, ride_date, offer_type, peak_day, product_Offer } = state
    console.log(product_Offer, 'product offer');

    const invertClass = (e) => {
        setExtraClass(e);
    };
    const handleToggle = (e) => {
        console.log("test", e);
        setToggle(e);
    }
    const handleClick = (e) => {
        setExtraClass(e.id)
        dispatch(MakeCardDefault(e.id)).then(() => {
            setrefetchcard(true)
            setrefetchcard(false)
        })
        // console.log(e, 'e');
        setSelectedCard({
            brand: e.brand,
            last4: e.last4,
        })
    }
    const handleAddCardClose = () => setAddCard(false);
    const handleAddCardShow = () => setAddCard(true);

    useEffect(() => {
        dispatch(GetAllCards()).then((res) => {
            console.log(res, 'res is');
            setExtraClass(res.payload[0].id)
        })

    }, [dispatch, refetchcard])

    return (
        <div>

            {
                addCard === true && <AddCard handleAddCardClose={handleAddCardClose} setrefetchcard={setrefetchcard} addCard={addCard} />
            }
            <div className="payMethods">
                <div className="container">
                    <div className="pmRow">
                        <ul className="nav nav-tabs" role="tablist">
                            <li className="nav-item">
                                <span
                                    className={"nav-link " + (toggle === 1 ? "active" : "")}
                                    data-toggle="tab" role="tab"
                                    onClick={() => handleToggle(1)}
                                >Add Payment method</span>
                            </li>
                            <li className="nav-item">
                                <span
                                    className={"nav-link " + (toggle === 2 ? "active" : "")}
                                    data-toggle="tab" role="tab"
                                    onClick={() => cards?.length === 0 ? message.info('Please add payment card to proceed payment') : handleToggle(2)}
                                >Confirm Booking</span>
                            </li>
                        </ul>
                        <div className="tab-content">
                            <div className={"tab-pane " + (toggle === 1 ? "active" : "")} id="tabs-1" role="tabpanel">
                                <div className="paneWrap">
                                    <div className="row pwRow">

                                        <div className="col-lg-6">
                                            {cards.length > 0 ? <>
                                                <h2>Your Payment Method</h2>


                                                <div className="pmLeft ">
                                                    <label className='bkCredHd'>
                                                        <div className="ptBank">
                                                            <div className="bankNm nmbk">
                                                                <span></span>
                                                            </div>
                                                            <div className="nameOnCard">
                                                                <span>Name on Card </span>
                                                            </div>
                                                            <div className="expires">
                                                                <span>Expires on</span>
                                                            </div>
                                                        </div>
                                                    </label>
                                                </div>

                                            </> : ''}



                                            <div className="pmLeft dotAct">


                                                {
                                                    cards?.length === 0 ? <h2>No cards Added</h2> :
                                                        cards.map((i) => (
                                                            <label onClick={() => handleClick(i)} className="active" >
                                                                <input checked={extraClass === i.id} type="radio" name="q1" id={i.id} value="1" onChange={() => invertClass(i.id)} defaultChecked={extraClass === i.id} />




                                                                <div className="ptBank" for="q1-n">
                                                                    <div className="bankNm">
                                                                        <h3>{i.brand} Card</h3>
                                                                        <span>ending in {i.last4}</span>
                                                                    </div>
                                                                    <div className="nameOnCard">
                                                                        <span>{i.name}</span>
                                                                    </div>
                                                                    <div className="expires">
                                                                        <span>{i.exp_month}/{i.exp_year} </span>
                                                                    </div>
                                                                </div>



                                                            </label>
                                                        ))

                                                }

                                            </div>



                                            <div className="addCardBtn">
                                                <button className='acBtn' onClick={handleAddCardShow}>
                                                    <span>+</span> ADD YOUR CARD
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="orderSy">
                                                <div className="calcRow">
                                                    <div className="calcPart">
                                                        <div className="orderSum">
                                                            <h2>Order Summary</h2>
                                                            <div className="orders">
                                                                <div className="orderin">
                                                                    <div className="ordItemName">
                                                                        <span>Ride Cost (${vehicle_rate}/hour) {peak_day && <i style={{ color: '#F6491E', fontSize: 'initial' }} class="fa fa-exclamation-circle carAlert" aria-hidden="true"></i>}</span>
                                                                    </div>
                                                                    <div className="itemPrice actualPrice">
                                                                        <span>${Ridecost && Ridecost}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="orderin">
                                                                    <div className="ordItemName">
                                                                        <span>Service Charge</span>
                                                                    </div>
                                                                    <div className="itemPrice">
                                                                        <span>${serviceCharge && serviceCharge}</span>
                                                                    </div>
                                                                </div>
                                                                {sessionStorage.getItem('reFetch') && offer_type === 'price_discount' && <div className="orderin">
                                                                    <div className="ordItemName">
                                                                        <span>Coupon Code Applied </span>
                                                                    </div>
                                                                    <div className="itemPrice">
                                                                        <span>-${offer && offer}</span>
                                                                    </div>
                                                                </div>}
                                                                {product_Offer?.type === "product_offer" && <div className="orderin">
                                                                    <div className="ordItemName">
                                                                        <span>Coupon Code Applied </span>
                                                                    </div>
                                                                    <div className="itemPrice">
                                                                        <span>{offer && offer}</span>
                                                                    </div>
                                                                </div>}

                                                                <div className="orderin totalPrice clrOrange">
                                                                    <div className="ordItemName">
                                                                        <span>TOTAL</span>
                                                                    </div>
                                                                    <div className="itemPrice">
                                                                        <span>${total && total}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="cardAccept">
                                                                <p>We accepts all major credit and debit cards:</p>
                                                                <div className="cards">
                                                                    <img src={Cards} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="paymentRow">
                                        <div className="procPaybtn">

                                            <button className='payBtn' onClick={() => cards?.length === 0 ? message.info('Please add payment card to proceed payment') : handleToggle(2)} >
                                                Proceed To Payment
                                            </button>


                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class={"tab-pane " + (toggle === 2 ? "active" : "")} id="tabs-2" role="tabpanel">
                                <div className="confirmBooking confirmTab">
                                    <div className="row cbRow">
                                        <div className="col-lg-6 colLcb">
                                            <div className="cbLeft h100">
                                                {bookingError ? <h4>{bookingError}</h4> : ''}
                                                <div className="cbLcar h100">
                                                    <img src={product_image} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 colRcb">
                                            <div className="cbRight">
                                                <div className="cbRHd">
                                                    <span>{number_plate}</span>
                                                    <h2>{vehicle_name}</h2>
                                                </div>
                                                <ul className='bookingDetail'>
                                                    <li className='bdItem'>
                                                        <div className="bdLeft">
                                                            <span>Pick Up:</span>
                                                        </div>
                                                        <div className="bdRight">
                                                            <div className="bdRicon">
                                                                <i className='fad fa-map-marker-alt'></i>
                                                            </div>
                                                            <div className="optedItem">
                                                                <span>{location_title}</span>
                                                                <h3>{location_spot}</h3>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className='bdItem'>
                                                        <div className="bdLeft">
                                                            <span>Start Trip:</span>
                                                        </div>
                                                        <div className="bdRight">
                                                            <div className="bdRicon">
                                                                <span className='dot'>
                                                                </span>
                                                            </div>
                                                            <div className="optedItem">
                                                                <h3>{start.slice(0, 6)}</h3>
                                                                <span>{start.slice(7)}*</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className='bdItem'>
                                                        <div className="bdLeft">
                                                            <span>End Trip:</span>
                                                        </div>
                                                        <div className="bdRight">
                                                            <div className="bdRicon">
                                                                <span className='dot withWhite'>

                                                                </span>
                                                            </div>
                                                            <div className="optedItem">
                                                                <h3>{start.slice(0, 6)}</h3>
                                                                <span>{end.slice(7)}</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <div className="totalHrs">
                                                    <h3><i class="fas fa-stopwatch"></i>{duration !== 1 ? `${duration} hours` : `${duration} hour`} </h3>
                                                    <p>* A Maximum of 30 minutes of delay time can be expected based on the traffic.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row payCalcRow">

                                        <div className="col-lg-6 colPayLeft">
                                            <h2>{cards.length < 0 || cards.length === 0 ? 'No Payment Method Added' : 'Payment Method'}</h2>
                                            {cards?.length > 0 ?
                                                <>

                                                    <div className="payLin">
                                                        <div className="pllleft">
                                                            <span>{SelectedCard.brand ? SelectedCard.brand : cards && cards[0].brand} </span>
                                                        </div>
                                                        <div className="pllrt">
                                                            <div className="cardIcon">
                                                                {
                                                                    cards[0]?.brand === 'Visa' ? <img src={VisaCard} alt="visa" /> : <img src={MasterCard} alt="" />
                                                                }

                                                            </div>
                                                            <div className="accNo">
                                                                <h2>XXXX XXXX X{SelectedCard.last4 ? SelectedCard.last4 : cards && cards[0].last4}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </> : ''
                                            }
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="calcRow calcPayment">
                                                <div className="calcPart">
                                                    <div className="orderSum">
                                                        <h2>Payment</h2>
                                                        <div className="orders">
                                                            <div className="orderin">
                                                                <div className="ordItemName">
                                                                    <span>Ride Cost (${vehicle_rate}/hour) </span>
                                                                </div>
                                                                <div className="itemPrice actualPrice">
                                                                    <span>${Ridecost && Ridecost}</span>
                                                                </div>
                                                            </div>
                                                            <div className="orderin">
                                                                <div className="ordItemName">
                                                                    <span>Servive Charge </span>
                                                                </div>
                                                                <div className="itemPrice">
                                                                    <span>${serviceCharge && serviceCharge}</span>
                                                                </div>
                                                            </div>
                                                            {sessionStorage.getItem('reFetch') && offer_type === 'price_discount' && <div className="orderin">
                                                                <div className="ordItemName">
                                                                    <span>Coupon Code Applied </span>
                                                                </div>
                                                                <div className="itemPrice">
                                                                    <span>-${offer && offer}</span>
                                                                </div>
                                                            </div>}
                                                            {product_Offer?.type === "product_offer" && <div className="orderin">
                                                                <div className="ordItemName">
                                                                    <span>Coupon Code Applied </span>
                                                                </div>
                                                                <div className="itemPrice">
                                                                    <span>{offer && offer}</span>
                                                                </div>
                                                            </div>}
                                                            <div className="orderin totalPrice">
                                                                <div className="ordItemName">
                                                                    <span>TOTAL</span>
                                                                </div>
                                                                <div className="itemPrice">
                                                                    <span>${total && total}</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="paymentRow">
                                        <div class="procPaybtn">


                                            <button disabled={loading} onClick={() => dispatch(BookMyRides({ Ridecost, serviceCharge, offer, total, product_image, vehicle_name, number_plate, location_title, location_spot, duration, start, end, vehicle_rate, location_id, vehicle_id, coupen_id, start_at, ride_date })).then((res) => {
                                                if (res.type !== 'data/BookRides/rejected') {
                                                    sessionStorage.removeItem('reFetch');
                                                    navigate('/thank-you', { state: { start: res.payload.ride_start, image: res.payload.vehicle_image } })

                                                } else {
                                                    console.log(res.payload, 'response after pay without card');
                                                    res.payload === "Cannot charge a customer that has no active card" ? message.info('No payment card is added') : message.info(bookError ? bookError : 'No payment method added')

                                                }
                                            }


                                                //    res.type !== 'data/BookRides/rejected' ? navigate('/thank-you', { state: { start: res.payload.ride_start, image: res.payload.vehicle_image } })
                                                //             : message.info(bookError ? bookError : 'No payment method added')
                                            )
                                            } class="payBtn">Make payment </button>


                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={3300}
                hideProgressBar
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default Tabs;
