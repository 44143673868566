import React, { useEffect, useState } from 'react'
import Footer from '../Componants/Footer';
import NavBar from '../Componants/NavBar';
import SpBanner from '../images/lamborgini.png';
import {Accordion} from 'react-bootstrap';
import axios from 'axios';
import Cookies from 'js-cookie'
import Shopbanner from '../images/shopBanner.png'
import { Parser } from 'html-to-react'
function FaqPage() {
const [Faq,setFaq] = useState();
    useEffect(() => {
      axios.get('https://staging-api.revupnyc.com/page/faq/web_views',{
        headers: { Authorization: `Bearer ${Cookies.get('token')}` }
      }).then((res)=>{
          setFaq(res.data);
      })
    }, [])
    const rawHTML = Faq
  return (
    <div>
        <NavBar />
        <div className="bannerSec spBanner shopBnr low-hieght" style={{ backgroundImage: `url('${Shopbanner}')` }}>
            <div className="WrapperBnr">                
            </div>
        </div>
       <div className="faqSecWrp">
       <div className="container">
            <div className="termWrap faqDetailRev">
                <h1>FAQ</h1>
                  <div className="faqsSec faqPgIn pb60">
                  <Accordion defaultActiveKey="0" flush class="faqAcc">
                    <Accordion.Item eventKey="0" className='itemAccord'>
                        <Accordion.Header>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Accordion.Header>
                        <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                        cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                        est laborum.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1" className='itemAccord'>
                        <Accordion.Header>Ut enim ad minim veniam</Accordion.Header>
                        <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                        cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                        est laborum.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2" className='itemAccord'>
                        <Accordion.Header>Ut enim ad minim veniam</Accordion.Header>
                        <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                        cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                        est laborum.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3" className='itemAccord'>
                        <Accordion.Header>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Accordion.Header>
                        <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                        cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                        est laborum.
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                </div>
            </div>
       </div>
       </div>
       <Footer noTopSpace/>
    </div>
  );
}

export default FaqPage;