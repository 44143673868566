import { message, Popconfirm } from 'antd';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MasterCard from '../images/master.png';
import VisaCard from '../images/visa.png';
import { DeleteUserCard, GetAllCards, MakeCardDefault } from '../Redux/Features/Cardslice';
import AddCard from './AddCard';
import AddPayment from './AddnewPayment';
function Payments() {
    const dispatch = useDispatch()
    const navigate =useNavigate()
    const { cards } = useSelector(state => state.Card)
    const [extraClass, setExtraClass] = useState(cards[0]?.id);
    const [refetchcard, setrefetchcard] = useState(false)
    const [addCard, setAddCard] = useState(false);

    const invertClass = (e) => {

        setExtraClass(e);
    };


    // function for making card default
    const handleClick = (e) => {
        // console.log(e, 'eee');
        dispatch(MakeCardDefault(e)).then(() => {
            setrefetchcard(true)
            setrefetchcard(false)
        })
    }
    
    function confirm(e) {
        dispatch(DeleteUserCard(e)).then(() => {
            message.success('Payment Card Removed Successfully')
            setrefetchcard(true)
            setrefetchcard(false)
        })
    }

    function cancel(e) {
        // console.log(e);

    }


    // useEffect
    useEffect(() => {
        dispatch(GetAllCards()).then((res) => {
            if (res?.payload?.statusCode === 401) {
                // message.error('Account is blocked by admin, contact revup support team')
                Cookies.remove('token')
                navigate('/login')
                
            }else{
                console.log(res,'fetched cards are');
                setExtraClass(res?.payload[0]?.id);
            }
              
        })
    }, [dispatch, refetchcard])
    console.log(extraClass, 'ExtraClass');
    const handleAddCardClose = () => setAddCard(false);
    const handleAddCardShow = () => setAddCard(true);
    return (
        <div>

            {
                addCard === true && <AddPayment handleAddCardClose={handleAddCardClose} setrefetchcard={setrefetchcard} addCard={addCard} />
            }
            {!addCard &&
                <div className="container">
                    <div className="row rowPay">
                        <div className="paymt_wraper p-0">

                            {/* 1 */}
                            {cards?.length > 0 ? cards?.map((card) => (
                                <>

                                    <div onClick={() => handleClick(card.id)} className="paymt payType-item">
                                        <div className="checked">

                                            <input type="radio" name="q2" id={card.id} value="1" onChange={() => invertClass(card.id)} defaultChecked={extraClass == card.id} checked={extraClass == card.id} />
                                            <div className="liwLeft">
                                                <span className="checkmark"></span>
                                            </div>
                                        </div>
                                        <label onClick={() => invertClass(card.id)} className={"paymt " + (extraClass == card.id ? "active" : "")} >
                                            <div className="paymt_in">
                                                <div className="paymt_in_top">
                                                    <div className="icon">
                                                        <img src={card?.brand === 'Visa' ? VisaCard : MasterCard} alt="" />
                                                    </div>

                                                </div>
                                                <div className="paymt_in_btm">
                                                    <div className="accDetail">
                                                        <h2>XXXX XXXX X{card?.last4}</h2>
                                                        <div className="accText">
                                                            <span>{card?.name}</span>
                                                        </div>
                                                    </div>
                                                    <div className="remove">

                                                        <Popconfirm
                                                            title="Do you want to remove this card?"
                                                            onConfirm={() => confirm(card.id)}
                                                            onCancel={cancel}
                                                            okText="Yes"
                                                            cancelText="No"
                                                        >
                                                            <span   >
                                                                Remove
                                                            </span>
                                                        </Popconfirm>
                                                        {/* <span onClick={() => handleDelete(card?.id)} >
                                                    Remove
                                                </span> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </>
                            )) : <h3 className='not-found-h1' >No Payment Cards Found</h3>}

                            {/* 2 */}
                            {/* <div className= "paymt payType-item">

                <div className="checked">
                    <input type="radio" name="q1" id="shp2" value="2" onChange={() => invertClass(1)} defaultChecked={extraClass === 1}/>
                    <label for="shp2" class="liwrap">
                        <div class="liwLeft">
                            <span class="checkmark"></span>
                        </div>
                    </label>
                </div>  
                <label className={"paymt " + (extraClass === 1 ? "active" : "")} for="shp2">
                <div className="paymt_in">
                    <div className="paymt_in_top">
                        <div className="icon">
                            <img src={MasterCard} alt="" />  
                        </div>     
                                          
                    </div>
                    <div className="paymt_in_btm">
                        <div className="accDetail">
                            <h2>XXXX XXXX X9565</h2>
                            <div className="accText">
                                <span>Eugene Waliance</span>
                            </div>
                        </div>
                        <div className="remove">
                            <span>
                                Remove
                            </span>
                        </div>
                    </div>
                </div>
                </label>
            </div> */}
                        </div>
                        <div className="addPayment">
                            <div class="addCardBtn"><button onClick={handleAddCardShow} class="acBtn"><span>+</span> ADD PAYMENT</button></div>
                        </div>
                    </div>
                </div>

            }

        </div>
    );
}

export default Payments;