import React from 'react';
import AddPaymentView from '../Componants/AddPaymentView';
function AddPayment() {
  
  return (
    <div>
        <AddPaymentView />
    </div>
  );
}

export default AddPayment;
