import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Car2 from '../images/car2.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { toast, ToastContainer } from 'react-toastify';
import { BookRide } from '../Redux/Features/Dataslice';
import DurationPopUp from './DurationPopUp';
import LocationPopUp from './LocationPopUp';
import TimeDatePopUp from './TimeDatePopUp';
import { Skeleton } from 'antd';
function BookNow({singleCar}) {
    const [duration, setDuration] = useState(false);
    const [timeDate, setTimeDate] = useState(false);
    const [location, setLocation] = useState(false);
    const [distance, setdistance] = useState();
    const [datetime, setdatetime] = useState();
    const [time, settime] = useState();
    const [place, setplace] = useState();
      
    console.log('grjgjrjgi',singleCar);
    const { id } = useParams()
    const navigate = useNavigate()

    const dispatch = useDispatch()

    const handleDurationClose = () => setDuration(false);
    const handleDurationShow = () => setDuration(true);

    const handletimeDateClose = () => setTimeDate(false);
    const handletimeDateShow = () =>{
        !distance ? toast('Choose Durtion'):setTimeDate(true);
        
    } 

    const handleLocationClose = () => setLocation(false);
    const handleLocationShow = () => setLocation(true);
    return (
        <div>
            <div className="bookNowSection">
                <div className="container">
                    <div className="wrapBookNow">
                        <div className="row bnRow">
                            <div className="col-lg-5 col-sm-12 colPdLeft">
                                <div className="pdLeft">
                                    {/* {
                                        loading && <h3>Loasing</h3>
                                    } */}
                                    <LazyLoadImage
                                        alt='car'
                                        effect="blur"
                                        src={singleCar.vehicle_image && singleCar.vehicle_image[0].image} />
                                    {/* <img src={singleCar.vehicle_image&&singleCar.vehicle_image[0].image} alt="" /> */}
                                </div>
                            </div>
                            <div className="col-lg-7 col-sm-12 colPdRight">
                                {!singleCar.name ? <Skeleton avatar loading/> :<div className="pdRight">
                                    <h2>{singleCar && singleCar.name}</h2>
                                    <div class="review">
                                        <span><i class="fa fa-star" aria-hidden="true"></i>{singleCar && singleCar?.rating_avg}  </span><span class="r-count">Reviews ({singleCar && singleCar?.review?.length})</span>
                                    </div>
                                    <div className="priceForCar">
                                        <h2>${singleCar && singleCar.rate}<span>/hour</span></h2>
                                        <div className="carAlert">
                                            <span><i class="fa fa-exclamation-circle" aria-hidden="true"></i>Price per hour may change depend up on peak time</span>
                                        </div>
                                    </div>
                                </div>}
                                
                            </div>
                        </div>
                        <div className="enquiryArea">
                            <div className="enqLeft">
                                <div className="elItem durBtn" onClick={handleDurationShow}>
                                    <span><i class="fas fa-stopwatch"></i> Duration</span>
                                    {distance ? <h3>{distance==1 ? `${distance}hour` :`${distance}hours`}  <i class="fad fa-angle-right"></i></h3> : <h3>Set Duration <i class="fad fa-angle-right"></i></h3>}

                                </div>
                                <div  className="elItem dtBtn" onClick={handletimeDateShow}>
                                    <span><i class="fas fa-clock"></i> Ride Date & Time</span>
                                    {datetime && time ? <h3>{datetime.toString().split("-").reverse().join("-")}  {time}<i class="fad fa-angle-right"></i></h3> : <h3>Choose Date & Time <i class="fad fa-angle-right"></i></h3>}



                                </div>
                                <div className="elItem locBtn" onClick={handleLocationShow}>
                                    <span><i class="fad fa-map-marker-alt"></i> Set Pickup Location</span>
                                    <br/>
                                    <div className='locPrt'>
                                    <span className='lnamespn'>{place?.title}</span>
                                    {place?.title ? <h3>{place.location} <i class="fad fa-angle-right"></i></h3> : <h3>Set Location <i class="fad fa-angle-right"></i></h3>}
                                    </div>
                                    {/* <div className='locPrt'>
                                    <span className='lnamespn'>World Trade Center</span>
                                    <h3>{place.title} <i class="fad fa-angle-right"></i></h3></div> */}

                                </div>
                            </div>
                            <div className="enqRight">
                                <div className="booKNow">
                                    <div className="bnBtn">
                                        {/* <Link  to='/confirm-booking'></Link>  */}
                                        <button onClick={() => !datetime || !time || !distance || !id || !place?.id ? toast('All required fields must be filled out') :  navigate('/confirm-booking',{ state:{datetime,time, distance, id, locationId: place.id}})} className='btnBn'>BOOK NOW</button>
                                        {/* <h2>datetime, time, distance, id, locationId: place.id</h2> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-center"
                    autoClose={3300}
                    hideProgressBar
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
            {
                duration && <DurationPopUp handleDurationClose={handleDurationClose} setdistance={setdistance} distance={distance} duration={duration} handleDurationShow={handleDurationShow} />
            }
            {
                timeDate && <TimeDatePopUp settime={settime} setdatetime={setdatetime} distance={distance} handletimeDateClose={handletimeDateClose} timeDate={timeDate} handletimeDateShow={handletimeDateShow} />
            }
            {
                location && <LocationPopUp place={place} setplace={setplace} handleLocationClose={handleLocationClose} location={location} />
            }
        </div>
    );
}

export default BookNow;
