import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import tshirt from '../images/tshirt.png'
import watch from '../images/watch.png'
import { AddtoCart, ConfirmOrder, GetCart, GetCartCount, RemoveCartById, UpdateCartById, } from '../Redux/Features/Shopslice';
import Skeleton from 'react-loading-skeleton'
import { message } from 'antd';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

function CartView() {
    const [increment, setIncrement] = useState(1)
    const [refetch, setRefetch] = useState(false)
    
   
    let excludedNostock;
    const { CartItems, orderPrice } = useSelector(state => state.Shop)
    const { CartCount, loading } = useSelector(state => state.Shop)
    const dispatch = useDispatch()
    const navigate =useNavigate()
    const handleIncrement = () => {
        setIncrement(increment + 1);
    }
    const handleDecrement = () => {
        if (increment !== 0) {
            setIncrement(increment - 1);
        }
    }

    const handleDeleteCart = (id) => {
        dispatch(GetCartCount())
        dispatch(RemoveCartById(id)).then((res) => {
            
            res.payload.message === "Deleted" && toast('Item successfully removed from cart')
            setRefetch(true)
            setRefetch(false)
        })
    }

    const changeCartCount = (id, count, active) => {
        console.log(count);


        if (count !== 1 || 0) {
            dispatch(UpdateCartById(id, count)).then((res) => {
                console.log(res, 'res after changing to cart');
                if (res?.payload?.message === "Updated") {
                    setRefetch(true)
                    setRefetch(false)

                } else {
                    
                }
              setTimeout(() => {
               {res.payload.message ==='Updated' ? toast(`Cart ${res.payload.message} Successfully`):toast(`${res.payload.message}`)} 
              }, 1000);  
                // res?.payload?.message === "Updated" && 
                // setRefetch(true)
                // setRefetch(false)
            })
        } else {
            alert('not triggering')
        }

    }

    useEffect(() => {
        
        dispatch(GetCart()).then((respo) => {
            
            if (respo?.payload?.statusCode===401) {
                message.error('Account is blocked by admin,please contact revup support team')
                Cookies.remove('token')
                navigate('/login')
                
                
            }else{
                excludedNostock = respo?.payload?.carts?.filter(item => item.stock !== 0)
            
            console.log(excludedNostock, 'excludedNostock');
            respo.type === "product/GetCart/fulfilled" && dispatch(ConfirmOrder(excludedNostock)).then((res)=>{
                // console.log(res.payload.message, 'res after confirming order');
               
                
            })
                
            }
            
        })
        dispatch(GetCartCount())
        // dispatch(ConfirmOrder({cart:CartItems.carts})).then((res)=>{
        //     console.log(res,'res after confirming order');
        // })
    }, [dispatch, refetch])
    
    return (
        <div className='cartWrap'>
            <div className="container">
                <div className="cartTable">
                    <div className="cartHd">
                        <div className="cartLeft">
                            <h1>Cart</h1>
                        </div>
                        <div className="cartRight">
                            
                          {CartCount?.count >0&&<span>{CartCount?.count === 0  ? `${CartCount.count} Item in your cart`:(CartCount?.count === 1?`${CartCount.count} Item in your cart`:`${CartCount.count} Items in your cart`)} </span>}  
                          {/* d.y >= 70 ? "green" : (d.y < 50 ? "red" : "yellow"); */}
                        </div>
                    </div>
                    <div className="table-col-3">
                        <div className="table-responsive table-sticky">
                            <table className="table table-bordered no-top cartTable ">
                                <tbody className="text-center align-middle cartTableBody">
                                    <tr style={{ borderBlock: 'none' }} >
                                        <th className="text-start p-3" scope="row">Item</th>
                                        <th></th>
                                        <th className='tbHd'>Price </th>
                                        <th className='tbHd'>Quantity</th>
                                        <th className='tbHd'>Sub Total</th>
                                        {/* <th className='tbHd'>Sub Total</th> */}
                                    </tr>

                                    {CartItems?.carts?.length === 0 &&<div className='no-cart-item' style={{textAlign:'center'}}><h2>No Item In Cart</h2></div> }
                                    {CartItems?.carts ? CartItems.carts.map((item) => (
                                        // item.quantity > 0 &&
                                        <>
                                            <tr className={item?.product?.stock < 1 ? ' cartItem itemNoStock' : 'cartItem'}>
                                                <td >
                                                    <div className="product">
                                                        <div className="product--img">
                                                            <img height={102} width={120} src={item?.product?.product_image[0]?.image} alt="" />
                                                        </div>                                                        
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="product--name">
                                                        <h2>{item?.product?.title.substring(0,18)}</h2>
                                                        {item?.product?.stock < 1 &&
                                                        <div className="stockInfo">
                                                            <span>OUT OF STOCK</span>
                                                        </div>
                                                        }                                                            
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="priceForProduct">
                                                        <span className='cartPrice'>
                                                            ${item?.product?.unit_price}
                                                        </span>
                                                        {/* <span className='deliveryfee'>
                                                            $8.00 Delivery Fee
                                                        </span> */}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="quantityAlter">
                                                        <div className="countAlter">
                                                            <span aria-disabled className='minus otrCircle' onClick={() => changeCartCount({ count: item?.quantity - 1, id: item?.product?.id, active: item.active, cartId: item.id })}><button className='reduce_button' disabled={item.quantity === 1} >-</button> </span>
                                                            <span className="countNo">{item.quantity}</span>

                                                            {!loading ? <span className='plus otrCircle' onClick={() => changeCartCount({ count: item?.quantity + 1, id: item?.product?.id, active: item.active, cartId: item.id })}>+</span> : <span className='plus otrCircle' >+</span>}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='subTotal'>
                                                    <span>${item?.product?.unit_price * item?.quantity}</span>
                                                </td>
                                                <td className='trash'>
                                                    <span onClick={() => handleDeleteCart(item.id)} ><i className="fas fa-trash-alt"></i></span>
                                                </td>
                                            </tr>



                                        </>
                                    )) :
                                        [...Array(3)].map((_, i) => (
                                            <>
                                                <tr className='cartItem'>
                                                    <td>
                                                        <div className="product">
                                                            <div className="product--img">
                                                                <Skeleton width={100} height={100} />
                                                            </div>
                                                            <div className="product--name">
                                                                <h2><Skeleton /></h2>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="priceForProduct">
                                                            <span className='cartPrice'>
                                                                <Skeleton />
                                                            </span>
                                                            <span className='deliveryfee'>
                                                                <Skeleton />
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="quantityAlter">
                                                            <div className="countAlter">
                                                                <span onClick={handleDecrement}><Skeleton /></span>
                                                                <span className="countNo"><Skeleton /></span>
                                                                <span onClick={handleIncrement}><Skeleton /></span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='subTotal'>
                                                        <span><Skeleton /></span>
                                                    </td>
                                                    <td className='trash'>
                                                        <span><Skeleton /></span>
                                                    </td>
                                                </tr>
                                            </>
                                        ))




                                    }

                                    {/* <tr className='cartItem itemNoStock'>
                                <td>
                                    <div className="product">
                                        <div className="product--img">
                                            <img src={watch} alt="" />
                                        </div>
                                        <div className="product--name">
                                            <h2>Tonino Lamborghini Spyder Chrono Watch</h2>
                                            <div className="stockInfo">
                                                <span>OUT OF STOCK</span>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="priceForProduct">
                                        <span className='cartPrice'>
                                            $1399.00
                                        </span>
                                        <span className='deliveryfee'>
                                            $8.00 Delivery Fee
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <div className="quantityAlter">
                                    <div className="countAlter">
                                        <span className='minus otrCircle' onClick={handleDecrement}>-</span>
                                        <span className="countNo">{increment}</span>
                                        <span className='plus otrCircle' onClick={handleIncrement}>+</span>
                                    </div>
                                    </div>
                                </td>
                                <td className='subTotal'>
                                    <span>$1501.00</span>
                                </td>
                                <td className='trash'>
                                    <span><i className="fas fa-trash-alt"></i></span>
                                </td>
                            </tr>
                            <tr className='cartItem'>
                                <td>
                                    <div className="product">
                                        <div className="product--img">
                                            <img src={tshirt} alt="" />
                                        </div>
                                        <div className="product--name">
                                            <h2>Tonino Lamborghini Spyder Chrono Watch</h2>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="priceForProduct">
                                        <span className='cartPrice'>
                                            $1399.00
                                        </span>
                                        <span className='deliveryfee'>
                                            $8.00 Delivery Fee
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <div className="quantityAlter">
                                    <div className="countAlter">
                                        <span className='minus otrCircle' onClick={handleDecrement}>-</span>
                                        <span className="countNo">{increment}</span>
                                        <span className='plus otrCircle' onClick={handleIncrement}>+</span>
                                    </div>
                                    </div>
                                </td>
                                <td className='subTotal'>
                                    <span>$1501.00</span>
                                </td>
                                <td className='trash'>
                                    <span><i className="fas fa-trash-alt"></i></span>
                                </td>
                            </tr> */}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CartView;