import { message } from 'antd';
import React, { useEffect } from 'react'
import { Button, Modal, Toast} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { AddNewShippingAddres, GetAllShippingAddress, GetAllState } from '../Redux/Features/Userslice';
function AddnewAddressPop({addNewAddress,handleAddAdressClose}) {
    const dispatch = useDispatch()
    const { states } = useSelector(state => state.User)
    const { register, handleSubmit, control, formState: { errors } } = useForm()
    const onSubmit = (data) => {

        console.log(data, 'sD');
        dispatch(AddNewShippingAddres(data)).then((res) => {
            console.log(res, 'res after adding new ship');
            if (res?.payload?.message === "Created") {
                message.info('Shipping Address added successfully')
                handleAddAdressClose()
                dispatch(GetAllShippingAddress())
            } else {
                // message.warning('Shipping Address Verification failed')
                // Toast('Shipping Address Verification failed')
                toast('Shipping Address Verification failed');
            }

        })



    }
    useEffect(() => {
        dispatch(GetAllState()).then((res) => {
            console.log(res, 'res after state');
        })
    }, [dispatch])
  return (
    <div>
      <Modal  show={addNewAddress} onHide={handleAddAdressClose} className='modalEditLocation'>
        <Modal.Header closeButton className='modalEditHdr'>
          <Modal.Title>Add a new address</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modalBodyPopup'>
                    <div className="editPopView">
                        <div action="#" className="register-form-edit">
                            <div class="row modalEditHdr">
                                <div className="col-lg-12 colRgRight p-0 align-self-center">
                                    <div className="rgRtWrap">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div action="#" className="register-form addCardFrm">

                                                <div className="form-row row">

                                                    <div className="form-controller col-lg-12">
                                                        <label for="">Name</label>
                                                        <input {...register('name', { required: ' Name is required', minLength: { value: 3, message: 'Name must be atleast 3 characters' }, pattern: { value: /^[a-zA-Z ]*$/, message: 'Name Must be Alphabets' }, maxLength: { value: 20, message: 'Name must be less than 20 alphabets' } })} name="name" type="text" autocomplete="off" placeholder="Name" />
                                                        {errors?.name && <p style={{ color: 'red' }}>{errors?.name.message}</p>}
                                                    </div>
                                                </div>
                                                <div className="form-row row">

                                                    <div className="form-controller col-lg-12">
                                                        <label for="">Address</label>
                                                        <input {...register('address', { required: ' Address is required', pattern: [/[a-z]/, /[A-Z]/, /[0-9]/], minLength: { value: 3, message: 'Address must be atleast 3 characters' }, maxLength: { value: 70, message: 'Address must be less than 70 characters' } })} name="address" type="text" autocomplete="off" placeholder="Address" />
                                                        {errors?.address && <p style={{ color: 'red' }}>{errors?.address.message}</p>}
                                                    </div>

                                                </div>
                                                <div className="form-row row">
                                                    <div className="form-controller col-lg-12">
                                                        <label for="">Unit/floor</label>
                                                        <input {...register('unit', { required: ' Unit/floor is required', pattern: [/[a-z]/, /[A-Z]/, /[0-9]/], minLength: { value: 3, message: 'Unit/floor must be atleast 3 characters' }, maxLength: { value: 30, message: 'Unit/floor must be less than 30 characters' } })} name="unit" type="text" autocomplete="off" placeholder="Unit/Floor" />
                                                        {errors?.unit && <p style={{ color: 'red' }}>{errors?.unit.message}</p>}
                                                    </div>
                                                </div>
                                                <div className="form-row row">
                                                    <div className="form-controller col-lg-12">
                                                        <label for="">Landmark</label>
                                                        <input {...register('Landmark', { pattern: [/[a-z]/, /[A-Z]/, /[0-9]/], minLength: { value: 3, message: 'Landmark must be atleast 3 characters' }, maxLength: { value: 70, message: 'Landmark must be less than 70 characters' } })} type="text" autocomplete="off" name="Landmark" placeholder=" Landmark    " />
                                                        {errors?.Landmark && <p style={{ color: 'red' }}>{errors?.Landmark.message}</p>}
                                                    </div>
                                                </div>
                                                <div className="form-row row">
                                                    <div className="form-controller col-lg-12">
                                                        <label for="">City</label>
                                                        <input {...register('city', { required: ' City is required', minLength: { value: 3, message: 'City must be atleast 3 characters' }, pattern: { value: /^[a-zA-Z ]*$/, message: 'Name Must be Alphabets' }, maxLength: { value: 30, message: 'City must be less than 30 characters' } })} name="city" type="text" autocomplete="off" placeholder="City" />
                                                        {errors?.city && <p style={{ color: 'red' }}>{errors?.city.message}</p>}
                                                    </div>
                                                </div>

                                                <div className="form-row row">

                                                    <div className="form-controller col-lg-6">
                                                        <label for="">State</label>
                                                        <select className='select-state' {...register("state_id", { required: 'State is required' })} name='state_id'>
                                                            {states?.states?.map((item) => (
                                                                <>
                                                                    <option selected disabled  hidden>Select</option>
                                                                    <option value={item.id}>{item.name}</option>
                                                                </>
                                                            ))}
                                                        </select>
                                                        {errors?.state_id && <p style={{ color: 'red' }}>{errors?.state_id.message}</p>}
                                                    </div>
                                                    <div class="form-controller col-lg-6">
                                                        <label for="">Zip Code</label>
                                                        <input {...register('zip_code', { required: 'Zip Code is required', pattern: { value: /\d+/, message: 'Zip Code must be numbers' }, minLength: { value: 5, message: ' Zip Code  must be atleast 5 digits ' }, maxLength: { value: 7, message: 'Zip code should be less than 7 digits' } })} name="zip_code" type="text" autocomplete="off" placeholder="Zip Code" />
                                                        {errors?.zip_code && <p style={{ color: 'red' }}>{errors?.zip_code.message}</p>}
                                                    </div>
                                                </div>
                                                <div className="formFooter">
                                                    <div className="ff-left">
                                                        {/* <div className="new">
                                                            <form>
                                                                <div className="form-group">
                                                                    <input type="checkbox" id="html" />
                                                                    <label for="html" className='dafcheck'>Make this my default address</label>
                                                                </div>
                                                            </form>
                                                        </div> */}
                                                    </div>
                                                    <div className="ff-right">
                                                        <button class="payBtn">Proceed</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <ToastContainer
                    position="top-center"
                    autoClose={3300}
                    hideProgressBar
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
      </Modal>
    </div>
  );
}

export default AddnewAddressPop;