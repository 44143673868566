import React, { useState } from 'react'
import NavBar from '../Componants/NavBar';
import SpBanner from '../images/lamborgini.png';
import Footer from '../Componants/Footer';
import Shopbanner from '../images/shopBanner.png'
import Profile from '../images/prof.jpeg'
import LeftArrow from '../images/left-arrow.svg'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { ChangeUserPhone } from '../Redux/Features/Userslice';
import { message } from 'antd';
import { useForm } from 'react-hook-form';
import formatPhoneNumber from '../Tools/PhoneFormatter'
import Cookies from 'js-cookie';
function ChangePhone() {
    const {state}=useLocation()
    const { register, handleSubmit,control, formState: { errors } } = useForm()
    const [error,seterror]=useState()
    const [phone,setPhone]=useState(formatPhoneNumber(state?.phone))
    // this phone is passing to backend (unformated)
    const [unformatedphone,setunformatedphone]=useState()
   
    const dispatch =useDispatch()
    const navigate =useNavigate()
    // const[phone,setPhone]=useState(state?.phone)
    // console.log(state);
    
    const handleChange =(e)=>{
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        
        var newString = e.target.value.replace(/[- )(]/g,'')
        // console.log(newString.substring(0,10),'unformated');
        setunformatedphone(newString.substring(0,10))
        if (!newString) {
            seterror('Phone number is required')
        }else if ( newString.length<10){
            seterror('Phone number must be 10 digits')
            
        }else{
            seterror('')
        }
        console.log(formattedPhoneNumber);
        setPhone(formattedPhoneNumber)
    }

    const handleChangePhone =()=>{
         !error && dispatch(ChangeUserPhone({phone:unformatedphone,phone_code:state?.phone_code})).then((res)=>{
        //   console.log(res,'res after change phone number');
          if (res.type === "user/PhoneChange/fulfilled"
          ) {
              message.info('Verification code sent successfully')
              navigate('/verify-phone',{state:{data:res?.payload?.session_id,phone:unformatedphone}})
          } if (res.payload.statusCode===401) {
            message.error('Account is blocked by admin,please contact revup support team')
                Cookies.remove('token')
                navigate('/login')
            
          }  else {
            //   console.log(res?.payload?.message[0],'eroor in eerror');
              res.payload.message[0] ==='phone must be a number string'? message.info('Use a diffrent phone number'):message.warning(res?.payload?.message)
          }
      })
       
    }
    // function for change phone number
    const onSubmit = (data) => {
        // console.log(data,'phone change');
    //   dispatch(ChangeUserPhone({phone:data.phone,phone_code:state?.phone_code})).then((res)=>{
    //       console.log(res,'res after change phone number');
    //       if (res.type === "user/PhoneChange/fulfilled"
    //       ) {
    //           message.info('Successfully verification code sent to your phone number')
    //           navigate('/verify-phone',{state:{data:res?.payload?.session_id,phone:data?.phone}})
    //       } else {
    //           message.warning(res?.payload?.message)
    //       }
    //   })
    }

  return (
    <div>
         <NavBar />
        <div className="bannerSec spBanner shopBnr low-hieght" style={{ backgroundImage: `url('${Shopbanner}')` }}>
            <div className="WrapperBnr">                
            </div>
        </div>
        <div className="editProfile pb60">
            <div className="container">
                <div className="row epRow">
                <div className="epIn col-lg-4">
                    <div className="epHd ep-change">
                        <h2 className='epHd'> 
                            <span>
                                <Link to="/profile-view" > <img src={LeftArrow} alt="" /> </Link>
                            </span> 
                            Change Phone
                        </h2>
                        <span className='ep-change--sub'>please enter your mobile number to receive a verification code to create an account</span>
                    </div>
                    <div className="product--name epDetail">
                        <div className="epForm register-form">
                        {/* <form onSubmit={handleSubmit(onSubmit)} > */}
                            <div className="form-controller">
                                <label htmlFor="">Phone</label>
                                {/* <input onChange={(e)=>setPhone(e.target.value)}  type="text" autocomplete="off" name="first_name" placeholder="Phone Number" defaultValue={state?.phone} />      */}
                                <input defaultValue={phone} value={ phone} onChange={handleChange}/>
                               {error &&<span style={{color:'red'}} >{error}</span>} 
                                {/* <input defaultValue={`(${state?.phone.substring(0,3)})${state.phone.substring(3,6)}-${state.phone.substring(6,10)}`} {...register('phone', { required: 'Phone is required' ,maxLength: { value: 10, message: ' Phone number must be 10 digits  ' }, minLength: { value: 10, message: ' Phone number must be 10 digits ' }, })} className='phnin'  autocomplete="off"  name="phone" placeholder="Phone"  control={control}  />
                                {errors?.phone && <p style={{ color: 'red' }}>{errors?.phone.message}</p>}                                            */}
                            </div>
                            {/* <Link to="/verify-phone"> */}
                                <button onClick={handleChangePhone}  type="submit" class="btn btn-save">CONTINUE</button>
                            {/* </Link> */}
                        {/* </form> */}
                        </div>
                    </div>                
                </div>
                </div>
            </div>
        </div>
        <Footer noTopSpace/>
    </div>
  );
}

export default ChangePhone;