import React from 'react';
import Footer from '../Componants/Footer';
import NavBar from '../Componants/NavBar';
import VerifyAccountView from '../Componants/VerifyAccountView';
function VerifyAccount() {
  return (
    <div>
        <NavBar />
        <VerifyAccountView />
        <Footer/>
    </div>
  );
}

export default VerifyAccount;
