import React from 'react';
import Footer from '../Componants/Footer';
import NavBar from '../Componants/NavBar';
import RideDetail5View from '../Componants/RideDetail5View';

function RideDetail5() {
  return (
    <div>
      
        <NavBar />
        <RideDetail5View />
        <Footer noTopSpace />
    </div>
  );
}

export default RideDetail5;
