import { message } from 'antd';
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReportARide, ReportRide } from '../Redux/Features/Dataslice';
function ReportIssue({ show, handleClose, Ride_code, category, orderId, setdefaultValue, defaultValue }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { reportOptions, reportCategories } = useSelector(state => state.Data)
  const { register, handleSubmit, control, reset, watch, formState: { errors } } = useForm(
    
  )
  console.log(defaultValue, 'default value');
  const onSubmit = (data) => {

    setdefaultValue(data)
    dispatch(ReportARide({ ride_id: Ride_code, order_id: orderId, reason_id: data.reportOption, details: data.message, category: category })).then((response) => {

      if (response?.payload?.message === "Created") {
        message.success('You have reported successfully')
        handleClose()
      }
      else {
        message.info(response?.payload)
        handleClose()
      }
    })




  }
  // console.log(Ride_code);
  useEffect(() => {
    dispatch(ReportRide({ category: category }))

  }, [dispatch])

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className='modal-report-header'>
          <Modal.Title>Report Issue</Modal.Title>


        </Modal.Header>
        <Modal.Body className='modal-report-body'>
          <h2>Please describe the issue</h2>
          <div action="#" class="register-form form-reportissue">
            <form onSubmit={handleSubmit(onSubmit)}>

              <div class="formWrap FormRow frm-rw">


                <div class="form-controller">
                  
                  {reportOptions && reportOptions.length > 0 &&
                  <>
                  <label for="">Select reason</label>
                  <select name="reportOption" {...register("reportOption", { required: 'Report reason is required' })}>
                    { reportOptions.map((i) => (
                      <>
                        <option selected disabled hidden>{reportCategories ? 'loading' : 'Select reason'}</option>
                        <option value={i.id}  >{i.reason}</option>
                      </>
                    ))}

                  </select>
                  </>
                  
                  }
                  
                  
                  {errors?.reportOption && <p style={{ color: 'red' }}>{errors?.reportOption.message}</p>}



                </div>

                <div class="form-controller p-0">
                  <label for="">Message</label>
                  <textarea placeholder='Type' {...register('message', { required: ' Message is required', pattern: [/[a-z]/, /[A-Z]/, /[0-9]/], minLength: { value: 5, message: 'Message must be atleast 5 characters' }, maxLength: { value: 100, message: ' Message should be less than 100 characters' } })} name="message" maxlength="100" id="" cols="30"></textarea>
                  {errors?.message && <p style={{ color: 'red' }}>{errors?.message.message}</p>}
                </div>
              </div>
              <button type="submit" class="btn contactBtn">SEND</button>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ReportIssue