import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import AddNewAddress from './AddNewAddress';
import { DeleteShippingAddres, EditShippingAddres, GetAllShippingAddress } from '../Redux/Features/Userslice';
import { message, Popconfirm } from 'antd';
import EditAddress from './EditAddress';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';


function Shipping() {
    const { shippingAddress,ShippingLoading } = useSelector(state => state.User)
    const [extraClass, setExtraClass] = useState(1);
    const [addAdress, setAddAdress] = useState(false);
    const [editAdress, setEditAdress] = useState(false);
    const [refetch, setRefetch] = useState(false);
    const [reFetch, setrefetch] = useState(false);
    const [UserAddress, setUserAddress] = useState({});
    const dispatch = useDispatch()
    const navigate=useNavigate()

    const invertClass = (e) => {

        setExtraClass(e);
    };
    const handleClick = () => {
        //alert("test");
        setAddAdress(!addAdress)
    }
    const handleEdit =(address) =>{
        setEditAdress(!editAdress)
        console.log(address,'address');
        setUserAddress(address)
    }
    
    function confirm(e) {
        dispatch(DeleteShippingAddres(e)).then((res) => {

            if (res.payload.message === "Deleted") {
                message.success('Shipping Address Removed Successfully')
                setRefetch(true)
                setRefetch(false)
            }
        })
        
      }
      const handleDefault=(data)=>{
        // console.log(data,'data');
        dispatch(EditShippingAddres({name:data.name,address:data.address,unit:data.unit,city:data.city,state:data.state_id,zip:data.zip,active:"active",default:true,id:data?.id})).then((res)=>{
            // console.log(res,'res after editship');
            if (res?.payload?.message=== "Updated") {
                
                
                setrefetch(true)
                setrefetch(false)
                // handleEditClose()
                // message.success('Shipping Address Updated Successfully')
            }
        })
    }
      
      function cancel(e) {
        
      }

    useEffect(() => {

        dispatch(GetAllShippingAddress()).then((res) => {
            // console.log(res?.payload?.statusCode,'res in shipiing');
            if (res?.payload?.statusCode === 401) {
                message.error('Account is blocked by admin,please contact revup support team')
                Cookies.remove('token')
                navigate('/login')
                
            }
        })
        .then((err)=>{
            // console.log(err,'error in shipiing');
        })
    }, [dispatch, refetch,reFetch])
    // shippingAddress &&console.log(shippingAddress.shipping_addresses[0].id, 'shippingAddress id');
    return (
        <div>
            <div className="container">
                <div className="row rowPay">
                    <div  className="paymt_wraper p-0">

                        {(!addAdress && !editAdress) &&
                            <>
                                <h1>{shippingAddress?.length}</h1>
                                {shippingAddress?.count > 0 ?
                                    shippingAddress?.shipping_addresses.map((address) => (
                                        <>

                                            <div  onClick={() => handleDefault(address)} className="paymt payType-item">
                                                <div  className="checked">
                                                    <input type="radio" name="q1" id={address.id} value="1" onChange={() => invertClass(address.id)} defaultChecked={address.default} checked={address.default} />
                                                    <div className="liwLeft">
                                                        <span className="checkmark"></span>
                                                    </div>
                                                </div>
                                                <label className={"paymt " + (extraClass === address.id ? "active" : "")} for="shpp1">
                                                    <div className="paymt_in">
                                                        <div className="shipping_name">
                                                            <h2>{address.name}</h2>
                                                            <p>{address.address} , {address.city} ,{address.zip}</p>
                                                        </div>
                                                        <div className="shppg_in_btm">
                                                            <div className="edit">
                                                                <span onClick={()=>handleEdit(address)}>
                                                                    Edit
                                                                </span>
                                                            </div>
                                                            <div className="remove">
                                                                <Popconfirm
                                                                    title="Are you sure to delete this Shipping Address?"
                                                                    onConfirm={()=>confirm(address.id)}
                                                                    onCancel={cancel}
                                                                    okText="Yes"
                                                                    cancelText="No"
                                                                >
                                                                    <span   >
                                                                    Remove
                                                                </span>
                                                                </Popconfirm>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>

                                        </>

                                    ))
                                    : <h3 className='not-found-h1'  >No Shipping Address Found</h3>


                                }

                            </>


                        }

                        {/* 1 */}


                    </div>
                    {(!addAdress && !editAdress)  &&

                        <div className="addPayment">
                            <div onClick={handleClick} class="addCardBtn"><button class="acBtn"><span>+</span> ADD NEW ADDRESS</button></div>
                        </div>
                    }

                </div>
            </div>

            {addAdress && <AddNewAddress setAddAdress={setAddAdress} />}
            {editAdress && <EditAddress UserAddress={UserAddress} setEditAdress={setEditAdress} setRefetch={setRefetch} />}
        </div>
    );
}

export default Shipping;