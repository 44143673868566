import { message } from 'antd';
import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import CartView from '../Componants/CartView';
import Footer from '../Componants/Footer';
import NavBar from '../Componants/NavBar';
import remvicn from '../images/offers.png';
import Shopbanner from '../images/shopBanner.png';
import { ApplyCoupencode, RemoveCoupencode } from '../Redux/Features/Dataslice';

function Cart() {
    const { CartItems,orderPrice,ammountError,ShipmentDetails } = useSelector(state => state.Shop)
    // const { CartItems, orderPrice, ammountError, ProductCoupenError, CoponOffer, ShipmentDetails,SingleProduct } = useSelector(state => state.Shop)
    const { bookingDetails, coupenerror, coupenOffer, loading, CoupenRemove, error } = useSelector(state => state.Data)
    const [code, setcode] = useState()
    const [itm, setitm] = useState(false)
    const [coupenData, setcoupenData] = useState({})
    const dispatch = useDispatch()
    const navigate = useNavigate()
    // const [ammountError, setammountError] = useState('')
    console.log(ammountError);
  return (
    <div>
        <NavBar />
        <div className="bannerSec spBanner shopBnr low-hieght" style={{ backgroundImage: `url('${Shopbanner}')` }}>
            <div className="WrapperBnr">                
            </div>
        </div>
        <CartView  />
        
        <div className="orderPayment payOrder">
            <div className="container">
                <div className="row opRow calcRow">
                   {CartItems?.carts?.length !==0 && !ammountError &&
                   <div className="col-lg-6 colOpLt">
                   <div className="item-deliver">
                       <div className="calcPart noArt">
                           
                         <span>Do you Have Promo Code?</span>


                         {coupenOffer.offer && sessionStorage.getItem('reFetch') ? <div className="promoApplied">
                                    <div className="paLeft">
                                        <div className="iconpa">
                                            <img src={remvicn} alt="" />
                                        </div>
                                        <div className="patext">
                                            { coupenOffer.type === 'price_discount' ? <span>Promo code Applied</span> :<span>You have got a {coupenOffer?.offer} <br/>  you will get it during the time of service</span> }
                                            {/* <span>{coupenOffer.type === 'price_discount' ? 'Promocode applied' : `You have got a ${coupenOffer?.offer}  you will get it during the time of service`}</span> */}
                                        </div>
                                    </div>
                                    <div className="paRt">
                                        <div className="paremove">
                                            {coupenOffer ? <span onClick={() => dispatch(RemoveCoupencode({ discount: coupenOffer?.offer, price: coupenOffer.total_cost })).then((res) => {
                                                console.log('Remove coupencode', res);
                                                if (res.type === 'data/Removecoupen/fulfilled') {
                                                    toast('Promo code removed successfully')
                                                    sessionStorage.removeItem('reFetch')
                                                    setitm(false)

                                                    setcoupenData(res.payload)


                                                }
                                                

                                            })} >Remove</span>
                                            
                                            :
                                            <>
                                            
                                            </>
                                             }

                                        </div>
                                    </div>
                                </div> : <div className="promoInput">
                                    <input value={code} onChange={(e) => setcode(e.target.value)} type="text" placeholder='Enter Promo Code' />
                                    <button disabled={loading} onClick={() => !code ? toast('Enter Promo Code') : dispatch(ApplyCoupencode({ code, price: orderPrice?.total.toString(),type: "shop" }))


                                        .then((res) => {


                                            if (res.type !== 'data/Applycoupen/rejected') {
                                                sessionStorage.setItem('reFetch', true)
                                                toast('Promo code applied successfully')
                                                console.log(sessionStorage.getItem('reFetch'), 'under log');
                                                setitm(true)

                                            }
                                        }

                                        )
                                    } >APPLY</button>


                                </div>
                                }

{coupenerror && <h6>{coupenerror}</h6>}


                         {/* <div class="promoInput">
                             <input type="text" placeholder="Enter Promo Code" />
                             <button>APPLY</button>
                         </div> */}
                         <div className="cartMsg">
                           <span><i class="fa fa-shield-alt"></i> Safe and secure payments. 100% authentic products</span>
                         </div>
                      </div>
                   </div>
               </div>
                   }
                    
                    
                    
                  
                    
                    
                    {CartItems?.carts?.length !==0 && !ammountError ?
                    // ammountError!=='' ? <></> :<></>
                    <div className="col-lg-6 colOpRt">
                    <div className="calcSum">
                        <div className="calcPart noEff">
                            <div class="orderSum">
                                <h2>Order Summary </h2>
                                
                                <div class="orders">
                                    <div class="orderin">
                                        <div class="ordItemName"><span>Total Item Price</span></div>
                                        <div class="itemPrice actualPrice">
                                           {orderPrice?.product_total? <span>${ orderPrice?.product_total }</span> : <Skeleton height={20} width={100} />}
                                            </div>
                                    </div>
                                    <div class="orderin">
                                        <div class="ordItemName"><span>Shipping Price</span></div>
                                        <div class="itemPrice">
                                            {orderPrice?.shipment_rate ?<span>${orderPrice?.shipment_rate}</span>: <Skeleton height={20} width={100} /> }
                                            
                                            </div>
                                    </div>
                                    <div class="orderin">
                                        <div class="ordItemName"><span>Service Charges</span></div>
                                        <div class="itemPrice">
                                           {orderPrice?.service_charge ?<span>${orderPrice?.service_charge}</span>: <Skeleton height={20} width={100} /> } 
                                            </div>
                                    </div>
                                    {coupenOffer?.offer && sessionStorage.getItem('reFetch')&&
                                    <div class="orderin">
                                        <div class="ordItemName"><span>Coupon Applied</span></div>
                                        <div class="itemPrice">
                                           {orderPrice?.service_charge ?<span>- ${coupenOffer?.offer }</span>: <Skeleton height={20} width={100} /> } 
                                            </div>
                                    </div>
                    }
                                    <div class="orderin totalPrice">
                                        <div class="ordItemName"><span>TOTAL</span></div>
                                        <div class="itemPrice">
                                        <span> {sessionStorage.getItem('reFetch') && coupenOffer.total_cost ? ` $ ${ coupenOffer.total_cost}`  : orderPrice?.total ?`$ ${orderPrice?.total}`  :<Skeleton width={100} height={20}/> }</span>
                                        {/* {orderPrice?.total ? <span>${orderPrice?.total}</span> : <Skeleton height={20} width={100} />}    */}
                                            </div>
                                    </div>
                                </div>
                                <div onClick={()=>navigate('/add-payment3',{state:{orderPrice,product:CartItems.carts,coupenOffer:coupenOffer,shipping_details: ShipmentDetails,qnty:2}})} class="proceedBtn"><button>PROCEED</button></div>                    
                            </div>
                        </div>
                    </div>
                    
                    </div>
                    
                    :
                    <div className="col-lg-6 colOpRt">
                    <div className="calcSum">
                        <div className="calcPart noEff">
                            {CartItems?.carts?.length !== 0 &&
                            <div class="orderSum">
                           {!ammountError&&<h2>Order Summary</h2>} 
                            
                            {
                            
                            CartItems?.carts?.length ===0 ?

                            
                            
                         <div class="orders">
                                {/* <div class="orderin">
                                    <div class="ordItemName"><span>Total Item Price</span></div>
                                    <Skeleton/>
                                </div>
                                <div class="orderin">
                                    <div class="ordItemName"><span>Shipping Price</span></div>
                                    <Skeleton/>
                                </div>
                                <div class="orderin">
                                    <div class="ordItemName"><span>Service Charges</span></div>
                                    <Skeleton/>
                                </div>
                                <div class="orderin totalPrice">
                                    <div class="ordItemName"><span>TOTAL</span></div>
                                    <Skeleton/>
                                </div> */}
                            </div>:
                            
                            <h6>{ammountError==='No shipping Address'? `${ammountError} found, please add shipping address  to see order summary`:`${ammountError}`}</h6>
                            }
                            
                          {!ammountError&&<div class="proceedBtn"><button>PROCEED</button></div> }                     
                        </div>
                            
                            
                            
                            }
                            
                        </div>
                    </div>
                    
                    </div>
                    }
                    
                </div>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={3300}
                hideProgressBar
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
        <Footer noTopSpace />
    </div>
  );
}

export default Cart;
